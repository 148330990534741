/**
 * @file Definition of the ListItemLink component
 * @author Harris Lummis
 */
import React, { FunctionComponent } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemText, {
  ListItemTextProps,
} from '@material-ui/core/ListItemText';
import { ListItemIcon } from '@material-ui/core';
import { ListItemIconProps } from '@material-ui/core/ListItemIcon';

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => (
    <RouterLink
      style={{ textDecoration: 'none' }}
      innerRef={ref as any}
      {...props}
    />
  ),
);

export interface ListItemLinkProps extends RouterLinkProps {
  /** Optionally apply styling to the ListItem */
  classes?: ListItemProps['classes'];
  /** Defines the align-items styles property of the ListItem. Defaults to center */
  alignItems?: 'flex-start' | 'center';
  /** If true, the list item will be focused during the first mount. Focus will also be triggered if the value changes from false to true. */
  autoFocus?: boolean;
  /** If true, the list item will be a button (using ButtonBase). Defaults to false */
  button?: boolean;
  /** If true, compact vertical padding designed for keyboard and mouse input will be used. */
  dense?: boolean;
  /** If true, the list item will be disabled. */
  disabled?: boolean;
  /** If true, the left and right padding is removed. */
  disableGutters?: boolean;
  /** If true, a 1px light border is added to the bottom of the list item. */
  divider?: boolean;
  /** Use to apply selected styling. */
  selected?: boolean;
  /** The icon to include in the list item, if any */
  icon?: JSX.Element;
  /** Primary text content to display */
  primary: React.ReactNode;
  /** Optional properties to override default ListItemText properties */
  listItemTextProps?: Partial<ListItemTextProps>;
  /** Optional properties to override default ListItemIcon properties */
  listItemIconProps?: Partial<ListItemIconProps>;
}

export const ListItemLink: FunctionComponent<ListItemLinkProps> = props => {
  const {
    icon,
    primary,
    listItemTextProps,
    listItemIconProps,
    ...toPass
  } = props;

  // NOTE: ListItem prop types are currently broken in material-ui, hence the coercion to any
  const listItemProps = { component: Link, ...toPass } as any;

  return (
    <li>
      <ListItem {...listItemProps}>
        {icon && <ListItemIcon {...listItemIconProps}>{icon}</ListItemIcon>}
        <ListItemText
          // Must be inset if an icon does not exist
          inset={!icon}
          primary={primary}
          {...listItemTextProps}
        />
      </ListItem>
    </li>
  );
};

export default ListItemLink;
