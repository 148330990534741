/**
 * @file ImageRadioButton component. Button with thumbnail that opens a modal
 * when clicked.
 * @author Harris Lummis
 * @author Lucille Njoo
 * @author Bridget O'Connor
 */
import * as React from 'react';
import Button from '@material-ui/core/Button';
import {
  WithStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Modal from '@material-ui/core/Modal';

/** Unicode Check mark */
const CHECK_MARK = '\u2714';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3),
    },
    card: {
      padding: theme.spacing(2),
      display: 'flex' as 'flex',
      flexDirection: 'column' as 'column',
      justifyContent: 'space-around' as 'space-around',
      alignItems: 'center' as 'center',
    },
    thumbnail: {
      maxWidth: '400px',
      minWidth: '250px',
      height: 'auto' as 'auto',
      marginBottom: theme.spacing(),
    },
    imgModalContainer: {
      width: '80%',
      margin: 'auto' as 'auto',
    },
    imgModalCard: {
      margin: 'auto' as 'auto',
      padding: theme.spacing(2),
      width: '75%',
      height: 'auto' as 'auto',
      display: 'flex' as 'flex',
      flexDirection: 'column' as 'column',
      justifyContent: 'center' as 'center',
      alignItems: 'center' as 'center',
      alignContent: 'center' as 'center',
    },
  });

/** Properties for a single ImageRadioButton */
export interface ImageRadioButtonProps extends WithStyles<typeof styles> {
  disabled: boolean;
  /** The key of the image in the s3 bucket */
  imgSrc: string;
  /** Indicates whether or not the image is selected */
  selected: boolean;
  /** The click handler */
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => any;
  /** Value of the button component */
  value: any;
  /** Optionally provided initial state */
  initialState?: ImageRadioButtonState;
}

/** State for an ImageRadioButton */
export interface ImageRadioButtonState {
  /** Indicates whether the modal displaying the image content is open */
  open: boolean;
}

/**
 * The Image Radio Button component. Creates a button with a thumbnail that
 * opens a modal when clicked.
 */
export class ImageRadioButton extends React.Component<
  ImageRadioButtonProps,
  ImageRadioButtonState
> {
  constructor(props: ImageRadioButtonProps) {
    super(props);
    this.state = props.initialState || { open: false };
  }
  /** Handle modal display opening */
  public handleModalOpen = () => {
    this.setState({ open: true });
  };
  /** Handle modal display closing */
  public handleModalClose = () => {
    this.setState({ open: false });
  };
  public render() {
    const { classes, disabled, imgSrc, selected, onClick, value } = this.props;
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <img
            src={imgSrc}
            className={classes.thumbnail}
            onClick={this.handleModalOpen}
          />
          <Button
            variant="contained"
            color={selected ? 'primary' : 'default'}
            onClick={onClick}
            disabled={disabled}
            value={value}
          >
            {selected ? CHECK_MARK : 'Select'}
          </Button>
        </Card>
        <Modal
          aria-labelledby="plate image"
          aria-describedby="plate image"
          open={this.state.open}
          onClose={this.handleModalClose}
        >
          <div className={classes.imgModalContainer}>
            <Card className={classes.imgModalCard}>
              <img src={imgSrc} />
            </Card>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(ImageRadioButton);
