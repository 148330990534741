/**
 * @file Component for displaying an image thumbnail with a title
 * @author Harris Lummis
 */
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/styles';

/** Default properties passed to child Typography component */
export const DEFAULT_TYPOGRAPHY_PROPS: TypographyProps = {
  variant: 'body1',
  align: 'center',
};

const styles = {
  root: {},
  img: {
    width: '100%',
    height: 'auto',
  },
  typography: {},
};

export type TitledImageClassKey = keyof typeof styles;

const useStyles = makeStyles(styles);

/** Props used to create a TitledImage */
export interface TitledImageProps {
  /** Classes that override default styles */
  classes?: Partial<Record<TitledImageClassKey, string>>;
  /** Image click event handler */
  onClickImg?: (event: React.MouseEvent<HTMLElement>) => void;
  /** Src of image */
  imgSrc: string;
  /** Properties passed to Typography component */
  typographyProps?: TypographyProps;
  /** Title of image */
  title: string;
}

/** TitledImage is a component for displaying an image with a title */
export const TitledImage: FunctionComponent<TitledImageProps> = props => {
  const { onClickImg, imgSrc, typographyProps, title } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <img className={classes.img} onClick={onClickImg} src={imgSrc} />
      <Typography {...{ ...DEFAULT_TYPOGRAPHY_PROPS, ...typographyProps }}>
        {title}
      </Typography>
    </div>
  );
};

export default TitledImage;
