/**
 * @file A component that defines a button that can display a loading status
 * @author Harris Lummis
 */
import React, { FunctionComponent } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

/** Default styles applied to the component */
const styles = () =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    progress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  });

/** Props passed to create a LoadingButton */
export interface LoadingButtonProps extends ButtonProps {
  /** Classes applied as styles */
  classes: ButtonProps['classes'] & WithStyles<typeof styles>['classes'];
  /** Indicates whether loading is in progress */
  loading: boolean;
}

/**
 * A component that displays a button. If the loading prop is set to true, the
 * button will display a circular progress indicator, otherwise it will display
 * the provided text. Other than classes, all properties are passed directly to
 * the button component.
 */
export const LoadingButton: FunctionComponent<LoadingButtonProps> = props => {
  const { classes, loading, children, ...buttonProps } = props;
  const {
    wrapper: wrapperClass,
    progress: progressClass,
    ...buttonClasses
  } = classes;
  return (
    <div className={classes.wrapper}>
      <Button
        classes={buttonClasses}
        disabled={
          buttonProps.disabled === undefined ? loading : buttonProps.disabled
        }
        {...buttonProps}
      >
        {children}
        {loading && <CircularProgress className={progressClass} size={24} />}
      </Button>
    </div>
  );
};

export default withStyles(styles)(LoadingButton);
