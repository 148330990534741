/**
 * @file Global Side Menu Component
 * @author Harris Lummis
 */
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import findIndex from 'lodash/findIndex';
import React, { FunctionComponent } from 'react';
import CollapsibleListItem from '../CollapsibleListItem';
import ListItemLink from '../ListItemLink';
import { TypographyProps } from '@material-ui/core/Typography';

/** The actual width of the menu drawer in pixels */
const DRAWER_WIDTH = 240;

interface StyledListItemLinkProps {
  className?: string;
  to: string;
  path: string;
  primary: React.ReactNode;
  typographyVariant: TypographyProps['variant'];
  icon?: JSX.Element;
  selectedClass: string;
}

const StyledListItemLink: FunctionComponent<
  StyledListItemLinkProps
> = props => {
  const { className, typographyVariant, selectedClass, path, ...rest } = props;
  const appliedSelectedClass = path === rest.to ? selectedClass : undefined;
  return (
    <ListItemLink
      className={className}
      button={true}
      listItemIconProps={{
        classes: { root: appliedSelectedClass },
      }}
      listItemTextProps={{
        primaryTypographyProps: { variant: typographyVariant },
        classes: { primary: appliedSelectedClass },
      }}
      {...rest}
    />
  );
};

const styles = (theme: Theme) =>
  createStyles({
    drawerPaper: {
      position: 'relative',
      width: DRAWER_WIDTH,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    selected: {
      color: theme.palette.primary.main,
    },
    nested: {
      paddingLeft: theme.spacing(3),
    },
  });

/** Props used to create a side menu */
export interface SideMenuProps extends WithStyles<typeof styles> {
  /** The path of the current page */
  path: string;
  /** Function to be called upon close of the menu */
  onDrawerClose: (event: any) => any;
  /** Indicates open status of menu */
  open: boolean;
}

/** Side Menu state */
export interface SideMenuState {
  /** Indicates whether collapsible analytics menu is open */
  analyticsOpen: boolean;
  /** Indicates whether collapsible citations menu is open */
  citationsOpen: boolean;
  /** Indicates whether collapsible admin menu is open */
  adminOpen: boolean;
}

export class SideMenu extends React.Component<SideMenuProps, SideMenuState> {
  constructor(props: SideMenuProps) {
    super(props);
    const { path } = this.props;
    const splitPath = path.substr(1).split('/');

    this.state = {
      analyticsOpen:
        findIndex(splitPath, el => {
          return el === 'analytics';
        }) >= 0,
      citationsOpen: findIndex(splitPath, el => el === 'citations') >= 0,
      adminOpen: findIndex(splitPath, el => el === 'admin') >= 0,
    };
  }
  public handleCollapsibleClick = (menuItemName: string) => {
    const { adminOpen, analyticsOpen, citationsOpen } = this.state;
    if (menuItemName === 'analytics') {
      this.setState({ analyticsOpen: !analyticsOpen });
    } else if (menuItemName === 'citations') {
      this.setState({ citationsOpen: !citationsOpen });
    } else if (menuItemName === 'admin') {
      this.setState({ adminOpen: !adminOpen });
    }
  };
  public render() {
    const { open, classes, path, onDrawerClose } = this.props;

    return (
      <Drawer
        variant="persistent"
        anchor="left"
        classes={{ paper: classes.drawerPaper }}
        open={open}
      >
        <div className={classes.drawerHeader}>
          <IconButton
            className="sidemenu__close-button"
            onClick={onDrawerClose}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List
          component="nav"
          subheader={<ListSubheader component="div">Navigation</ListSubheader>}
        >
          <StyledListItemLink
            to="/"
            path={path}
            primary="Dashboard"
            typographyVariant="subtitle1"
            selectedClass={classes.selected}
            icon={<DashboardIcon />}
          />
          {/*
          <CollapsibleListItem
            icon={<EventNoteIcon />}
            title="Citations"
            expanded={this.state.citationsOpen}
            onClick={() => this.handleCollapsibleClick('citations')}
          >
            <StyledListItemLink
              className={classes.nested}
              to="/citations/pending"
              path={path}
              primary="Pending"
              typographyVariant="body1"
              selectedClass={classes.selected}
            />
            <StyledListItemLink
              className={classes.nested}
              to="/citations/issued"
              path={path}
              primary="Issued"
              typographyVariant="body1"
              selectedClass={classes.selected}
            />
          </CollapsibleListItem>
          */}
          <CollapsibleListItem
            icon={<TrackChangesIcon />}
            title="Analytics"
            expanded={this.state.analyticsOpen}
            onClick={() => this.handleCollapsibleClick('analytics')}
          >
            <StyledListItemLink
              className={classes.nested}
              to="/analytics/parking-traffic"
              path={path}
              primary="Parking and Traffic"
              typographyVariant="body1"
              selectedClass={classes.selected}
            />
            <StyledListItemLink
              className={classes.nested}
              to="/analytics/violations"
              path={path}
              primary="Violations"
              typographyVariant="body1"
              selectedClass={classes.selected}
            />
            <StyledListItemLink
              className={classes.nested}
              to="/analytics/deeper-insights"
              path={path}
              primary="Deeper Insights"
              typographyVariant="body1"
              selectedClass={classes.selected}
            />
          </CollapsibleListItem>
          <CollapsibleListItem
            icon={<AccountCircleIcon />}
            title="Admin"
            expanded={this.state.adminOpen}
            onClick={() => this.handleCollapsibleClick('admin')}
          >
            <StyledListItemLink
              className={classes.nested}
              to="/admin/user-management"
              path={path}
              primary="User Management"
              typographyVariant="body1"
              selectedClass={classes.selected}
            />
            <StyledListItemLink
              className={classes.nested}
              to="/admin/footage"
              path={path}
              primary="Request Footage"
              typographyVariant="body1"
              selectedClass={classes.selected}
            />
            <StyledListItemLink
              className={classes.nested}
              to="/admin/request-analytics"
              path={path}
              primary="Request Analytics"
              typographyVariant="body1"
              selectedClass={classes.selected}
            />
          </CollapsibleListItem>
        </List>
      </Drawer>
    );
  }
}

export default withStyles(styles)(SideMenu);
