/**
 * @file Library for working with authorization
 * @author Harris Lummis
 */
import { getUserGroups } from '../awsLib';
import includes from 'lodash/includes';

/** Arguments used to create an auth instance */
export interface AuthArgs {
  /** A function used for retrieving user groups */
  getUserGroups?: () => Promise<string[]>;
}

/** An object for working with authorization */
export interface Auth {
  /**
   * Check if the current user is an Automotus user
   * @return A promise that resolves to true if the user is an Automotus user
   */
  isAutomotusUser: () => Promise<boolean>;
}

/**
 * Create an object that uses the provided object's methods to check if a user is an Automotus user
 * @param obj The object that will receive the method
 */
const canCheckIsAutomotusUser = (obj: {
  getUserGroups: () => Promise<string[]>;
}) => ({
  async isAutomotusUser() {
    return includes(await obj.getUserGroups(), 'Automotus');
  },
});

/**
 * Create an Auth instance
 * @param args Arguments used to create the auth instance
 */
export function createAuth(args?: AuthArgs): Auth {
  const auth = {
    getUserGroups,
    ...args,
  };

  return { ...auth, ...canCheckIsAutomotusUser(auth) };
}
