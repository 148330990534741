/**
 * @file Definition of the DataRangePicker component
 * @author Harris Lummis
 */
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import includes from 'lodash/includes';
import { TimeRangeName, TimeRangeResolutionName, TimeRange } from './typings';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(),
    },
    buttonBase: {
      marginLeft: theme.spacing(),
      fontSize: 12,
      fontWeight: 'normal' as 'normal',
      padding: theme.spacing(),
      borderRadius: 3,
      borderWidth: 2,
      borderStyle: 'solid',
    },
    buttonEnabled: {
      borderColor: theme.palette.grey['600'],
      color: theme.palette.grey['600'],
      '&:hover': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: theme.palette.primary.main,
      },
    },
    buttonPressed: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      backgroundColor: theme.palette.primary.main,
    },
    buttonDisabled: {
      borderColor: theme.palette.grey['300'],
      color: theme.palette.grey['300'],
    },
  });

/** Properties of the Data Range Picker toolbar */
export interface DataRangePickerProps extends WithStyles<typeof styles> {
  /** Available ranges for selection. */
  allowedRanges?: TimeRangeName[];
  /**
   * Available resolutions for selection. Value depends on the currently
   * selected time range.
   * 1D: ['hour']
   * 1W: ['hour', 'day']
   * 1M: ['day', 'week']
   * 3M: ['day', 'week', 'month']
   * 1Y: ['day', 'week', 'month']
   */
  allowedResolutions: TimeRangeResolutionName[];
  /** The actual time range */
  timeRange?: TimeRange;
  /** The name of the selected time range */
  selectedRangeName: TimeRangeName;
  /** The name of the selected resolution */
  selectedResolutionName: TimeRangeResolutionName;
  /** A callback function to execute on change of the range */
  onRangeChange: (label: string) => (event: any) => any;
  /** A callback function to execute on change of the resolution */
  onResolutionChange: (label: string) => (event: any) => any;
}

const DataRangePicker: FunctionComponent<DataRangePickerProps> = props => {
  const {
    allowedRanges,
    classes,
    allowedResolutions,
    selectedRangeName,
    selectedResolutionName,
    onRangeChange,
    onResolutionChange,
  } = props;

  const rangeButtonSpecs = [
    {
      label: '1D',
      value: '1D', // TODO: should these values be numbers?
    },
    {
      label: '1W',
      value: '1W',
    },
    {
      label: '1M',
      value: '1M',
    },
    {
      label: '3M',
      value: '3M',
    },
    {
      label: '1Y',
      value: '1Y',
    },
  ];

  const resolutionButtonSpecs = [
    { label: 'hour', value: 'hour' },
    { label: 'day', value: 'day' },
    { label: 'week', value: 'week' },
    { label: 'month', value: 'month' },
  ];

  return (
    <Grid
      container={true}
      className={classes.container}
      direction="row"
      justify="space-between"
    >
      <Grid item={true} xs={12} sm={6}>
        <Grid container={true} alignContent="center" alignItems="center">
          {rangeButtonSpecs.map(({ label }, index) => {
            if (allowedRanges && !includes(allowedRanges, label)) {
              return;
            }
            return (
              <div
                key={label + index}
                className={classnames(
                  classes.buttonBase,
                  classes.buttonEnabled,
                  {
                    [classes.buttonPressed]: label === selectedRangeName,
                  },
                )}
                onClick={onRangeChange(label)}
              >
                {label}
              </div>
            );
          })}
        </Grid>
      </Grid>
      <Grid item={true} xs={12} sm={6}>
        <Grid
          container={true}
          justify="flex-end"
          alignContent="center"
          alignItems="center"
        >
          <Typography>View Data By:</Typography>
          {resolutionButtonSpecs.map(({ label }, index) => {
            const disabled = !includes(allowedResolutions, label);
            return (
              <div
                key={label + index}
                className={classnames(
                  classes.buttonBase,
                  { [classes.buttonEnabled]: !disabled },
                  {
                    [classes.buttonPressed]: label === selectedResolutionName,
                    [classes.buttonDisabled]: disabled,
                  },
                )}
                onClick={!disabled ? onResolutionChange(label) : undefined}
              >
                {label}
              </div>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(DataRangePicker);
