"use strict";

function __export(m) {
  for (var p in m) {
    if (!exports.hasOwnProperty(p)) exports[p] = m[p];
  }
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @file Master exporter for OpenALPR models
 * @author Harris Lummis
 */

__export(require("./Coordinate"));

__export(require("./RecognizeItem"));

__export(require("./RecognizeRequest"));

__export(require("./YesNo"));

__export(require("./PlateCandidate"));

__export(require("./PlateDetails"));

__export(require("./ProcessingTimes"));

__export(require("./RecognizeResponse"));

__export(require("./RecognizeResponseBody"));

__export(require("./RecognizeResponseHeaders"));

__export(require("./RegionOfInterest"));

__export(require("./VehicleCandidate"));

__export(require("./VehicleDetails"));