// TODO: handle multiple path parameters at same path
import stampit from 'stampit';
import { parse as parseQs } from 'querystring';
import {
  CreateUserParams,
  DeleteUserParams,
  UserResponseBody,
  ListUsersInGroupParams,
  UpdateUserParams,
} from '../controllers/userManagement';
import { RequestFootageParams } from '../controllers/footage';
import { APIResponseError } from './errors';
import sortBy from 'lodash/sortBy';
import { RetryAPIInstance } from './utils';

export interface APIOptions<TBody = any> {
  body?: TBody;
  headers?: {};
  response?: boolean;
}

interface ReqParamsToDemoCallParams {
  method: 'get' | 'put' | 'post' | 'delete';
  apiName: string;
  path: string;
  options: APIOptions;
}

export interface CallsDemoStamp extends stampit.Stamp {
  (...args: any[]): CallsDemoAPIInstance;
  timeoutGenerator: (...args: any[]) => number;
  mockResponders: any;
}

type DefaultSetter = (...args: any[]) => any;

export type CallsDemoAPIInstance = RetryAPIInstance;

/**
 * mockResponders looks like this
 * path: {
 *   get(...){ ... },
 *   someOtherPath: {
 *     get(...) { ... },
 *   },
 *   ':pathParam': {
 *     someOtherPath: {
 *       ...
 *     },
 *   }
 * }
 */

// Hide some data
const reqParamsToDemoCall = Symbol('reqParamsToDemoCall');

export const CallsDemoAPI = stampit()
  .deepConf({
    // Functions that will set defaults
    defaultSetters: [],
  })
  .statics({
    timeoutGenerator() {
      return 300 + Math.random() * 200;
    },
    [reqParamsToDemoCall](
      this: any,
      { method, apiName, path, options }: ReqParamsToDemoCallParams,
    ) {
      const queryParamsStartIndex = path.indexOf('?');
      const queryParams =
        queryParamsStartIndex >= 0
          ? parseQs(path.slice(queryParamsStartIndex + 1))
          : {};
      const splitPath = path
        .substr(1)
        .replace(/\?.*$/, '')
        .split('/');
      const pathParams = {};
      let curDemoReqPathObject = this.mockResponders;

      // Here we use a some iterator to interrupt loop execution when necessary
      splitPath.some((pathComponent, index) => {
        let nextDemoReqPathObject = curDemoReqPathObject[pathComponent];

        if (!nextDemoReqPathObject) {
          // If the current path component is not found, assume it is a path
          // parameter, then find its name. Note that this will fail if more
          // than one path parameter exists for the same path.

          const pathParamWithColon = Object.keys(curDemoReqPathObject)
            .filter(key => key.charAt(0) === ':')
            .pop();

          // If no parameter exists at that path, throw an error
          if (!pathParamWithColon) {
            throw new Error(`no endpoint defined at path ${path}`);
          }

          // Otherwise use the path param to determine the next available path
          nextDemoReqPathObject = curDemoReqPathObject[pathParamWithColon];
          pathParams[pathParamWithColon.slice(1)] = pathComponent;
        }

        curDemoReqPathObject = nextDemoReqPathObject;
        return false;
      });

      // If response has not yet been set, first try to hit the requested
      // method. If method is not found, throw an error.
      if (!curDemoReqPathObject) {
        throw new Error(
          `no endpoint for method ${method} defined at path ${path}`,
        );
      }

      // Use static context for this to pass static properties
      return curDemoReqPathObject[method].call(
        this,
        pathParams,
        queryParams,
        options,
      );
    },
  })
  .init(function(this: any, payload: any, { stamp }: any) {
    this.get = (apiName: string, path: string, options: any) => {
      return stamp[reqParamsToDemoCall]({
        method: 'get',
        apiName,
        path,
        options,
      });
    };
    this.post = (apiName: string, path: string, options: any) => {
      return stamp[reqParamsToDemoCall]({
        method: 'post',
        apiName,
        path,
        options,
      });
    };
    this.put = (apiName: string, path: string, options: any) => {
      return stamp[reqParamsToDemoCall]({
        method: 'put',
        apiName,
        path,
        options,
      });
    };
    this.delete = (apiName: string, path: string, options: any) => {
      return stamp[reqParamsToDemoCall]({
        method: 'delete',
        apiName,
        path,
        options,
      });
    };
    // Have to make a method for this, as defaults cannot be set in session
    // storage until AFTER a component has been mounted
    this.setDefaults = (...args: any[]) => {
      stamp.compose.deepConfiguration.defaultSetters.forEach(
        (setter: DefaultSetter) => {
          setter.call(stamp, args);
        },
      );
    };
  }) as CallsDemoStamp;

const DEFAULT_DEMO_USERS = [
  {
    Attributes: [
      { Name: 'firstName', Value: 'Gary' },
      { Name: 'lastName', Value: 'Bolton' },
      { Name: 'privilegeLevel', Value: 'admin' },
    ],
    Enabled: true,
    MFAOptions: {},
    UserCreateDate: new Date('2018-03-17T13:24:00').getTime(),
    UserLastModifiedDate: new Date('2018-03-17T13:24:00').getTime(),
    Username: 'gary.bolton@lmu.edu',
    UserStatus: 'CONFIRMED',
  },
  {
    Attributes: [
      { Name: 'firstName', Value: 'Lionel' },
      { Name: 'lastName', Value: 'Say' },
      { Name: 'privilegeLevel', Value: 'admin' },
    ],
    Enabled: true,
    MFAOptions: {},
    UserCreateDate: new Date('2017-08-12T16:16:25').getTime(),
    UserLastModifiedDate: new Date('2017-08-12T18:13:05').getTime(),
    Username: 'lionel.say@lmu.edu',
    UserStatus: 'CONFIRMED',
  },
];

export const HasAdminMocks = CallsDemoAPI.deepConf({
  defaults: {
    users: DEFAULT_DEMO_USERS,
  },
  defaultSetters: [
    function(this: any) {
      if (!this.storage.getItem('users')) {
        this.storage.setItem(
          'users',
          JSON.stringify(this.compose.deepConfiguration.defaults.users),
        );
      }
    },
  ],
})
  .statics({
    storage: {
      getItem(key: string) {
        return sessionStorage.getItem(key);
      },
      setItem(key: string, value: string) {
        sessionStorage.setItem(key, value);
      },
    },
  })
  .deepStatics({
    mockResponders: {
      admin: {
        footage: {
          post(
            this: any,
            pathParams: {},
            queryParams: {},
            options: APIOptions<RequestFootageParams>,
          ) {
            const {
              body,
              response: shouldReturnFullResponse = false,
            } = options;

            return new Promise((resolve, reject) => {
              if (!body) {
                return reject(new Error('body cannot be undefined'));
              }
              setTimeout(() => {
                resolve(shouldReturnFullResponse ? { status: 201 } : {});
              }, this.timeoutGenerator());
            });
          },
        },
        user: {
          post(
            this: any,
            pathParams: {},
            queryParams: {},
            options: APIOptions<CreateUserParams>,
          ) {
            const {
              body,
              response: shouldReturnFullResponse = false,
            } = options;

            return new Promise((resolve, reject) => {
              if (!body) {
                return reject(new Error('body cannot be undefined'));
              }
              setTimeout(() => {
                const newUser = {
                  Attributes: [
                    { Name: 'firstName', Value: body.firstName },
                    { Name: 'lastName', Value: body.lastName },
                    { Name: 'privilegeLevel', Value: body.privilegeLevel },
                  ],
                  Enabled: true,
                  UserCreateDate: new Date().getTime(),
                  UserLastModifiedDate: new Date().getTime(),
                  Username: body.email,
                  UserStatus: 'UNCONFIRMED',
                };
                const currentUsersString = this.storage.getItem('users');
                const currentUsers = currentUsersString
                  ? (JSON.parse(currentUsersString) as Array<{}>)
                  : [];
                currentUsers.push(newUser);
                this.storage.setItem('users', JSON.stringify(currentUsers));
                // TODO: add firstname and lastname attributes to real users
                resolve(
                  shouldReturnFullResponse
                    ? { data: newUser, status: 201 }
                    : newUser,
                );
              }, this.timeoutGenerator());
            });
          },
          delete(
            this: any,
            pathParams: {},
            queryParams: {},
            options: APIOptions<DeleteUserParams>,
          ) {
            const {
              body,
              response: shouldReturnFullResponse = false,
            } = options;

            return new Promise((resolve, reject) => {
              if (!body) {
                return reject(new Error('body cannot be undefined'));
              }
              setTimeout(() => {
                const currentUsersString = this.storage.getItem('users');

                const currentUsers: UserResponseBody[] = currentUsersString
                  ? JSON.parse(currentUsersString)
                  : [];
                const userToDeleteIndex = currentUsers.findIndex(
                  (user: UserResponseBody) => {
                    return user.Username === body.email;
                  },
                );
                if (userToDeleteIndex < 0) {
                  const errorMessage = `could not find user with email ${body.email}`;
                  // TODO: make this depend on shouldreturnfullresponse
                  return reject(
                    new APIResponseError(errorMessage, {
                      status: 404,
                      body: { message: errorMessage },
                    }),
                  );
                }
                const userToDelete = currentUsers[userToDeleteIndex];
                currentUsers.splice(userToDeleteIndex, 1);
                this.storage.setItem('users', JSON.stringify(currentUsers));
                resolve(
                  shouldReturnFullResponse
                    ? { data: userToDelete, status: 200 }
                    : userToDelete,
                );
              }, this.timeoutGenerator());
            });
          },
          put(
            this: any,
            pathParams: {},
            queryParams: {},
            options: APIOptions<UpdateUserParams>,
          ) {
            const {
              body,
              response: shouldReturnFullResponse = false,
            } = options;

            return new Promise((resolve, reject) => {
              if (!body) {
                return reject(new Error('body cannot be undefined'));
              }

              setTimeout(() => {
                const currentUsersString = this.storage.getItem('users');
                const currentUsers: UserResponseBody[] = currentUsersString
                  ? JSON.parse(currentUsersString)
                  : [];

                const userToUpdateIndex = currentUsers.findIndex(
                  (user: UserResponseBody) => {
                    return user.Username === body.email;
                  },
                );

                if (userToUpdateIndex < 0) {
                  const errorMessage = `could not find user with email ${body.email}`;
                  return reject(
                    shouldReturnFullResponse
                      ? new APIResponseError(errorMessage, {
                          status: 404,
                          response: { message: errorMessage },
                        })
                      : new Error(errorMessage),
                  );
                }

                // Set the privilegeLevel and UserLastModifiedDate attribute s
                const userToUpdate = currentUsers[
                  userToUpdateIndex
                ] as UserResponseBody;
                userToUpdate.UserLastModifiedDate = new Date().getTime();
                const privilegeAttributeIndex = userToUpdate.Attributes.findIndex(
                  attr => {
                    return attr.Name === 'privilegeLevel';
                  },
                );
                userToUpdate.Attributes[privilegeAttributeIndex].Value =
                  body.privilegeLevel;
                // Might not be necessary but just in case...
                currentUsers[userToUpdateIndex] = userToUpdate;
                this.storage.setItem('users', JSON.stringify(currentUsers));
                resolve(
                  shouldReturnFullResponse
                    ? { data: userToUpdate, status: 201 }
                    : userToUpdate,
                );
              }, this.timeoutGenerator());
            });
          },
        },
        users: {
          get(
            this: any,
            pathParams: {},
            queryParams: ListUsersInGroupParams,
            options: APIOptions,
          ) {
            const { groupName, limit = 100 } = queryParams;
            const { response: shouldReturnFullResponse = false } = options;

            return new Promise((resolve, reject) => {
              // TODO: parameterize this return
              const currentUsersString = this.storage.getItem('users');

              const currentUsers: UserResponseBody[] = currentUsersString
                ? (JSON.parse(currentUsersString) as UserResponseBody[])
                : this.compose.deepConfiguration.defaults.users;

              let resultUsers = currentUsers;

              if (currentUsers.length > 0) {
                const usersWithSortedAttributes = currentUsers.map(
                  (user: UserResponseBody) => {
                    user.Attributes = sortBy(user.Attributes, 'Name');
                    return user;
                  },
                );
                const privilegeAttributeIndex = usersWithSortedAttributes[0].Attributes.findIndex(
                  attr => attr.Name === 'privilegeLevel',
                );
                const usersInGroup = currentUsers.filter(
                  (user: UserResponseBody) => {
                    return (
                      user.Attributes[privilegeAttributeIndex].Value ===
                      groupName
                    );
                  },
                );
                resultUsers = usersInGroup.slice(0, limit);
              }

              setTimeout(() => {
                resolve(
                  shouldReturnFullResponse
                    ? { data: resultUsers, status: 200 }
                    : resultUsers,
                );
              }, this.timeoutGenerator());
            });
          },
        },
      },
    },
  }) as CallsDemoStamp;
