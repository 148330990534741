import { HasAPI, HasAPIInstance } from '../stamps/aws';
import { RetryAPIInstance } from '../libs/utils';
import { FullResponse } from '../libs/awsLib';

// TODO: add user to params

/** Parameters used to request footage */
export interface RequestFootageParams {
  /** The epoch start time of requested footage */
  startTime: number;
  /** The length (in seconds) of requested footage */
  lengthInSeconds: number;
  /** The camera from which footage is being requested */
  cameraId: string;
}

export interface FootageControllerInstance extends HasAPIInstance {
  /**
   * Make a request for new footage
   * @param params The parameters used to request footage
   * @returns A promise that resolves if footage is successfully requested
   */
  requestFootage(params: RequestFootageParams): PromiseLike<FullResponse<{}>>;
}

export type FootageControllerStamp = ({
  apiName,
  API,
}: {
  apiName: string;
  API: RetryAPIInstance;
}) => FootageControllerInstance;

const FootageController = HasAPI.methods({
  requestFootage(
    this: FootageControllerInstance,
    params: RequestFootageParams,
  ) {
    return this.request({
      method: 'post',
      path: '/admin/footage',
      body: params,
    });
  },
}) as FootageControllerStamp;

export default FootageController;
