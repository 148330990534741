"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @file Definition of the RecognizeResponse model
 * @author Harris Lummis
 */

var openalpr_pb_1 = require("@automotus/openalpr-pb");

var mixins_1 = require("@atlas/models/mixins");

var OpenALPR_1 = require("@atlas/models/models/OpenALPR");

var utils_1 = require("@atlas/models/utils");
/**
 * Represents a response returned from the /recognize endpoint of the OpenALPR
 * Cloud API
 */


var RecognizeResponse =
/** @class */
function (_super) {
  __extends(RecognizeResponse, _super);
  /**
   * Creates a new instance with the provided props. All properties
   * that can be expressed as a model are converted to models during
   * construction
   * @param props Properties used to construct the model
   */


  function RecognizeResponse(props) {
    var _this = _super.call(this) || this;

    var _a = props || RecognizeResponse.DEFAULTS,
        statusCode = _a.statusCode,
        headers = _a.headers,
        body = _a.body;

    _this.statusCode = typeof statusCode === 'number' ? statusCode : RecognizeResponse.DEFAULTS.statusCode;
    _this.headers = headers instanceof OpenALPR_1.RecognizeResponseHeaders ? headers : headers ? new OpenALPR_1.RecognizeResponseHeaders(headers) : RecognizeResponse.DEFAULTS.headers;
    _this.body = body instanceof OpenALPR_1.RecognizeResponseBody ? body : body ? new OpenALPR_1.RecognizeResponseBody(body) : RecognizeResponse.DEFAULTS.body;
    return _this;
  }
  /**
   * Parses an object and creates a new instance from it
   * @param obj The object to parse
   * @return A new RecognizeResponse instance
   */


  RecognizeResponse.FromObject = function (obj) {
    return utils_1.parseObject(this, obj);
  };
  /**
   * Creates a new RecognizeResponse instance from a wire-format encoded message
   * @param buf The buffer containing wire-format encoded RecognizeResponse
   * @return A new RecognizeResponse instance
   */


  RecognizeResponse.FromBuffer = function (buf) {
    return utils_1.fromBuffer(this, buf);
  };
  /** Default values of enumerable properties */


  RecognizeResponse.DEFAULTS = {
    statusCode: 0,
    headers: null,
    body: null
  };
  return RecognizeResponse;
}(mixins_1.Bufferable(openalpr_pb_1.openalpr.cloud.RecognizeResponse));

exports.RecognizeResponse = RecognizeResponse;
mixins_1.applyMixins(RecognizeResponse, mixins_1.Objectable, mixins_1.IsBufferable);
exports.default = RecognizeResponse;