import React from 'react';
import { Redirect, Route } from 'react-router-dom';

interface Props {
  component: React.ComponentType<any>;
  props: any;
  [rest: string]: any;
}

function querystring(name: string, url: string = window.location.href) {
  name = name.replace(/[[]]/g, '\\$&z');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ''));
}

export default ({ component: C, props: cProps, ...rest }: Props) => {
  const redirect = querystring('redirect');
  return (
    <Route
      {...rest}
      render={props =>
        !cProps.isAuthenticated ? (
          <C {...props} {...cProps} />
        ) : (
          <Redirect to={redirect || '/'} />
        )
      }
    />
  );
};
