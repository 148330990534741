import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField/TextField';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Typography from '@material-ui/core/Typography';
import { API } from 'aws-amplify';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import FootageController, {
  FootageControllerInstance,
} from '../controllers/footage';
import { HasAdminMocks } from '../libs/demoApi';
import { RetryAPI } from '../libs/utils';

// TODO: Disable invalid length select
// TODO: populate camera selection with list of available cameras
// TODO: display loading

interface RequestAnalyticsProps extends RouteComponentProps<string> {
  isAuthenticated: boolean;
}

interface RequestAnalyticsContentProps
  extends WithStyles<'requestPaper' | 'lineItem'> {
  isDemo: boolean;
}

interface RequestAnalyticsContentState {
  /** The content of the text box */
  requestText: string;
  /** True if submission is in process */
  submitting: boolean;
  /** True if submission completed */
  submitted: boolean;
}

const decorateRequestAnalyticsContent = withStyles((theme: Theme) => ({
  requestPaper: {
    padding: theme.spacing(2),
    whiteSpace: 'normal' as 'normal',
  },
  lineItem: {
    marginBottom: theme.spacing(),
  },
}));

const RequestAnalyticsContent = decorateRequestAnalyticsContent(
  class extends React.Component<
    RequestAnalyticsContentProps,
    RequestAnalyticsContentState
  > {
    // TODO: change to request analytics controller
    public footageController: FootageControllerInstance;
    constructor(props: RequestAnalyticsContentProps) {
      super(props);
      this.footageController = FootageController({
        apiName: 'parking',
        API: RetryAPI({ api: props.isDemo ? HasAdminMocks() : API }),
      });
      this.state = {
        requestText: '',
        submitting: false,
        submitted: false,
      };
    }
    /** Set timer on mount */
    // componentDidMount() {
    //   if (!this.props.isAuthenticated) {
    //     return;
    //   }
    //   // Set an interval through window to allow timerID to be a number
    //   // this.timerID = window.setInterval(() => this.tick(), 1000);
    // }
    /** Remove timers prior to unmount */
    public componentWillUnmount() {
      // clearInterval(this.timerID);
    }
    /** Tick the timer to update current time */
    // tick() {
    //   const now = new Date();
    //   this.setState({
    //     now,
    //     // minDate: moment(now).subtract(config.MAX_FOOTAGE_TIME_AGO_HOURS, 'h').toDate(),
    //   });
    // }
    public handleRequestTextChange = (event: any) => {
      this.setState({
        requestText: event.target.value as string,
      });
    };
    public validateForm = () => {
      const { requestText } = this.state;
      return requestText.length > 0;
    };
    public handleSubmit = async (event: any) => {
      event.preventDefault();
      // const { requestText } = this.state;
      this.setState({
        submitting: true,
      });
      try {
        // TODO: NO FOOTAGE CONTROLLER
        // await this.footageController.requestFootage({
        //   startTime: selectedTime.valueOf(),
        //   lengthInSeconds: selectedLength,
        //   cameraId: 'hello',
        // });
        this.setState({
          submitted: true,
        });
      } catch (err) {
        alert(err);
      }
      this.setState({
        submitting: false,
      });
      return;
    };
    public handleRequestAgainClick = (event: any) => {
      this.setState({
        requestText: '',
        submitted: false,
      });
    };
    public renderSuccess() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.lineItem}>
            Your request has been submitted. We'll send you an email as soon as
            an Automotus agent can get to your request.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleRequestAgainClick}
          >
            Make another request?
          </Button>
        </React.Fragment>
      );
    }
    public renderForm() {
      const { classes } = this.props;
      const { requestText } = this.state;
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.lineItem}>
            Please write a detailed description of the new datapoints or
            analyses you would like to see. You may also reach us at our&nbsp;
            <Tooltip title="+1 (805) 504-5750">
              <a href="tel:+18066055750">dedicated support line</a>
            </Tooltip>
            &nbsp;between 9am and 6pm PST
          </Typography>
          {/* contains all rows */}
          <Grid
            container={true}
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item={true} xs={12}>
              <form onSubmit={this.handleSubmit}>
                <Grid container={true} direction="row" justify="center">
                  <Grid item={true} xs={12} className={classes.lineItem}>
                    <TextField
                      autoFocus={true}
                      multiline={true}
                      onChange={this.handleRequestTextChange}
                      value={requestText}
                      InputProps={{ style: { height: 200 } }}
                      inputProps={{ style: { height: 200 } }}
                      style={{ minWidth: '100%', height: 200 }}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Grid
                      container={true}
                      direction="row"
                      justify="center"
                      alignContent="center"
                      alignItems="center"
                    >
                      <Button variant="contained" color="primary" type="submit">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
    public render() {
      const { submitted } = this.state;
      const { classes } = this.props;
      return (
        <Grid container={true} direction="row" justify="center">
          <Grid item={true} xs={12} sm={8} md={7} lg={6} xl={5}>
            <Paper className={classes.requestPaper}>
              <Typography variant="h6" className={classes.lineItem}>
                Request Additional Analytics
              </Typography>
              {submitted ? this.renderSuccess() : this.renderForm()}
            </Paper>
          </Grid>
        </Grid>
      );
    }
  },
);

export default class RequestAnalytics extends React.Component<
  RequestAnalyticsProps
> {
  constructor(props: RequestAnalyticsProps) {
    super(props);
  }
  public render() {
    return this.props.isAuthenticated ? (
      // TODO: parameterize demoness
      <RequestAnalyticsContent isDemo={true} />
    ) : (
      <div />
    );
  }
}
