"use strict";

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @file Definition of the RecognizeItem model
 * @author Harris Lummis
 */

var mixins_1 = require("@atlas/models/mixins");

var RecognizeRequest_1 = require("./RecognizeRequest");

var RecognizeResponse_1 = require("./RecognizeResponse");

var dynamodb_auto_marshaller_1 = require("@aws/dynamodb-auto-marshaller");

var errors_1 = require("@atlas/models/errors");

var utils_1 = require("@atlas/models/utils");
/** Default values of enumerable properties of an OpenALPRRecognizeItem */


var defaults = {
  imageKey: '',
  clientId: '',
  objectId: '',
  infractionId: '',
  requestData: null,
  responseData: null
};
/**
 * Represents a stored item with data regarding a request and response to the
 * OpenALPR Cloud API's /recognize endpoint
 */

var RecognizeItem =
/** @class */
function () {
  /**
   * Creates a new OpenALPRRecognizeItem instance using the provided properties,
   * using default values when properties are not defined
   * @param props Properties used to define the instance
   */
  function RecognizeItem(props) {
    /** The key of the image recognized */
    this.imageKey = defaults.imageKey;
    /** The UUID of the client to whom the image belongs */

    this.clientId = defaults.clientId;
    /** The UUID of the object that committed the infraction */

    this.objectId = defaults.objectId;
    /** The UUID of the infraction to which the image relates */

    this.infractionId = defaults.infractionId;
    /** Request data */

    this.requestData = defaults.requestData;
    /** Response data */

    this.responseData = defaults.responseData;

    if (!props) {
      return;
    }

    var _a = props.imageKey,
        imageKey = _a === void 0 ? defaults.imageKey : _a,
        _b = props.clientId,
        clientId = _b === void 0 ? defaults.clientId : _b,
        _c = props.objectId,
        objectId = _c === void 0 ? defaults.objectId : _c,
        _d = props.infractionId,
        infractionId = _d === void 0 ? defaults.infractionId : _d,
        _e = props.requestData,
        requestData = _e === void 0 ? defaults.requestData : _e,
        _f = props.responseData,
        responseData = _f === void 0 ? defaults.responseData : _f;
    this.imageKey = imageKey || defaults.imageKey;
    this.clientId = clientId || defaults.clientId;
    this.objectId = objectId || defaults.objectId;
    this.infractionId = infractionId || defaults.infractionId;
    this.requestData = requestData instanceof RecognizeRequest_1.RecognizeRequest ? requestData : requestData ? new RecognizeRequest_1.RecognizeRequest(requestData) : defaults.requestData;
    this.responseData = responseData instanceof RecognizeResponse_1.RecognizeResponse ? responseData : responseData ? new RecognizeResponse_1.RecognizeResponse(responseData) : defaults.responseData;
  }
  /**
   * Creates a new OpenALPRRecognizeItem instance
   * @param props Properties used to create the instance
   * @return A new OpenALPRRecognizeItem instance
   */


  RecognizeItem.create = function (props) {
    return new this(props);
  };
  /**
   * Parses an object and creates a new instance from it
   * @param obj The object to parse
   * @return A new OpenALPRRecognizeItem instance
   */


  RecognizeItem.FromObject = function (obj) {
    return utils_1.parseObject(this, obj);
  };
  /**
   * Creates a new OpenALPRRecognizeItem from a DynamoDB item representation
   * @param item The DynamoDB item used to source properties
   * @param marshaller The marshaller used to unmarshal values. Defaults to the
   * standard marshaller
   * @return A new OpenALPRRecognizeItem instance created from the unmarshalled
   * item
   */


  RecognizeItem.FromDDBItem = function (item, marshaller) {
    if (marshaller === void 0) {
      marshaller = new dynamodb_auto_marshaller_1.Marshaller();
    }

    var unmarshalled = marshaller.unmarshallItem(item || {});

    var imageKey = unmarshalled.ImageKey,
        clientId = unmarshalled.ClientId,
        objectId = unmarshalled.ObjectId,
        infractionId = unmarshalled.InfractionId,
        requestData = unmarshalled.RequestData,
        responseData = unmarshalled.ResponseData,
        rest = __rest(unmarshalled, ["ImageKey", "ClientId", "ObjectId", "InfractionId", "RequestData", "ResponseData"]);

    if (Object.keys(rest).length > 0) {
      throw new errors_1.InvalidTypeError("unexpected keys during unmarshalling: " + Object.keys(rest));
    }

    return this.create({
      imageKey: imageKey,
      clientId: clientId,
      objectId: objectId,
      infractionId: infractionId,
      requestData: requestData && RecognizeRequest_1.RecognizeRequest.FromBuffer(requestData),
      responseData: responseData && RecognizeResponse_1.RecognizeResponse.FromBuffer(responseData)
    });
  };
  /**
   * Marshals values of the calling instance into a valid DynamoDB item
   * @param marshaller The marshaller used to marshal values. Defaults to the standard marshaller
   * @return A DynamoDB item representing the calling instance
   */


  RecognizeItem.prototype.toDDBItem = function (marshaller) {
    if (marshaller === void 0) {
      marshaller = new dynamodb_auto_marshaller_1.Marshaller();
    }

    var toMarshal = {
      ImageKey: this.imageKey,
      ClientId: this.clientId,
      ObjectId: this.objectId,
      InfractionId: this.infractionId,
      RequestData: this.requestData && this.requestData.toBuffer(),
      ResponseData: this.responseData && this.responseData.toBuffer()
    };
    return marshaller.marshallItem(toMarshal);
  };
  /** Default values of enumerable properties of an OpenALPRRecognizeItem */


  RecognizeItem.DEFAULTS = defaults;
  return RecognizeItem;
}();

exports.RecognizeItem = RecognizeItem;
mixins_1.applyMixins(RecognizeItem, mixins_1.Objectable);
exports.default = RecognizeItem;