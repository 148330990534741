/**
 * @file Definition of the NewUserDisplay component
 * @author Harris Lummis
 */
import React, { FunctionComponent } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
  WithStyles,
} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme: Theme) =>
  createStyles({
    main: {
      margin: 'auto',
      marginTop: '25%',
    },
    title: {
      height: '2em',
      padding: theme.spacing(),
      paddingLeft: theme.spacing(2),
      paddingRight: 0,
      backgroundColor: theme.palette.primary.main,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
    },
    titleText: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    iconButton: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    blockInput: {
      padding: theme.spacing(),
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
  });

export interface NewUserDisplayProps extends WithStyles<typeof styles> {
  newUserFirstName: string;
  newUserLastName: string;
  newUserEmail: string;
  newUserPrivilegeLevel: string;
  isAddingNewUser: boolean;
  validateForm: (...args: any[]) => boolean;
  onClose: (event: any) => any;
  onSubmit: (event: any) => any;
  onChange: (event: any) => any;
}

export const NewUserDisplay: FunctionComponent<NewUserDisplayProps> = props => {
  const {
    classes,
    newUserFirstName,
    newUserLastName,
    newUserEmail,
    newUserPrivilegeLevel,
    // isAddingNewUser,
    onChange,
    onClose,
    onSubmit,
    validateForm,
  } = props;

  return (
    <Grid container={true} direction="row" justify="center">
      <Grid item={true} xs={8} sm={7} md={6} lg={4} xl={3}>
        <Paper className={classes.main}>
          <div className={classes.title}>
            <Typography className={classes.titleText} variant="h6">
              Create User
            </Typography>
            <IconButton className={classes.iconButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.content}>
            <form onSubmit={onSubmit}>
              <div className={classes.blockInput}>
                <TextField
                  id="newUserFirstName"
                  name="newUserFirstName"
                  label="First Name"
                  value={newUserFirstName}
                  autoFocus={true}
                  onChange={onChange}
                />
              </div>
              <div className={classes.blockInput}>
                <TextField
                  id="newUserLastName"
                  name="newUserLastName"
                  label="Last Name"
                  value={newUserLastName}
                  onChange={onChange}
                />
              </div>
              <div className={classes.blockInput}>
                <TextField
                  id="newUserEmail"
                  name="newUserEmail"
                  label="Email"
                  value={newUserEmail}
                  onChange={onChange}
                />
              </div>
              <div className={classes.blockInput}>
                <FormControl style={{ minWidth: '100%' }}>
                  <InputLabel>Privileges</InputLabel>
                  <Select
                    name="newUserPrivilegeLevel"
                    value={newUserPrivilegeLevel}
                    onChange={onChange}
                    style={{ width: '100%' }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="normal">Normal User</MenuItem>
                    <MenuItem value="admin">Administrator</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  disabled={!validateForm()}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Create User
                </Button>
              </div>
            </form>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(NewUserDisplay);
