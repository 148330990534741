import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import config from './config';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Theme from './Theme';
import AtlasClient from '@atlas/client';

Amplify.configure({
  API: {
    endpoints: [
      {
        endpoint: config.apiGateway.URL,
        name: 'parking',
        region: config.apiGateway.REGION,
      },
    ],
  },
  Auth: {
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    region: config.s3.REGION,
  },
});

AtlasClient.init({
  API: Amplify.API,
  apiName: 'parking',
  logger: new Amplify.Logger('atlas-api'),
});

ReactDOM.render(
  <Router>
    <ThemeProvider theme={Theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </Router>,
  document.getElementById('root') as HTMLElement,
);
registerServiceWorker();
