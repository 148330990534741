import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Auth } from 'aws-amplify';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './Login/Login.css';

interface ChangePasswordContentProps {
  userHasAuthenticated: (hasAuthenticated: true) => void;
}

type ChangePasswordContentPropsWithStyles = ChangePasswordContentProps &
  WithStyles<'buttonProgress' | 'card' | 'heading' | 'textField' | 'wrapper'>;

interface ChangePasswordContentState {
  oldPassword: string;
  password: string;
  isLoading: boolean;
}

const decorator = withStyles((theme: Theme) => ({
  heading: {
    color: theme.palette.primary.main,
    fontWeight: 300 as 300,
    marginLeft: 'auto' as 'auto',
    marginRight: 'auto' as 'auto',
    marginTop: theme.spacing(3),
  },
  card: {
    paddingTop: theme.spacing(2),
    width: 400,
    height: 300,
    margin: 'auto' as 'auto',
    textAlign: 'center' as 'center',
  },
  textField: {
    marginLeft: 50,
    marginRight: 50,
    width: 200,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: 'auto' as 'auto',
    marginTop: theme.spacing(3),
    position: 'relative' as 'relative',
  },
}));

const ChangePasswordContent = decorator(
  class extends React.Component<
    ChangePasswordContentPropsWithStyles,
    ChangePasswordContentState
  > {
    constructor(props: ChangePasswordContentPropsWithStyles) {
      super(props);
      this.state = {
        oldPassword: '',
        isLoading: false,
        password: '',
      };
    }

    public validateForm() {
      const { oldPassword, password } = this.state;
      return (
        oldPassword.length > 0 &&
        password.length > 0 &&
        oldPassword === password
      );
    }

    public handleChange = (inputName: keyof ChangePasswordContentState) => (
      event: any,
    ) => {
      // yes this is an ugly hack but computed property names are a bitch when it
      // comes to state
      this.setState({ [inputName]: event.target.value as string } as any);
    };

    public handleSubmit = (event: any) => {
      const { oldPassword, password } = this.state;
      event.preventDefault();
      this.setState({ isLoading: true });
      Auth.currentUserInfo()
        .then(user => {
          console.log(user); // tslint:disable-line
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            return Auth.completeNewPassword(user, password, null);
          }
          return Auth.changePassword(user, oldPassword, password);
        })
        .then(() => {
          this.props.userHasAuthenticated(true);
        })
        .catch(err => {
          alert(err);
          this.setState({ isLoading: false });
        });
    };

    public render() {
      const { classes } = this.props;
      const { isLoading, oldPassword, password } = this.state;

      return (
        <Card className={classes.card}>
          <h2 className={classes.heading}>Change Password</h2>
          <form onSubmit={this.handleSubmit}>
            <TextField
              autoFocus={true}
              id="oldPassword"
              label="old password"
              className={classes.textField}
              value={oldPassword}
              onChange={this.handleChange('oldPassword')}
              type="password"
              margin="normal"
            />
            <TextField
              id="password"
              label="new password"
              className={classes.textField}
              value={password}
              onChange={this.handleChange('password')}
              type="password"
              margin="normal"
            />
            <div className={classes.wrapper}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!this.validateForm() || isLoading}
              >
                Change Password
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </form>
        </Card>
      );
    }
  },
);

interface ChangePasswordProps extends RouteComponentProps<string> {
  userHasAuthenticated: (hasAuthenticated: true) => void;
}

export type Props = ChangePasswordProps & RouteComponentProps<string>;

class ChangePassword extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public handleRequiredChangePassword = () => {
    this.props.history.push(`/change-password/${this.props.location.search}`);
  };

  public render() {
    return (
      <div className="Login">
        <ChangePasswordContent
          userHasAuthenticated={this.props.userHasAuthenticated}
        />
      </div>
    );
  }
}

export default ChangePassword;
