import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React from 'react';
import { getRealTimeAnalytics } from '../libs/apiLib';
import { formatPercentage } from '../libs/formatLib';
import { RealTimeAnalyticsData } from '../typings/responses';
import './RealTimeAnalytics.css';

const decorateDisplay = withStyles((theme: Theme) => ({
  root: {
    whiteSpace: 'normal' as 'normal',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  titleContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  contentContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  loader: {
    width: '95%',
    margin: 'auto' as 'auto',
  },
}));

/** Props used for creating a real-time analytics display */
export interface RealTimeAnalyticsDisplayProps {
  refreshTime?: number;
  /** Callback for error handling */
  onError?: (err: Error) => any;
}

type RealTimeAnalyticsDisplayPropsWithStyles = RealTimeAnalyticsDisplayProps &
  WithStyles<'root' | 'loader' | 'contentContainer' | 'titleContainer'>;

export interface RealTimeAnalyticsDisplayState {
  /** indicates whether animation should trigger */
  shouldAnimate: boolean;
  /** The current ratio of occupied spots */
  occupancyRatio?: number;
  /** The number of occupied spots */
  spotsOccupied?: number;
  /** The number of available spots */
  spotsAvailable?: number;
  /** The number of vehicles counted in the last 15 minutes */
  vehicleCount?: number;
  /** Indicates whether the display is loading */
  loading: boolean;
  /** Indicates whether an animation has taken place. Only animated if so. */
  hasAnimated: boolean;
}

const RealTimeAnalyticsDisplay = decorateDisplay(
  class extends React.Component<
    RealTimeAnalyticsDisplayPropsWithStyles,
    RealTimeAnalyticsDisplayState
  > {
    private refreshTimer: number = 0;
    private animateTimer: number = 0;
    /**
     * Create a new real time analytics display
     * @param props The props used to create a display
     * @see RealTimeAnalyticsDisplayProps
     */
    constructor(props: RealTimeAnalyticsDisplayPropsWithStyles) {
      super(props);
      this.state = {
        spotsOccupied: undefined,
        spotsAvailable: undefined,
        occupancyRatio: undefined,
        vehicleCount: undefined,
        loading: true,
        shouldAnimate: false,
        hasAnimated: false,
      };
    }

    /**
     * Update data, unset loading status, and set an interval refresh
     */
    public async componentDidMount() {
      const { onError, refreshTime = 60 } = this.props;
      try {
        await this.updateData();
        this.setState({ loading: false });
        // Set up an interval to reload data at regular intervals
        this.refreshTimer = window.setInterval(() => {
          this.updateData();
        }, refreshTime * 1000);
      } catch (err) {
        if (onError) {
          this.setState({ loading: false });
          onError(err);
        } else {
          alert(err);
        }
      }
    }

    /**
     * Remove timers prior to unmount
     */
    public componentWillUnmount() {
      clearInterval(this.refreshTimer);
      clearTimeout(this.animateTimer);
    }

    /**
     * Update analytics data for display and set state
     */
    public async updateData() {
      // Enable cache usage
      const time = moment()
        .startOf('minute')
        .valueOf();
      const { data } = await getRealTimeAnalytics({ time });
      const { aggregateResults } = data as RealTimeAnalyticsData;
      const { occupancy, counts } = aggregateResults;
      this.setState({
        spotsOccupied: occupancy.occupied,
        spotsAvailable: occupancy.available,
        occupancyRatio: occupancy.ratio,
        vehicleCount: counts.vehicles,
        // Only animate if we have animated at least once before
        shouldAnimate: this.state.hasAnimated ? true : false,
        hasAnimated: true,
      });
      // Only set a timeout if animation has already occurred once before
      if (this.state.hasAnimated) {
        this.animateTimer = window.setTimeout(() => {
          this.setState({ shouldAnimate: false });
        }, 600);
      }
    }

    /**
     * Render a loading animation
     */
    public renderLoader() {
      const { classes } = this.props;
      return (
        <Grid item={true} xs={12}>
          <LinearProgress className={classes.loader} />
        </Grid>
      );
    }

    /**
     * Render the content of the component. Trigger animations by changing
     * classnames of typography compnents. See css file for description of
     * animation
     */
    public renderContent() {
      const {
        spotsOccupied,
        spotsAvailable,
        occupancyRatio,
        vehicleCount,
        shouldAnimate,
      } = this.state;

      // This shouldn't happen but if it does just render a blank div
      if (
        spotsOccupied === undefined ||
        spotsAvailable === undefined ||
        occupancyRatio === undefined ||
        vehicleCount === undefined
      ) {
        return <div />;
      }

      return (
        <React.Fragment>
          <Grid item={true} xs={4}>
            <Typography
              align="center"
              variant="subtitle1"
              color={shouldAnimate ? 'inherit' : 'initial'}
              className={shouldAnimate ? 'text' : undefined}
            >
              {spotsOccupied} Spots Occupied ({formatPercentage(occupancyRatio)}
              )
            </Typography>
          </Grid>
          <Grid item={true} xs={4}>
            <Typography
              align="center"
              variant="subtitle1"
              color={shouldAnimate ? 'inherit' : 'initial'}
              className={shouldAnimate ? 'text' : undefined}
            >
              {spotsAvailable} Spots Available (
              {formatPercentage(1 - occupancyRatio)})
            </Typography>
          </Grid>
          <Grid item={true} xs={4}>
            <Typography
              align="center"
              variant="subtitle1"
              color={shouldAnimate ? 'inherit' : 'initial'}
              className={shouldAnimate ? 'text' : undefined}
            >
              {vehicleCount} Vehicles Seen in Past 15min
            </Typography>
          </Grid>
        </React.Fragment>
      );
    }

    public render() {
      const { loading } = this.state;

      const { classes } = this.props;

      return (
        <Grid
          container={true}
          direction="row"
          justify="flex-start"
          alignItems="center"
          alignContent="center"
          className={classes.root}
        >
          <Grid item={true} xs={2} className={classes.titleContainer}>
            <Typography variant="h5">Right Now</Typography>
          </Grid>
          <Grid item={true} xs={10}>
            <Grid
              container={true}
              direction="row"
              justify={loading ? 'center' : 'space-between'}
              alignItems="center"
              alignContent="center"
              className={classes.contentContainer}
            >
              {loading ? this.renderLoader() : this.renderContent()}
            </Grid>
          </Grid>
        </Grid>
      );
    }
  },
);

export default RealTimeAnalyticsDisplay;
