import CircularProgress from '@material-ui/core/CircularProgress';
import Done from '@material-ui/icons/Done';
import React, { FunctionComponent, useState } from 'react';
// TODO: implement failure feedback mechanism
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';

type VerificationButtonContainerClassKey =
  | 'root'
  | 'actionDoneBase'
  | 'verifyActionDone'
  | 'declineActionDone'
  | 'button'
  | 'buttons';

const styles: Styles<
  Theme,
  VerificationButtonContainerProps,
  VerificationButtonContainerClassKey
> = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 'auto',
    width: '200px',
    paddingLeft: 4,
    paddingRight: 4,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: '40%',
    height: '36px',
  },
  actionDoneBase: {
    width: '100%',
    textAlign: 'center',
  },
  verifyActionDone: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  declineActionDone: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
});

const useStyles = makeStyles(styles);

export interface VerificationButtonContainerProps {
  /** A function to handle a citation decline */
  onDeclineHandler: (violationId: string) => any;
  /** A function to handle a citation acceptance */
  onVerificationHandler: (violationId: string) => any;
  /** The id of the violation to be verified or declined */
  violationId: string;
}

export const VerificationButtonContainer: FunctionComponent<
  VerificationButtonContainerProps
> = props => {
  const classes = useStyles(props);
  const { onVerificationHandler, onDeclineHandler, violationId } = props;
  const [isVerified, setIsVerified] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  /**
   * Handle a click on the verification button. Will result in an update to
   * the verification record then display an indication upon success or failure
   * of verification, then remove the row that was verified
   * @returns a promise
   */
  const handleVerificationClick = async () => {
    setIsVerified(true);
    try {
      // await verifyViolation(this.props.violationId);
      setIsVerified(true);
      setIsLoading(false);
      setTimeout(() => {
        onVerificationHandler(violationId);
        // Have to reset isVerified state to avoid persisting feedback
        // when component renders with new data
        setIsVerified(false);
      }, 1000);
    } catch (err) {
      alert(err);
    }
    setIsLoading(false);
  };
  /**
   * Handle a click on the decline button. Will result in an update to the
   * verification record then display an indication of a successful result or
   * an error if necessary, then remove the row upon success.
   * @returns a promise
   */
  const handleDeclineClick = async () => {
    setIsLoading(true);
    try {
      // await declineViolation(this.props.violationId);
      setIsDeclined(true);
      setIsLoading(false);
      setTimeout(() => {
        onDeclineHandler(violationId);
        // Have to reset isVerified state to avoid persisting feedback
        // when component renders with new data
        setIsDeclined(false);
      }, 1000);
    } catch (err) {
      alert(err);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {isVerified || isDeclined ? (
        <div
          className={classnames(classes.actionDoneBase, {
            [classes.verifyActionDone]: isVerified,
            [classes.declineActionDone]: isDeclined,
          })}
        >
          <Done />
        </div>
      ) : (
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            color="primary"
            onClick={handleVerificationClick}
          >
            Verify
          </Button>
          <Button
            className={classes.button}
            color="secondary"
            onClick={handleDeclineClick}
          >
            Decline
          </Button>
        </div>
      )}
    </div>
  );
};

export default VerificationButtonContainer;
