"use strict";

function __export(m) {
  for (var p in m) {
    if (!exports.hasOwnProperty(p)) exports[p] = m[p];
  }
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

__export(require("./useFetchS3ObjectUrl"));

var useFetchS3ObjectUrl_1 = require("./useFetchS3ObjectUrl");

exports.default = useFetchS3ObjectUrl_1.default;