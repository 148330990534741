import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { API } from 'aws-amplify';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import config from '../config';
import FootageController, {
  FootageControllerInstance,
} from '../controllers/footage';
import { HasAdminMocks } from '../libs/demoApi';
import { RetryAPI } from '../libs/utils';

// TODO: Disable invalid length select
// TODO: populate camera selection with list of available cameras
// TODO: display loading

interface FootageProps extends RouteComponentProps<string> {
  isAuthenticated: boolean;
}

interface FootageContentProps extends WithStyles<'requestPaper' | 'lineItem'> {
  isDemo: boolean;
}

interface FootageContentState {
  /** The current date */
  now: Date;
  /** The minimum selectable date (i.e. 48 hrs into the past) */
  minDate: Date;
  /** The id of the selected camera */
  selectedCameraId: string;
  /** The currently selected date. Defaults to now - 10 min */
  selectedTime: moment.Moment;
  /** The currently selected length (in seconds). Defaults to 10 min */
  selectedLength: number;
  /** True if submission is in process */
  submitting: boolean;
  /** True if submission completed */
  submitted: boolean;
}

const ALLOWED_LENGTHS_IN_MINUTES = [1, 2, 5, 10, 15, 20];
const DEMO_CAMERAS = [
  { id: 'cam1', name: 'Hannon Lot Entrance' },
  { id: 'cam2', name: 'Hannon Lot Exit' },
  { id: 'cam3', name: 'Burns FA Street North' },
  { id: 'cam4', name: 'Burns FA Street South' },
];

const decorateFootageContent = withStyles((theme: Theme) => ({
  requestPaper: {
    padding: theme.spacing(2),
    whiteSpace: 'normal' as 'normal',
  },
  lineItem: {
    marginBottom: theme.spacing(),
  },
}));

const FootageContent = decorateFootageContent(
  class extends React.Component<FootageContentProps, FootageContentState> {
    // private timerID: number;
    public footageController: FootageControllerInstance;
    constructor(props: FootageContentProps) {
      super(props);
      // this.timerID = -1;
      const now = new Date();
      this.footageController = FootageController({
        apiName: 'parking',
        API: RetryAPI({ api: props.isDemo ? HasAdminMocks() : API }),
      });
      this.state = {
        now,
        minDate: moment(now)
          .subtract(config.MAX_FOOTAGE_TIME_AGO_HOURS, 'h')
          .toDate(),
        selectedTime: moment(now).subtract(10, 'm'),
        selectedLength: 10 * 60,
        submitting: false,
        submitted: false,
        selectedCameraId: '',
      };
    }
    /** Set timer on mount */
    // componentDidMount() {
    //   if (!this.props.isAuthenticated) {
    //     return;
    //   }
    //   // Set an interval through window to allow timerID to be a number
    //   // this.timerID = window.setInterval(() => this.tick(), 1000);
    // }
    /** Remove timers prior to unmount */
    public componentWillUnmount() {
      // clearInterval(this.timerID);
    }
    /** Tick the timer to update current time */
    // tick() {
    //   const now = new Date();
    //   this.setState({
    //     now,
    //     // minDate: moment(now).subtract(config.MAX_FOOTAGE_TIME_AGO_HOURS, 'h').toDate(),
    //   });
    // }
    public handleDateChange = (date: moment.Moment | null) => {
      if (date) {
        this.setState({
          selectedTime: date,
        });
      }
    };
    public handleLengthChange = (event: any) => {
      this.setState({
        selectedLength: event.target.value as number,
      });
    };
    public handleCameraChange = (event: any) => {
      this.setState({
        selectedCameraId: event.target.value as string,
      });
    };
    public validateForm = () => {
      const { selectedLength, selectedTime, now } = this.state;
      // Make sure that interval does not extend past present
      return (
        moment(selectedTime)
          .add(selectedLength, 's')
          .valueOf() <= now.getTime()
      );
    };
    public isLengthAllowed(lengthInSeconds: number) {
      const { selectedTime, now } = this.state;
      // If selected time invalid, it's definitely not allowed
      if (!selectedTime) {
        return false;
      }

      return now.getTime() >= selectedTime.valueOf() + lengthInSeconds * 1000;
    }
    public handleSubmit = async (event: any) => {
      event.preventDefault();
      const { selectedTime, selectedLength } = this.state;
      try {
        await this.footageController.requestFootage({
          startTime: selectedTime.valueOf(),
          lengthInSeconds: selectedLength,
          cameraId: 'hello',
        });
        this.setState({
          submitted: true,
        });
      } catch (err) {
        alert(err);
      }
      return;
    };
    public handleRequestAgainClick = (event: any) => {
      const now = new Date();
      this.setState({
        now,
        submitted: false,
        minDate: moment(now)
          .subtract(config.MAX_FOOTAGE_TIME_AGO_HOURS, 'h')
          .toDate(),
        selectedTime: moment(now).subtract(10, 'm'),
        selectedLength: 10 * 60,
      });
    };
    public renderSuccess() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.lineItem}>
            Your request has been submitted. We'll notify you when the footage
            is ready.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleRequestAgainClick}
          >
            Request more footage
          </Button>
        </React.Fragment>
      );
    }
    public renderForm() {
      const { classes } = this.props;
      const {
        now,
        minDate,
        selectedTime,
        selectedLength,
        selectedCameraId,
      } = this.state;
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.lineItem}>
            Submit a request for recorded footage from the past 48hrs.
          </Typography>
          {/* contains all rows */}
          <Grid container={true} direction="row">
            <Grid item={true} xs={12}>
              <form onSubmit={this.handleSubmit}>
                <Grid container={true} direction="row">
                  <Grid item={true} xs={12} className={classes.lineItem}>
                    <FormControl style={{ minWidth: 100 }}>
                      <InputLabel style={{ whiteSpace: 'nowrap' }}>
                        Camera
                      </InputLabel>
                      <Select
                        value={selectedCameraId}
                        onChange={this.handleCameraChange}
                        inputProps={{
                          name: 'cameraId',
                          id: 'cameraId',
                        }}
                      >
                        {[<MenuItem key="NONE" value="" />].concat(
                          DEMO_CAMERAS.map(({ id, name }) => {
                            return (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            );
                          }),
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={12} sm={6} className={classes.lineItem}>
                    {/* contains first row of content */}
                    <DateTimePicker
                      disableFuture={true}
                      minDate={minDate}
                      maxDate={now}
                      label="Footage start time"
                      minDateMessage="Footage cannot be accessed beyond 48 hours ago."
                      maxDateMessage="Footage from the future cannot yet be accessed"
                      value={selectedTime}
                      onChange={this.handleDateChange}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} className={classes.lineItem}>
                    <FormControl>
                      <InputLabel style={{ whiteSpace: 'nowrap' }}>
                        Footage Length
                      </InputLabel>
                      <Select
                        value={selectedLength}
                        onChange={this.handleLengthChange}
                        inputProps={{
                          name: 'footageLength',
                          id: 'footageLength',
                        }}
                      >
                        {ALLOWED_LENGTHS_IN_MINUTES.map(lengthInMinutes => {
                          const valInSeconds = lengthInMinutes * 60;
                          return (
                            <MenuItem
                              key={lengthInMinutes}
                              value={valInSeconds}
                              disabled={!this.isLengthAllowed(valInSeconds)}
                            >
                              {`${lengthInMinutes}min`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
    public render() {
      const { submitted } = this.state;
      const { classes } = this.props;
      return (
        <Grid container={true} direction="row" justify="center">
          <Grid item={true} xs={12} sm={8} md={6} lg={5} xl={4}>
            <Paper className={classes.requestPaper}>
              <Typography variant="h6" className={classes.lineItem}>
                Request Footage
              </Typography>
              {submitted ? this.renderSuccess() : this.renderForm()}
            </Paper>
          </Grid>
        </Grid>
      );
    }
  },
);

export default class Footage extends React.Component<FootageProps> {
  constructor(props: FootageProps) {
    super(props);
  }
  public render() {
    return this.props.isAuthenticated ? (
      // TODO: parameterize demoness
      <FootageContent isDemo={true} />
    ) : (
      <div />
    );
  }
}
