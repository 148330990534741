import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import LinkHeader from 'http-link-header';
import concat from 'lodash/concat';
import each from 'lodash/each';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import moment from 'moment-timezone';
import qs from 'qs';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AnalyticsDisplay, { UpdateParams } from '../components/AnalyticsDisplay';
import Table, { Column } from '../components/DataTable';
import { ErrorBar } from '../components/errors';
import RealTimeAnalyticsDisplay from '../components/RealTimeAnalyticsDisplay';
import { retryApi } from '../libs/apiLib';
import {
  formatDuration,
  formatViolationType,
  getRemainingAPIPathsFromLinks,
} from '../libs/formatLib';
import { aggregateChunks } from '../libs/utils';
import BigTableStyle from '../styles/BigTable';
import { styleDatasets } from '../styles/LineGraphs';
import { PendingCitation } from '../typings/responses';
import './Home.css';

export interface Props extends RouteComponentProps<string> {
  isAuthenticated: boolean;
}
export interface State {
  isLoading: boolean;
}

interface StatefulPendingCitation extends PendingCitation {
  /** Indicates whether or not the citation has been verified */
  verified?: boolean;
  /** Indicates verification time, if any */
  verifiedAt?: number;
}

const DEMO_CITATIONS: StatefulPendingCitation[] = [
  {
    images: {
      park: '/images/7WVX691-park.jpg',
      plate: '/images/7WVX691-plate.jpg',
      violation: '/images/7WVX691-cite.jpg',
    },
    license_plate: '7WVX691',
    end_time: moment.tz('2018-04-17 10:13:08', 'America/Los_Angeles').toDate(),
    start_time: moment
      .tz('2018-04-17 09:41:08', 'America/Los_Angeles')
      .toDate(),
    state: 'CA',
    verified: false,
    violation_id: 'violation1',
    violation_type: 'MAX_TIME',
  },
  {
    images: {
      park: '/images/6RGJ148-park.jpg',
      plate: '/images/6RGJ148-plate.jpg',
      violation: '/images/6RGJ148-cite.jpg',
    },
    license_plate: '6RGJ148',
    end_time: moment.tz('2018-04-17 11:43:35', 'America/Los_Angeles').toDate(),
    start_time: moment
      .tz('2018-04-17 11:11:35', 'America/Los_Angeles')
      .toDate(),
    state: 'CA',
    verified: false,
    violation_id: 'violation2',
    violation_type: 'MAX_TIME',
  },
  {
    images: {
      park: '/images/7EUU195-park.jpg',
      plate: '/images/7EUU195-plate.png',
      violation: '/images/7EUU195-cite.jpg',
    },
    license_plate: '7EUU195',
    end_time: moment.tz('2018-04-10 15:12:22', 'America/Los_Angeles').toDate(),
    start_time: moment
      .tz('2018-04-10 14:40:22', 'America/Los_Angeles')
      .toDate(),
    state: 'CA',
    verified: false,
    violation_id: 'violation3',
    violation_type: 'MAX_TIME',
  },
  {
    images: {
      park: '/images/7LZT891-park.jpg',
      plate: '/images/7LZT891-plate.jpg',
      violation: '/images/7LZT891-cite.jpg',
    },
    license_plate: '7LZT891',
    end_time: moment.tz('2018-04-10 10:50:40', 'America/Los_Angeles').toDate(),
    start_time: moment
      .tz('2018-04-10 10:18:40', 'America/Los_Angeles')
      .toDate(),
    state: 'CA',
    verified: false,
    violation_id: 'violation4',
    violation_type: 'MAX_TIME',
  },
  {
    images: {
      park: '/images/7RYG758-park.jpg',
      plate: '/images/7RYG758-plate.jpg',
      violation: '/images/7RYG758-cite.jpg',
    },
    license_plate: '7RYG758',
    end_time: moment.tz('2018-04-12 10:55:55', 'America/Los_Angeles').toDate(),
    start_time: moment
      .tz('2018-04-12 10:23:55', 'America/Los_Angeles')
      .toDate(),
    state: 'CA',
    verified: false,
    violation_id: 'violation5',
    violation_type: 'MAX_TIME',
  },
];

const CITATION_COLUMNS = [
  {
    id: 'license_plate',
    label: 'Plate # (State)',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (plateText: string, rowValues: PendingCitation) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {plateText + ' (' + rowValues.state + ')'}
        </p>
      );
    },
  },
  {
    id: 'violation_type',
    label: 'Type',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (violationType: string) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {formatViolationType(violationType)}
        </p>
      );
    },
  },
  {
    id: 'end_time',
    label: 'Recorded At',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (time: Date) => {
      return <p style={BigTableStyle.tableRowChild}>{time.toLocaleString()}</p>;
    },
  },
];

const TIME_COLUMNS = {
  hour: {
    id: 'hourTime',
    label: 'Hour',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (dayTime: number) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {new Date(dayTime).toLocaleString()}
        </p>
      );
    },
  },
  day: {
    id: 'dayTime',
    label: 'Day',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (dayTime: number) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {new Date(dayTime).toLocaleDateString()}
        </p>
      );
    },
  },
  week: {
    id: 'dayTime',
    label: 'Week',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (dayTime: number) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {new Date(dayTime).toLocaleDateString()}
        </p>
      );
    },
  },
  month: {
    id: 'dayTime',
    label: 'Month',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (dayTime: number) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {new Date(dayTime).toLocaleDateString()}
        </p>
      );
    },
  },
};

const PARKING_VALUE_COLUMNS: Column[] = [
  {
    id: 'parks',
    label: '# Parks',
    sortable: true,
    disablePadding: false,
    numeric: true,
  },
  {
    id: 'vehicles',
    label: '# Vehicles',
    sortable: true,
    disablePadding: false,
    numeric: true,
  },
  {
    id: 'violations',
    label: '# Violations',
    sortable: true,
    disablePadding: false,
    numeric: true,
  },
];

const INSIGHT_VALUE_COLUMNS: Column[] = [
  {
    id: 'avgOccupancy',
    label: 'Occupancy',
    sortable: true,
    disablePadding: false,
    numeric: true,
    render: (occupancy: number) => {
      return <p>{occupancy.toFixed(3)}</p>;
    },
  },
  {
    id: 'avgPassedVacant',
    label: '# Passed Vacant',
    sortable: true,
    disablePadding: false,
    numeric: true,
    render: (passedVacant: number) => {
      return <p>{passedVacant.toFixed(3)}</p>;
    },
  },
  {
    id: 'avgParkTime',
    label: 'Park Time',
    sortable: true,
    disablePadding: false,
    numeric: true,
    render: (timeInSeconds: number) => {
      const duration = moment.duration(timeInSeconds, 's');
      return <p>{formatDuration(duration)}</p>;
    },
  },
];

const updateInsightData = async (updateParams: UpdateParams) => {
  const { timeRange, order } = updateParams;
  let path = '/analytics/spots/daily?';

  path += qs.stringify({
    timeRange: [timeRange.start, timeRange.end],
    order,
  });
  try {
    const { data, headers } = await retryApi.get('parking', path, {
      response: true,
    });
    const { rows } = data;
    const links = LinkHeader.parse(headers.link);
    const remainingPaths = getRemainingAPIPathsFromLinks(links, 2);

    return Promise.all(
      remainingPaths.map(remainingPath => {
        return retryApi.get('parking', remainingPath, null);
      }),
    ).then(results => {
      const allData = concat(
        rows,
        flatten(map(results, result => result.data.rows)),
      );
      return aggregateChunks(allData, 7, [
        'avgOccupancy',
        'avgPassedVacant',
        'avgParkTime',
      ]);
    });
  } catch (err) {
    throw err;
  }
};

const updateParkingData = async (updateParams: UpdateParams) => {
  const { timeRange, order } = updateParams;
  let path = '/analytics/daily?';

  path += qs.stringify({
    timeRange: [timeRange.start, timeRange.end],
    order,
  });
  try {
    const { data, headers } = await retryApi.get('parking', path, {
      response: true,
    });
    const { rows } = data;
    const links = LinkHeader.parse(headers.link);
    const remainingPaths = getRemainingAPIPathsFromLinks(links, 2);

    return Promise.all(
      remainingPaths.map(remainingPath => {
        return retryApi.get('parking', remainingPath, null);
      }),
    ).then(results => {
      return concat(rows, flatten(map(results, result => result.data.rows)));
    });
  } catch (err) {
    throw err;
  }
};

const decorateHomeContent = withStyles((theme: Theme) => ({
  analyticsDisplay: {
    padding: theme.spacing(),
  },
  analyticsDisplayChart: {
    padding: theme.spacing(),
    width: '100%',
  },
  content: {
    display: 'flex' as 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between' as 'space-between',
    flexGrow: 1,
  },
  widget: {
    marginBottom: theme.spacing(2),
  },
  widgetColumn: {
    width: '100%',
    marginRight: theme.spacing(2),
  },
  titleButton: {
    cursor: 'pointer' as 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  titleButtonIndent: {
    marginLeft: theme.spacing(),
  },
}));

interface HomeContentState {
  pendingCitations: any[];
  err?: Error;
}

interface HomeContentProps {
  onTitleClick: (title: string) => any;
}
type HomeContentPropsWithStyles = HomeContentProps &
  WithStyles<
    | 'content'
    | 'widget'
    | 'widgetColumn'
    | 'titleButton'
    | 'titleButtonIndent'
    | 'analyticsDisplay'
    | 'analyticsDisplayChart'
  >;

const HomeContent = decorateHomeContent(
  class extends React.Component<HomeContentPropsWithStyles, HomeContentState> {
    constructor(props: HomeContentPropsWithStyles) {
      super(props);
      this.state = {
        pendingCitations: [],
        err: undefined,
      };
    }
    public async componentDidMount() {
      await this.updatePendingCitations();
    }
    /** Callback for handling an insurmountable error. Will trigger display of error bar */
    public handleError = (err: Error) => {
      this.setState({ err });
    };
    /** Callback to be fired upon press of error bar close button */
    public handleErrorBarClose = () => {
      this.setState({ err: undefined });
    };
    /** Callback to be fired upon press of error bar retry button */
    public handleErrorBarRetry = () => {
      window.location.reload();
    };
    public updatePendingCitations = async (
      orderBy?: string,
      order?: string,
    ) => {
      let pendingCitations: PendingCitation[] = [];
      // TODO: work with non-demo as well
      const previouslyVerifiedCitationString = sessionStorage.getItem(
        'verifiedCitations',
      );
      const previouslyDeclinedCitationString = sessionStorage.getItem(
        'declinedCitations',
      );
      const previouslyHandledIds: string[] = [];

      if (
        typeof previouslyVerifiedCitationString &&
        previouslyVerifiedCitationString
      ) {
        previouslyHandledIds.push(
          ...JSON.parse(previouslyVerifiedCitationString).map(
            (citation: StatefulPendingCitation) => citation.violation_id,
          ),
        );
      }
      if (
        typeof previouslyDeclinedCitationString &&
        previouslyDeclinedCitationString
      ) {
        previouslyHandledIds.push(
          ...JSON.parse(previouslyDeclinedCitationString).map(
            (citation: StatefulPendingCitation) => citation.violation_id,
          ),
        );
      }

      if (previouslyHandledIds.length === 0) {
        pendingCitations = DEMO_CITATIONS;
      } else {
        pendingCitations = DEMO_CITATIONS.filter(citation => {
          // Only return unverified citations
          return previouslyHandledIds.indexOf(citation.violation_id) < 0;
        }).map(citation => {
          citation.verified = false;
          return citation;
        });
      }

      pendingCitations.sort((c1, c2) => {
        return order === 'asc'
          ? c1[orderBy!] < c2[orderBy!]
            ? -1
            : 1
          : c2[orderBy!] < c1[orderBy!]
          ? -1
          : 1;
      });

      this.setState({ pendingCitations });
    };
    public render() {
      const { onTitleClick, classes } = this.props;
      const { err, pendingCitations } = this.state;
      return (
        <div className={classes.content}>
          <ErrorBar
            err={err}
            onClose={this.handleErrorBarClose}
            onRetry={this.handleErrorBarRetry}
          />
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <Paper>
                <RealTimeAnalyticsDisplay onError={this.handleError} />
              </Paper>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={6}>
              <AnalyticsDisplay
                title="Parking Analytics (Past Week)"
                className={classes.widgetColumn}
                chartHeight={100}
                componentTitle={
                  <div
                    className={classnames(
                      classes.titleButton,
                      classes.titleButtonIndent,
                    )}
                    onClick={onTitleClick('/analytics/parking-traffic')}
                  >
                    <Typography variant="h5" color="inherit">
                      Parking Analytics (Past Week)
                    </Typography>
                  </div>
                }
                displayAverages={false}
                displayDataRangePicker={false}
                displayFooter={false}
                displayHeader={false}
                timeColumnMap={TIME_COLUMNS}
                valueColumns={PARKING_VALUE_COLUMNS}
                defaultTimeRangeName="1W"
                defaultTimeRangeResolution="day"
                defaultTimeAxisUnit="day"
                defaultOrderBy="dayTime"
                maxDecimalPrecision={3}
                updateData={(updateParams: UpdateParams) => {
                  return updateParkingData(updateParams).catch(updateErr => {
                    this.handleError(updateErr);
                    return [];
                  });
                }}
                chartType="line"
                meanFunction={(dataPoints: any) => {
                  return (
                    dataPoints.reduce((sum: any, { y }: { y: number }) => {
                      return sum + y;
                    }, 0) / dataPoints.length // tslint:disable-line
                  );
                }}
                singlePaper={true}
                // TODO: put chart options in state
                chartOptions={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    xAxes: [
                      {
                        type: 'time',
                        display: true,
                        time: {
                          unit: 'day', // TODO: modularize
                        },
                        scaleLabel: {
                          display: false,
                          labelString: 'Date',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        id: '# Parks',
                        type: 'linear',
                        display: false,
                      },
                      {
                        id: '# Vehicles',
                        type: 'linear',
                        display: false,
                      },
                      {
                        id: '# Violations',
                        type: 'linear',
                        display: false,
                      },
                    ],
                  },
                }}
                formatChartData={(timeResolution: string, data: any[]) => {
                  const timeColumnName =
                    timeResolution === 'hour' ? 'hourTime' : 'dayTime';
                  const parkData: [string, Array<{ x: Date; y: number }>] = [
                    '# Parks',
                    [],
                  ];
                  const violationData: [
                    string,
                    Array<{ x: Date; y: number }>,
                  ] = ['# Violations', []];
                  const vehicleData: [string, Array<{ x: Date; y: number }>] = [
                    '# Vehicles',
                    [],
                  ];
                  each(data, row => {
                    const x = new Date(row[timeColumnName]); // TODO: modularize this
                    parkData[1].push({ x, y: row.parks });
                    violationData[1].push({ x, y: row.violations });
                    vehicleData[1].push({ x, y: row.vehicles });
                  });
                  return {
                    datasets: styleDatasets([
                      parkData,
                      violationData,
                      vehicleData,
                    ]).map(obj => {
                      return { ...obj, yAxisID: obj.label };
                    }),
                  };
                }}
              />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={6}>
              {/* <div className={classes.widgetColumn}> */}
              <div className={classes.widget}>
                <AnalyticsDisplay
                  title="Hi"
                  componentTitle={
                    <div
                      className={classes.titleButton}
                      onClick={onTitleClick('/analytics/deeper-insights')}
                    >
                      <Typography
                        variant="h5"
                        color="inherit"
                        className={classes.titleButton}
                      >
                        Insights (Past Month)
                      </Typography>
                    </div>
                  }
                  tableTitle="Insights (Past Month)"
                  displayAverages={false}
                  displayChart={false}
                  displayDataRangePicker={false}
                  displayFooter={false}
                  timeColumnMap={TIME_COLUMNS}
                  valueColumns={INSIGHT_VALUE_COLUMNS}
                  defaultTimeRangeName="1M"
                  defaultTimeRangeResolution="week"
                  defaultOrderBy="dayTime"
                  maxDecimalPrecision={3}
                  updateData={(params: UpdateParams) => {
                    return updateInsightData(params).catch(updateErr => {
                      this.handleError(updateErr);
                      return [];
                    });
                  }}
                  chartType="line"
                  meanFunction={(dataPoints: any) => {
                    return (
                      dataPoints.reduce((sum: number, { y }: { y: number }) => {
                        return sum + y;
                      }, 0) / dataPoints.length
                    );
                  }}
                  // TODO: put chart options in state
                  chartOptions={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      xAxes: [
                        {
                          type: 'time',
                          display: true,
                          time: {
                            unit: 'day', // TODO: modularize
                          },
                          scaleLabel: {
                            display: false,
                            labelString: 'Date',
                          },
                        },
                      ],
                      yAxes: [
                        {
                          id: 'Avg. Occupancy',
                          type: 'linear',
                          display: false,
                        },
                        {
                          id: 'Avg. # Passed Vacant',
                          type: 'linear',
                          display: false,
                        },
                        {
                          id: 'Avg. Park Time (s)',
                          type: 'linear',
                          display: false,
                        },
                      ],
                    },
                  }}
                  formatChartData={(timeResolution: string, data: any[]) => {
                    const timeColumnName =
                      timeResolution === 'hour' ? 'hourTime' : 'dayTime';
                    const occupancyData: [
                      string,
                      Array<{ x: Date; y: number }>,
                    ] = ['Avg. Occupancy', []];
                    const passedVacantData: [
                      string,
                      Array<{ x: Date; y: number }>,
                    ] = ['Avg. # Passed Vacant', []];
                    const timeData: [string, Array<{ x: Date; y: number }>] = [
                      'Avg. Park Time (s)',
                      [],
                    ];
                    each(data, row => {
                      const x = new Date(row[timeColumnName]); // TODO: modularize this
                      occupancyData[1].push({ x, y: row.avgOccupancy });
                      passedVacantData[1].push({ x, y: row.avgPassedVacant });
                      timeData[1].push({ x, y: row.avgParkTime });
                    });
                    return {
                      datasets: styleDatasets([
                        occupancyData,
                        passedVacantData,
                        timeData,
                      ]).map(obj => {
                        return { ...obj, yAxisID: obj.label };
                      }),
                    };
                  }}
                />
              </div>
              <div className={classes.widget}>
                <Table
                  title="Pending Citations"
                  componentTitle={
                    <div
                      className={classes.titleButton}
                      onClick={onTitleClick('/citations/pending')}
                    >
                      <Typography
                        variant="h5"
                        color="inherit"
                        className={classes.titleButton}
                      >
                        Pending Citations
                      </Typography>
                    </div>
                  }
                  displayFooter={false}
                  small={true}
                  columns={CITATION_COLUMNS}
                  data={pendingCitations}
                  displayHover={true}
                  orderBy="end_time"
                  order="desc"
                  enableSelect={false}
                  enableSelectAll={false}
                  totalRows={5}
                  updateData={(orderBy?: string, order?: 'asc' | 'desc') =>
                    this.updatePendingCitations(orderBy, order)
                  }
                />
              </div>
            </Grid>
          </Grid>
        </div>
      );
    }
  },
);

export default class Home extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }
  public async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
  }
  public handleTitleClick = (path: string) => () => {
    this.props.history.push(path);
  };
  public renderLander() {
    return (
      <div className="lander">
        <h1>Automotus Client Portal</h1>
      </div>
    );
  }
  public renderPage() {
    return <HomeContent onTitleClick={this.handleTitleClick} />;
  }
  public render() {
    return (
      <div>
        {this.props.isAuthenticated ? this.renderPage() : this.renderLander()}
      </div>
    );
  }
}
