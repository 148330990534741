"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @file Definition of the RecognizeRequest model
 * @author Harris Lummis
 */

var openalpr_pb_1 = require("@automotus/openalpr-pb");

var utils_1 = require("@atlas/models/utils");

var mixins_1 = require("@atlas/models/mixins");
/**
 * Represents a request to OpenALPR cloud
 */


var RecognizeRequest =
/** @class */
function (_super) {
  __extends(RecognizeRequest, _super);
  /**
   * Creates a new OpenALPRRequest instance
   * @param properties Properties used to construct the OpenALPRRequest
   */


  function RecognizeRequest(properties) {
    return _super.call(this, properties) || this;
  }
  /**
   * Creates a new OpenALPRRequest from a plain object
   * @param obj The object to create from
   * @return A new OpenALPRRequest instance
   */


  RecognizeRequest.FromObject = function (obj) {
    return new this(utils_1.parseObject(this, obj));
  };
  /**
   * Creates a new OpenALPRRequest from a wire-format message
   * @param buf The buffer containing the OpenALPRRequest in binary wire format
   * @return A new OpenALPRRequest
   */


  RecognizeRequest.FromBuffer = function (buf) {
    return utils_1.fromBuffer(this, buf);
  };
  /** Default property values */


  RecognizeRequest.DEFAULTS = {
    recognizeVehicle: 0,
    country: '',
    state: '',
    returnImage: 0,
    topn: 0,
    prewarp: '',
    secretKey: ''
  };
  return RecognizeRequest;
}(mixins_1.Bufferable(openalpr_pb_1.openalpr.cloud.RecognizeRequest));

exports.RecognizeRequest = RecognizeRequest;
mixins_1.applyMixins(RecognizeRequest, mixins_1.Objectable, mixins_1.IsBufferable);
exports.default = RecognizeRequest;