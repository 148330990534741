/**
 * @file A component used for displaying errors as a small bar-like popup
 * @author Harris Lummis
 */
import { Button, Grid, IconButton, Paper } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

/** Props used to create an error bar */
type ErrorBarBaseProps = {
  /**
   * Specific content of bar. If children is set, it will override the standard
   * message display
   */
  children?: any;
  /** The error that triggers display */
  err?: Error;
  /** Handle a click on the retry button */
  onRetry?: (...args: any[]) => any;
  /** Handle a click on the close button */
  onClose?: (...args: any[]) => any;
} & Partial<DefaultProps>;

/** Type of default properties for error bar */
type DefaultProps = Readonly<typeof defaultProps>;

/** Error bar component props */
export type ErrorBarProps = ErrorBarBaseProps &
  WithStyles<'main' | 'icon' | 'iconButton' | 'grid' | 'retryButton'>;

/** Default properties for error bar */
export const defaultProps = {
  /** Message to display for the error. */
  message: 'Oops! Something went wrong.',
};

/** Applies styling to ErrorBar component */
const decorate = withStyles((theme: Theme) => ({
  grid: {
    position: 'absolute' as 'absolute',
    marginLeft: -theme.spacing(3),
    marginRight: 'auto',
    whiteSpace: 'normal' as 'normal',
    overflow: 'auto',
    wordWrap: 'normal' as 'normal',
    zIndex: 999,
  },
  main: {
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: red[50],
    borderColor: red[300],
    borderRadius: 20,
    borderStyle: 'solid',
    borderWidth: 2,
    color: red[300],
    display: 'flex',
    fontWeight: 'bold' as 'bold',
    justifyContent: 'space-between',
    padding: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  icon: {
    color: red[300],
  },
  iconButton: {
    height: '80%',
    width: 'auto' as 'auto',
  },
  retryButton: {
    marginRight: 10,
    borderRadius: 10,
    fontWeight: 'bold' as 'bold',
  },
}));

/** Error Bar Component */
export const ErrorBar = decorate(class extends React.Component<
  ErrorBarProps & DefaultProps
> {
  /** Class-wide default properties */
  public static defaultProps = defaultProps;
  /** Construct a new ErrorBar */
  constructor(props: ErrorBarProps & DefaultProps) {
    super(props);
  }
  /** Handle a press on the close button by setting active state to false */
  public handleClose = () => {
    this.setState({ active: false });
  };
  /** Render standard message content */
  public renderMessageContent() {
    const { classes, message, onRetry, onClose } = this.props;

    return (
      <React.Fragment>
        <div id="errorBarMessage">{message}</div>
        <div id="errorBarButtons">
          {onRetry && (
            <Button
              color="inherit"
              onClick={onRetry}
              className={classes.retryButton}
              id="errorBarRetryButton"
            >
              Retry
            </Button>
          )}
          <IconButton
            className={classes.iconButton}
            onClick={onClose || this.handleClose}
            id="errorBarCloseButton"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        </div>
      </React.Fragment>
    );
  }
  /** Render the component */
  public render() {
    const { err, children, classes } = this.props;

    return (
      <Grid container={true} justify="center" className={classes.grid}>
        {err && (
          <Grid item={true} xs={11} sm={10} md={8} lg={6} xl={5}>
            <Paper className={classes.main}>
              {children ? children : this.renderMessageContent()}
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  }
} as React.ComponentClass<ErrorBarProps>);

export default ErrorBar;
