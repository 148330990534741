/**
 * @file A HOC for displaying components only upon successful authorization
 * @author Harris Lummis
 */
import React from 'react';
import useAuthorizer, { Authorizer } from '@atlas/hooks/useAuthorizer';
import PageLoader from '../PageLoader';
import Typography from '@material-ui/core/Typography';

/**
 * Create an HOC that accepts a component and only displays
 * it if the provided authorizer returns truthfully. Otherwise
 * display a loading page (while loading) and a Simple lack of
 * authorization otherwise.
 * @param authorizer The authorizer function
 */
export function withAuthorization(authorizer: Authorizer) {
  return <P extends object>(Component: React.ComponentType<P>) => (
    props: P,
  ) => {
    const { isAuthorized, isAuthorizing, err } = useAuthorizer(authorizer);

    if (err) {
      throw err;
    }
    if (isAuthorizing) {
      return (
        <div data-testid="loader">
          <PageLoader data-testid="loader" />
        </div>
      );
    }
    if (isAuthorized) {
      return <Component {...props} />;
    }
    return (
      <Typography data-testid="unauthorized" variant="h5">
        You are not authorized to view this page.
      </Typography>
    );
  };
}

export default withAuthorization;
