import chunk from 'lodash/chunk';
import includes from 'lodash/includes';
import map from 'lodash/map';
import omit from 'lodash/omit';
import retry from 'retry';
import stampit from 'stampit';

class AbortError extends Error {
  public originalError: Error;
  constructor(message: string | Error) {
    super();

    if (message instanceof Error) {
      this.originalError = message;
      ({ message } = message);
    } else {
      this.originalError = new Error(message);
      this.originalError.stack = this.stack;
    }

    this.name = 'AbortError';
    this.message = message;
  }
}

/**
 * Redefine pRetry function here to compile to older ecmascript specs, improving
 * browswer compatibility
 */
function pRetry<T>(
  input: (attemptCount: number) => Promise<T> | T,
  options?: any,
): Promise<T> {
  return new Promise((resolve, reject) => {
    options = {
      onFailedAttempt: () => {
        return;
      },
      retries: 10,
      ...options,
    };

    const operation = retry.operation(options);

    operation.attempt(attemptNumber => {
      const attemptsLeft = options.retries - attemptNumber;

      return Promise.resolve(attemptNumber)
        .then(input)
        .then(resolve, error => {
          if (error instanceof AbortError) {
            operation.stop();
            reject(error.originalError);
          } else if (error instanceof TypeError) {
            operation.stop();
            reject(error);
          } else if (operation.retry(error)) {
            error.attemptNumber = attemptNumber;
            error.attemptsLeft = attemptsLeft;
            options.onFailedAttempt(error);
          } else {
            reject(operation.mainError());
          }
        });
    });
  });
}

/**
 * Parameters used to instantiate a RetryAPIInstance
 * @see RetryAPIInstance
 */
interface RetryParams {
  /** The api object used for calling APIs. Defaults to the amplify API */
  api: any;
  /** Max number of retries before failure. Defaults to 10 */
  retries?: number;
  /**
   * The exponential growth factor of retry timeouts. Defaults to 1.47394.
   * See class documentation for reasoning.
   */
  factor?: number;
  /** The minimum timeout for first retry in ms. Defaults to 500. */
  minTimeout?: number;
  /** The maximum timeout for last retry in ms. Defaults to 30000. */
  maxTimeout?: number;
  /**
   * Whether or not to randomly multiply timeouts by factor between 1 to 2.
   * Defaults to false.
   */
  randomize?: boolean;
  /**
   * The HTTP error codes to retry on. Defaults to [500, 502].
   */
  retryCodes?: number[];
}

/** An instance of the RetryAPI function */
export interface RetryAPIInstance {
  /** The api object used for calling APIs. Defaults to the amplify API */
  api: any;
  /** Max number of retries before failure. Defaults to 10 */
  retries: number;
  /**
   * The exponential growth factor of retry timeouts. Defaults to 1.47394.
   * See class documentation for reasoning.
   */
  factor: number;
  /** The minimum timeout for first retry in ms. Defaults to 500. */
  minTimeout: number;
  /** The maximum timeout for last retry in ms. Defaults to 30000. */
  maxTimeout: number;
  /**
   * Whether or not to randomly multiply timeouts by factor between 1 to 2.
   * Defaults to false.
   */
  randomize: boolean;
  /**
   * The HTTP error codes to retry on. Defaults to [500, 502].
   */
  retryCodes: number[];
  /**
   * Make a GET request
   * @param apiName The name of the API in the Amplify config
   * @param path The path to hit in the request
   * @param options Options to include in the request
   * @returns A promise that resolves to the API response
   */
  get: (apiName: string, path: string, options: any) => Promise<any>;
  /**
   * Make a PUT request
   * @param apiName The name of the API in the Amplify config
   * @param path The path to hit in the request
   * @param options Options to include in the request
   * @returns A promise that resolves to the API response
   */
  put: (apiName: string, path: string, options: any) => Promise<any>;
  /**
   * Make a POST request
   * @param apiName The name of the API in the Amplify config
   * @param path The path to hit in the request
   * @param options Options to include in the request
   * @returns A promise that resolves to the API response
   */
  post: (apiName: string, path: string, options: any) => Promise<any>;
  /**
   * Make a DELETE request
   * @param apiName The name of the API in the Amplify config
   * @param path The path to hit in the request
   * @param options Options to include in the request
   * @returns A promise that resolves to the API response
   */
  delete: (apiName: string, path: string, options: any) => Promise<any>;
}

// Protect some data for the retryAPI
const makeCall = Symbol('makeCall');

/** A factory function that creates a RetryAPIInstance */
export interface RetryAPIFactory extends stampit.Stamp {
  (params: RetryParams, ...encloseArgs: any[]): RetryAPIInstance;
}

/**
 * A wrapper class for the amplify API lib that allows for request retries.
 * The default paramaters are tuned specifically to make the max retry time
 * 30 seconds.
 * @see https://www.npmjs.com/package/retry
 */
export const RetryAPI: RetryAPIFactory = stampit()
  .props({
    factor: 1.47394,
    retries: 10,
    minTimeout: 500,
    maxTimeout: 3000,
    randomize: false,
    retryCodes: [500, 502],
  })
  .init(function(this: RetryAPIInstance, params: RetryParams) {
    // Override defaults
    Object.assign(this, params);
    this.api = params.api;
  })
  .methods({
    [makeCall](
      this: RetryAPIInstance,
      apiName: string,
      method: string,
      path: string,
      options: any,
    ) {
      return pRetry(
        () => {
          options = options || {};
          return this.api[method](apiName, path, { ...options, response: true })
            .then((response: any) => response)
            .catch((err: any) => {
              /** Only retry if error is in retry codes */
              if (
                err.response &&
                includes(this.retryCodes, err.response.status)
              ) {
                throw err;
              }
              /** Abort otherwise */
              throw new AbortError(err);
            });
        },
        {
          factor: this.factor,
          retries: this.retries,
          minTimeout: this.minTimeout,
          maxTimeout: this.maxTimeout,
          randomize: this.randomize,
        },
      );
    },
    get(apiName: string, path: string, options: any) {
      return this[makeCall](apiName, 'get', path, options);
    },
    post(apiName: string, path: string, options: any) {
      return this[makeCall](apiName, 'post', path, options);
    },
    put(apiName: string, path: string, options: any) {
      return this[makeCall](apiName, 'put', path, options);
    },
    delete(apiName: string, path: string, options: any) {
      return this[makeCall](apiName, 'delete', path, options);
    },
  });

/**
 * Chunk an array of objects and take the sum/average of the desired properties
 * in each chunk
 * @param arr The array of data to run aggregations on
 * @param chunkSize The size of the chunks to aggregate on
 * @param propsToAverage The names of properties of which to take the average
 * @param propsToSum The names of properties of which to take the sum. If not
 * set, then no sums will be taken
 * @returns An array of length chunkSize containing sums and averages on desired
 * properties
 */
export function aggregateChunks(
  arr: any[],
  chunkSize: number,
  propsToAverage: string[],
  propsToSum?: string[],
) {
  const chunks = chunk(arr, chunkSize);
  return map(chunks, curChunk => {
    // Get the names of all properties to aggregate
    const allPropsToAggregate = propsToAverage.concat(propsToSum || []);
    // Copy the first object from the current chunk, sans props that will
    // be aggregated. This will be used to store the results of the aggregation
    // for the current chunk
    const unaveraged = omit(curChunk[0], allPropsToAggregate);
    const accumulator: { [p: string]: number } = {};
    // Calculate sums for all props that should be aggregated. Averages will be
    // taken upon successful completion of summation
    const sums = curChunk.reduce((acc, cur) => {
      allPropsToAggregate.forEach(prop => {
        if (!acc[prop]) {
          acc[prop] = cur[prop];
        } else {
          acc[prop] += cur[prop];
        }
      });
      return acc;
    }, accumulator);

    // Average the totals that should be averaged
    propsToAverage.forEach(prop => {
      sums[prop] /= curChunk.length;
    });

    return { ...unaveraged, ...sums };
  });
}
