/**
 * @file Component that displays when an infraction has already been verified
 * @author Harris Lummis
 */
import React from 'react';
import {
  createStyles,
  WithStyles,
  Card,
  Typography,
  withStyles,
} from '@material-ui/core';

/** Styles applied to the component */
const styles = () =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
  });

/** Props passed to create an AlreadyVerifiedView */
export interface AlreadyVerifiedViewProps extends WithStyles<typeof styles> {}

/** A view for display when an infraction is already verified */
export function AlreadyVerifiedView({ classes }: AlreadyVerifiedViewProps) {
  return (
    <Card className={classes.root}>
      <Typography variant="h5">
        Citation has already been checked. Move along.
      </Typography>
    </Card>
  );
}

export default withStyles(styles)(AlreadyVerifiedView);
