/**
 * @file Controller for calling infractions endpoints
 * @author Harris Lummis
 */
import { HasAPI, HasAPIInstance } from '../stamps/aws';
import { RetryAPIInstance } from '../libs/utils';
import { Infraction, GetInfractionOutput } from '../typings/responses';
import stampit from 'stampit';
import { DynamoDB } from 'aws-sdk';
import { Auth } from 'aws-amplify';
import config from '../config';
import {
  VerifyInfractionNoExportParams,
  VerifyInfractionParams,
} from './typings/infractions';

/** Parameters used for listing infractions */
export interface ListInfractionsParams {
  /** Whether to allow officer to verify (deprecated) */
  allowVerification?: 'yes' | 'no' | null;
  /** The last evaluated key from a previous dynamodb query */
  lastEvaluatedKey?: any;
  /** The sort order in which to display results */
  sort?:
    | 'end-time_asc'
    | 'end-time_desc'
    | 'infraction-id_asc'
    | 'infraction-id_desc'
    | 'start-time_asc'
    | 'start-time_desc';
  /** Filter results by verified value */
  verified?: boolean;
  /** Filter results by violation type */
  violationType?: 'MAX_TIME' | 'RED_ZONE';
}

/** Output from the list infractions method */
export interface ListInfractionsOutput {
  /** Infractions returned by query */
  infractions: Infraction[];
  /** Last key evaluated by dynamodb (used for pagination) */
  lastEvaluatedKey?: any;
  /** The number of queried items */
  count: number;
}

/**
 * Parameters used for making calls to /infractions/:infractionId/decline
 * endpoint
 */
export interface DeclineInfractionParams {
  /** UUID of the infraction to decline */
  infractionId: string;
  /** Country of origin of the plate (deprecated) */
  country?: string | null;
  /** S3 Key of plate image file */
  imgKeyPlate?: string;
  /** Plate text */
  plate?: string;
  /** Abbreviated state code of plate */
  state?: string;
  /** Reason for rejecting the infraction (stringified json) */
  rejectionReason: string;
}

/** A controller used for calling infractions endpoints */
export interface InfractionsControllerInstance extends HasAPIInstance {
  /** Indicates whether or not the controller has been initialized */
  isInitialized: boolean;
  /** The document client used by the instance */
  db: DynamoDB.DocumentClient;
  /**
   * Initialize the controller by authorizing DynamoDB.
   * @returns A promise that resolves upon initialization
   */
  init(): PromiseLike<any>;
  /**
   * Decline a single infraction in dynamodb
   * @param declineParams The parameters used to decline the infraction
   * @returns A promise that resolves to a parsed response from the called API
   */
  decline(declineParams: DeclineInfractionParams): PromiseLike<any>;
  /**
   * List infractions from dynamodb
   * @param searchParams The parameters used to make the dynamodb query
   * @returns A promise that resolves to the resultant response from the called
   * API
   */
  list(searchParams: ListInfractionsParams): PromiseLike<ListInfractionsOutput>;
  /**
   * Verify a single infraction in dynamodb
   * @param verifyParams The parameters used to verify the infraction
   * @returns A promise that resolves to a parsed response from the called endpoint
   */
  verify(verifyParams: VerifyInfractionParams): PromiseLike<any>;
  /**
   * Verify a single infraction and notify enforcers (don't export to UMS)
   * @param params The parameters used to verify the infraction
   * @returns A promise that resolves to a parsed response form the called endpoint
   */
  verifyNoExport(params: VerifyInfractionNoExportParams): Promise<any>;
  /**
   * Get a single infraction from dynamodb
   * @param infractionId The id of the infraction to retrieve
   * @returns A promise that resolves to an infraction or null
   */
  getOne(infractionId: string): PromiseLike<Infraction | null>;
}

/** Parameters used to create a new InfractionsController */
export interface InfractionsControllerParams {
  /** The name of the API to call */
  apiName: string;
  /** The API caller instance */
  API: RetryAPIInstance;
}

/** A factory function used to create new InfractionsControllers */
export interface InfractionsControllerFactory extends stampit.Stamp {
  /**
   * Create a new InfractionsController
   * @param params Parameters used to initialize the controller
   * @param encloseArgs Additional args passed to enclose functions (if any
   * exist
   * @returns A new InfractionsController instance
   */
  (
    params: InfractionsControllerParams,
    ...encloseArgs: any[]
  ): InfractionsControllerInstance;
}

const InfractionsController = HasAPI.methods({
  init(this: InfractionsControllerInstance) {
    return Auth.currentCredentials().then(credentials => {
      this.db = new DynamoDB.DocumentClient({
        region: config.dynamodb.infractions.region,
        credentials: Auth.essentialCredentials(credentials),
      });
      this.isInitialized = true;
    });
  },
  list(
    this: InfractionsControllerInstance,
    searchParams: ListInfractionsParams,
  ) {
    if (!this.isInitialized) {
      return new Error('cannot list from an uninitialized controller');
    }
    const {
      // only show infractions for which verification is allowed by default
      allowVerification = null,
      lastEvaluatedKey,
      // default to sorting by end/issue time in descending order
      sort = 'end-time_desc',
      // default to only showing unverified citations
      verified = false,
      violationType,
    } = searchParams;

    const limit = config.dynamodb.infractions.getLimit;

    // This should always work due to parameter validation
    const [sortBy, sortOrder] = sort.split('_');

    // By default, show infractions regardless of allow_verification
    // field value
    let filterExpression = '#verif = :verifVal';
    const expressionAttributeNames = {
      '#cli': 'client_id',
      '#verif': 'verified',
    };
    const expressionAttributeValues = {
      ':cli': config.clientId,
      ':verifVal': verified,
    };

    if (allowVerification) {
      filterExpression += ' and #allow = :allowVal';
      expressionAttributeNames['#allow'] = 'allow_verification';
      expressionAttributeValues[':allowVal'] = allowVerification;
    }
    // Setup query params
    const queryParams: DynamoDB.DocumentClient.QueryInput = {
      TableName: config.dynamodb.infractions.tableName,
      KeyConditionExpression: '#cli = :cli',
      Limit: limit,
      ExclusiveStartKey: lastEvaluatedKey,
      FilterExpression: filterExpression,
      ExpressionAttributeNames: expressionAttributeNames,
      ExpressionAttributeValues: expressionAttributeValues,
      ScanIndexForward: sortOrder === 'asc',
    };

    // Determine index by checking sort parameter. Sorting by infraction_id
    // will use the default index
    switch (sortBy) {
      case 'end-time':
        queryParams.IndexName = 'client_id-end_time-index';
        break;
      case 'start-time':
        queryParams.IndexName = 'client_id-start_time-index';
        break;
      default:
        break;
    }

    // Add violation type to filter expression if necessary
    if (violationType) {
      queryParams.FilterExpression += ' and #vType = :vTypeVal';
      queryParams.ExpressionAttributeNames!['#vType'] = 'violation_type';
      queryParams.ExpressionAttributeValues![':vTypeVal'] = violationType;
    }

    // Run the query
    return new Promise<DynamoDB.DocumentClient.QueryOutput>(
      (resolve, reject) => {
        // TODO: retry operation
        this.db.query(queryParams, (err, data) => {
          if (err) {
            return reject(err);
          }
          resolve(data);
        });
      },
    )
      .then(ddbRes => {
        return {
          infractions: ddbRes.Items,
          count: ddbRes.Count,
          lastEvaluatedKey: ddbRes.LastEvaluatedKey,
        };
      })
      .catch(err => {
        // TODO: better error handling
        alert(err);
        return {
          infractions: [],
          count: 0,
        };
      });
  },
  decline(
    this: InfractionsControllerInstance,
    declineParams: DeclineInfractionParams,
  ) {
    const { infractionId } = declineParams;
    return this.request({
      method: 'post',
      path: `/infractions/${infractionId}/decline`,
      body: {
        country: declineParams.country || null,
        img_key_plate: declineParams.imgKeyPlate,
        plate: declineParams.plate,
        state: declineParams.state,
        rejection_reason: declineParams.rejectionReason,
      },
      headers: { 'content-type': 'application/json' },
    });
  },
  getOne(this: InfractionsControllerInstance, infractionId: string) {
    return this.request({
      method: 'get',
      path: `/infractions/${infractionId}`,
    }).then((res: GetInfractionOutput) => {
      const { data } = res;
      const { item } = data;
      return item ? item : null;
    });
  },
  verify(this: InfractionsControllerInstance, params: VerifyInfractionParams) {
    const { infractionId, vehicle, infraction, imgKeyPlate } = params;
    return this.request({
      method: 'post',
      path: `/infractions/${infractionId}/verify`,
      body: {
        vehicle,
        infraction,
        img_key_plate: imgKeyPlate,
      },
      headers: { 'content-type': 'application/json' },
    });
  },
  verifyNoExport(
    this: InfractionsControllerInstance,
    params: VerifyInfractionNoExportParams,
  ) {
    const { infractionId, vehicle, infraction, imgKeyPlate } = params;
    return this.request({
      method: 'post',
      path: `/infractions/${infractionId}/verify-no-export`,
      body: {
        vehicle,
        infraction,
        img_key_plate: imgKeyPlate,
      },
      headers: { 'content-type': 'application/json' },
    });
  },
}) as InfractionsControllerFactory;

export default InfractionsController;
