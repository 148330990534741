/**
 * @file RejectButton component, a button that functions as a toggle switch.
 * @author Harris Lummis
 * @author Lucille Njoo
 * @author Bridget O'Connor
 */
import React, { FunctionComponent } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, StyleRules } from '@material-ui/styles';
import red from '@material-ui/core/colors/red';

export type RejectButtonClassKey = 'button' | 'disabled';

export const rejectButtonStyles: StyleRules<
  RejectButtonProps,
  RejectButtonClassKey
> = {
  button: {
    border: '2px solid',
    borderColor: ({ disabled }) => (disabled ? red[200] : red[700]),
    color: ({ pressed, disabled }) =>
      disabled ? red[200] : pressed ? 'white' : red[700],
    backgroundColor: ({ pressed }) => (pressed ? red[700] : undefined),
    '&:hover': {
      color: ({ pressed }) => (pressed ? red[700] : 'white'),
      backgroundColor: ({ pressed }) => (pressed ? 'white' : red[700]),
    },
  },
  disabled: {
    color: red[200],
    borderColor: red[200],
  },
};

const useStyles = makeStyles<any, RejectButtonProps, RejectButtonClassKey>(
  rejectButtonStyles,
);

/** Properties used to create a reject button */
export interface RejectButtonProps {
  /** Optional override classes */
  classes?: Partial<Record<RejectButtonClassKey, string>>;
  /**
   * Handler a button click event
   * @param event The click event
   */
  onClick: (event: any) => any;
  /** Indicates the toggle status of the button */
  pressed: boolean;
  /** Indicates whether button is enabled */
  disabled?: boolean;
}

/**
 * The Reject Button component. Creates a toggle switch. Does not include styles.
 */
export const RejectButton: FunctionComponent<RejectButtonProps> = props => {
  const classes = useStyles(props);
  const { children, disabled, onClick } = props;

  return (
    <Button
      classes={{
        label: disabled ? classes.disabled : undefined,
        disabled: classes.disabled,
      }}
      className={classes.button}
      onClick={onClick}
      disabled={!!disabled}
    >
      {children}
    </Button>
  );
};

export default RejectButton;
