"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/** An enum indicating yes or no */

exports.YesNo = {
  YES: 1,
  NO: 0
}; // Ensure that properties cannot be rewritten

Object.defineProperties(exports.YesNo, {
  YES: {
    value: 1,
    writable: false
  },
  NO: {
    value: 0,
    writable: false
  }
});
exports.default = exports.YesNo;