/**
 * @file A component for indicating that a page is loading
 * @author Harris Lummis
 */
import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';

/** Styles applied to the component */
const styles = () =>
  createStyles({
    root: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

/** Props passed to create a PageLoader */
export interface PageLoaderProps extends WithStyles<typeof styles> {
  /** Properties passed directly to the progress indicator */
  progressProps?: CircularProgressProps;
}

/**
 * PageLoader is used to display a large loading indicator inside of a container that assumes
 * the full width and height of its parent. The loading indicator will display in the center of
 * its container, unless specified otherwise through style overrides
 */
export const PageLoader = ({ classes, progressProps }: PageLoaderProps) => (
  <div data-testid="page-loader" className={classes.root}>
    <CircularProgress size="15%" {...progressProps} />
  </div>
);

export default withStyles(styles)(PageLoader);
