"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @file Definition of the ProcessingTimes model
 * @author Harris Lummis
 */

var openalpr_pb_1 = require("@automotus/openalpr-pb");

var mixins_1 = require("@atlas/models/mixins");

var utils_1 = require("@atlas/models/utils");
/** Represents processing time of an OpenALPR Cloud API request */


var ProcessingTimes =
/** @class */
function (_super) {
  __extends(ProcessingTimes, _super);

  function ProcessingTimes() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * Parses an object and creates a new instance from it
   * @param obj The object to parse
   * @return A new ProcessingTimes instance
   */


  ProcessingTimes.FromObject = function (obj) {
    return utils_1.parseObject(this, obj);
  };
  /**
   * Creates a new ProcessingTimes instance from a wire-format encoded message
   * @param buf The buffer containing wire-format encoded ProcessingTimes
   * @return A new ProcessingTimes instance
   */


  ProcessingTimes.FromBuffer = function (buf) {
    return new this(this.decode(buf));
  };
  /** Default values of enumerable properties */


  ProcessingTimes.DEFAULTS = {
    total: 0,
    plates: 0,
    vehicles: 0
  };
  return ProcessingTimes;
}(mixins_1.Bufferable(openalpr_pb_1.openalpr.cloud.ProcessingTimes));

exports.ProcessingTimes = ProcessingTimes;
mixins_1.applyMixins(ProcessingTimes, mixins_1.Objectable);
exports.default = ProcessingTimes;