/**
 * @file Plate Rejection Form Group component. Renders form groups for plate
 * rejection reasons.
 * @author Harris Lummis
 * @author Lucille Njoo
 * @author Bridget O'Connor
 */
import * as React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import { PlateRejectionReasons } from './typings';
import FormControl from '@material-ui/core/FormControl';

const styles = () =>
  createStyles({
    rowDiv: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    },
  });

/** Props for PlateRejectionFormGroup component */
export interface PlateRejectionFormProps
  extends WithStyles<typeof styles>,
    Partial<PlateRejectionReasons> {
  /** Function that handles a change of a form checkbox */
  onChange: (name: keyof PlateRejectionReasons, value: any) => void;
}

/**
 * Plate Rejection Form Group component that renders form groups for plate
 * rejection reasons.
 */
export function PlateRejectionForm(props: PlateRejectionFormProps) {
  const { classes, onChange, ...rejectedReasons } = props;

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.name as keyof PlateRejectionReasons, e.target.checked);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.name as keyof PlateRejectionReasons, e.target.value);
  };

  return (
    <div className={classes.rowDiv}>
      <FormControl id="form-control--plate-readability">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!rejectedReasons.plateUnreadable}
                onChange={handleCheckboxChange}
                name="plateUnreadable"
              />
            }
            label="Plate Unreadable"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!rejectedReasons.stateUnreadable}
                onChange={handleCheckboxChange}
                name="stateUnreadable"
              />
            }
            label="State Unreadable"
          />
        </FormGroup>
      </FormControl>
      <FormControl id="form-control--plate-brightness">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!rejectedReasons.plateImagesTooDark}
                onChange={handleCheckboxChange}
                name="plateImagesTooDark"
              />
            }
            label="Too Dark"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!rejectedReasons.plateImagesTooBright}
                onChange={handleCheckboxChange}
                name="plateImagesTooBright"
              />
            }
            label="Too Bright"
          />
        </FormGroup>
      </FormControl>
      <FormControl id="form-control--timing">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!rejectedReasons.plateImagesTooEarly}
                onChange={handleCheckboxChange}
                name="plateImagesTooEarly"
              />
            }
            label="Too Early"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!rejectedReasons.plateImagesTooLate}
                onChange={handleCheckboxChange}
                name="plateImagesTooLate"
              />
            }
            label="Too Late"
          />
        </FormGroup>
      </FormControl>
      <FormControl id="form-control--other">
        <FormGroup>
          <TextField
            id="rejectOther"
            label="Other"
            name="other"
            value={rejectedReasons.other || ''}
            onChange={handleTextChange}
            margin="normal"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default withStyles(styles)(PlateRejectionForm);
