/**
 * @file The main container for ManualVerification
 * @author Harris Lummis
 */
import React from 'react';
import PageLoader from '../../components/PageLoader';
import AlreadyVerifiedView from './AlreadyVerifiedView';
import ManualVerificationView from './ManualVerificationView';
import {
  GeneralRejectionReasons,
  PlateRejectionReasons,
  Infraction,
  DeclineInfractionArgs,
  RejectionInfo,
} from './typings';
import isEqual from 'lodash/isEqual';
import { formatTimeToMatchImageTimestamp } from '../../libs/formatLib';

/** Props used to create a ManualVerification container */
export interface ManualVerificationProps {
  // The id of the infraction to verify
  infractionId: string;
  // The id of the client that owns the infraction
  clientId: string;
  // The timezone in which the client is located
  clientTimezone: string;
  // The number of plate images to select from
  numPlateImgs: number;
  /** The number of seconds until a created image link should expire */
  imageTTL: number;
  // TODO: define this type more fully
  /** A function that verifies an infraction */
  verifyInfraction: (infraction: any) => PromiseLike<any>;
  /** A function that declines an infraction */
  declineInfraction: (args: DeclineInfractionArgs) => PromiseLike<any>;
  /**
   * Get an infraction
   * @param infractionId The id of the infraction to retrieve
   * @return A promise that resolves to the retrieved infraction
   */
  getInfraction: (infractionId: string) => Promise<Infraction>;
  /**
   * Generate an image src url from s3 key information
   * @param prefix The prefix of the ke
   * @param key The key
   * @param ttl The time (in seconds) until the generated link expires
   * @return A promise that resolves to a src url
   */
  getImgSrc: (prefix: string, key: string, ttl: number) => PromiseLike<string>;
  /**
   * Get S3 keys for plate images
   * @param objectId The UUID of the object in the infraction
   * @param numImgs The number of desired images
   * @return An array of S3 keys
   */
  getPlateImgKeys: (objectId: string, numImgs: number) => string[];
  // TODO: this prop may be wrong
  onFinishSubmit: () => void;
  /** The initial state to use (if specified) */
  initialState?: ManualVerificationState;
}

/** State maintained by the ManualVerification container */
export interface ManualVerificationState {
  /** Indicates whether or not infraction already handled */
  infractionHandled: boolean;
  /** Indicates whether input has been verified */
  verified: boolean;
  /** The 0-based index of the selected plate image. Will be undefined if no plate is selected */
  selectedPlateIndex?: number;
  /** The infraction to verify */
  infraction?: Infraction;
  /** Plate text */
  plate?: string;
  /** License plate state */
  state?: string;
  /** License plate country */
  country?: string;
  /** Park image source url */
  parkImgSrc?: string;
  /** Infraction image source url */
  infractionImgSrc?: string;
  /** Plate image keys */
  plateImgKeys: string[];
  /** Plate image candidates */
  plateImgSrcs: string[];
  /** Indicates whether page is loading */
  loading: boolean;
  /** Indicates whether submission is in progress */
  submitting: boolean;
  /** Indicates whether plate rejection enabled */
  plateRejectionEnabled: boolean;
  /** Indicates whether general rejection enabled */
  generalRejectionEnabled: boolean;
  /** Reasons for general rejection */
  generalRejectionInfo: GeneralRejectionReasons;
  /** Reasons for plate rejection */
  plateRejectionInfo: PlateRejectionReasons;
}

/** Default initial state of the container */
export const initialState: ManualVerificationState = {
  infractionHandled: false,
  plateImgKeys: [],
  plateImgSrcs: [],
  loading: true,
  submitting: false,
  verified: false,
  generalRejectionEnabled: false,
  generalRejectionInfo: {
    trackerSwappedIncoming: false,
    trackerSwappedOutgoing: false,
    wrongStartTime: false,
    wrongEndTime: false,
    taggedInParkRegion: false,
    other: '',
  },
  plateRejectionEnabled: false,
  plateRejectionInfo: {
    plateUnreadable: false,
    stateUnreadable: false,
    plateImagesTooBright: false,
    plateImagesTooDark: false,
    plateImagesTooEarly: false,
    plateImagesTooLate: false,
    other: '',
  },
};

export class ManualVerification extends React.Component<
  ManualVerificationProps,
  ManualVerificationState
> {
  /**
   * Create a new ManualVerification container. Sets initial state, instance variables,
   * and binds requisite handlers.
   * @param props Properties used to create the container
   */
  constructor(props: ManualVerificationProps) {
    super(props);
    this.state = props.initialState
      ? { ...props.initialState }
      : { ...initialState };

    // Bind handlers
    this.handleSelectPlateImg = this.handleSelectPlateImg.bind(this);
    this.handleClickGeneralReject = this.handleClickGeneralReject.bind(this);
    this.handleChangeGeneralReject = this.handleChangeGeneralReject.bind(this);
    this.handleClickPlateReject = this.handleClickPlateReject.bind(this);
    this.handleChangePlateReject = this.handleChangePlateReject.bind(this);
    this.handleChangePlateInfo = this.handleChangePlateInfo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  /**
   * Upon mount, load all data (infraction and image source urls)
   */
  public async componentDidMount() {
    const {
      getInfraction,
      getPlateImgKeys,
      getImgSrc,
      infractionId,
      numPlateImgs,
      imageTTL,
      clientId,
    } = this.props;

    // Skip getting infraction if one has already been provided
    const infraction = await (() =>
      this.state.infraction
        ? Promise.resolve(this.state.infraction)
        : getInfraction(infractionId))();

    const { objectId } = infraction;
    const plate = infraction.plate || '';
    const state = infraction.state || '';

    const plateImgKeys = getPlateImgKeys(objectId, numPlateImgs);
    const keyPrefix = `prod/${clientId}/`;

    // Skip getting imgSrcs if they have already been provided
    const { parkImgSrc, infractionImgSrc, plateImgSrcs } = await Promise.all([
      this.state.parkImgSrc
        ? Promise.resolve(this.state.parkImgSrc)
        : getImgSrc(keyPrefix, `${objectId}/park.jpg`, imageTTL),
      this.state.infractionImgSrc
        ? Promise.resolve(this.state.infractionImgSrc)
        : getImgSrc(keyPrefix, `${objectId}/infraction.jpg`, imageTTL),
      this.state.plateImgSrcs.length > 0
        ? Promise.resolve(this.state.plateImgSrcs)
        : Promise.all(
            plateImgKeys.map(key => {
              const keyWithoutPrefix = key.replace(keyPrefix, '');
              return getImgSrc(keyPrefix, keyWithoutPrefix, imageTTL);
            }),
          ),
    ]).then(([parkSrc, infractionSrc, plateSrcs]) => {
      return {
        parkImgSrc: parkSrc,
        infractionImgSrc: infractionSrc,
        plateImgSrcs: plateSrcs,
      };
    });

    this.setState({
      loading: false,
      infractionHandled: !!infraction.allowVerification,
      parkImgSrc,
      infractionImgSrc,
      plateImgSrcs,
      plateImgKeys,
      infraction,
      plate,
      state,
    });
  }
  /**
   * Handle the selection of a new plate image
   */
  public handleSelectPlateImg(event: React.MouseEvent<HTMLButtonElement>) {
    const { selectedPlateIndex } = this.state;
    const newSelectedIndex: number = +event.currentTarget.value;

    if (newSelectedIndex === selectedPlateIndex) {
      // Deselect if same image is clicked again
      this.setState({ selectedPlateIndex: undefined });
      return;
    }
    this.setState({ selectedPlateIndex: newSelectedIndex });
  }
  /** Handle a click on the general reject button */
  public handleClickGeneralReject() {
    const { generalRejectionEnabled } = this.state;
    if (generalRejectionEnabled) {
      this.setState({
        generalRejectionEnabled: false,
        generalRejectionInfo: { ...initialState.generalRejectionInfo },
      });
    } else {
      this.setState({
        generalRejectionEnabled: true,
      });
    }
    this.verifyForm();
  }
  /** Handle a change of the general rejection form */
  public handleChangeGeneralReject(
    name: keyof GeneralRejectionReasons,
    value: any,
  ) {
    const { generalRejectionInfo } = this.state;
    if (name === 'other') {
      this.setState({
        generalRejectionInfo: {
          ...generalRejectionInfo,
          other: value as string,
        },
      });
    } else {
      this.setState({
        generalRejectionInfo: {
          ...generalRejectionInfo,
          [name]: value as boolean,
        },
      });
    }
    this.verifyForm();
  }
  /** Verify the general rejection form */
  private verifyGeneralRejection() {
    const { generalRejectionInfo } = this.state;
    return !isEqual(generalRejectionInfo, initialState.generalRejectionInfo);
  }
  /** Handle a click on the plate reject button */
  public handleClickPlateReject() {
    const { plateRejectionEnabled } = this.state;
    if (plateRejectionEnabled) {
      this.setState({
        plateRejectionEnabled: false,
        plateRejectionInfo: { ...initialState.plateRejectionInfo },
      });
    } else {
      this.setState({
        plateRejectionEnabled: true,
      });
    }
    this.verifyForm();
  }
  /** Handle a change of the plate rejection form */
  public handleChangePlateReject(
    name: keyof PlateRejectionReasons,
    value: any,
  ) {
    const { plateRejectionInfo } = this.state;
    if (name === 'other') {
      this.setState({
        plateRejectionInfo: { ...plateRejectionInfo, other: value as string },
      });
    } else {
      this.setState({
        plateRejectionInfo: { ...plateRejectionInfo, [name]: value as boolean },
      });
    }
    this.verifyForm();
  }
  /** Verify the plate rejection form */
  private verifyPlateRejection() {
    const { plateRejectionInfo } = this.state;
    return !isEqual(plateRejectionInfo, initialState.plateRejectionInfo);
  }
  /** Handle a change of the plate info form */
  public handleChangePlateInfo(
    name: 'plate' | 'state' | 'country',
    value: string,
  ) {
    switch (name) {
      case 'plate':
        this.setState({ plate: value });
        break;
      case 'state':
        this.setState({ state: value });
        break;
      case 'country':
        this.setState({ country: value });
        break;
      default:
        break;
    }
    this.verifyForm();
  }
  /** Verify plate information */
  private verifyPlateInfo() {
    const { plate, state, country, selectedPlateIndex } = this.state;
    return (
      plate !== '' &&
      state !== '' &&
      country !== '' &&
      selectedPlateIndex !== undefined
    );
  }
  /** Verify the full form */
  public verifyForm() {
    const { plateRejectionEnabled, generalRejectionEnabled } = this.state;
    let plateRejectionOk = true;
    let plateOk = true;
    let generalRejectionOk = true;

    // Only verify the parts of the form that need to be verified
    if (plateRejectionEnabled) {
      plateRejectionOk = this.verifyPlateRejection();
    }
    if (generalRejectionEnabled) {
      generalRejectionOk = this.verifyGeneralRejection();
    }
    if (!generalRejectionEnabled && !plateRejectionEnabled) {
      plateOk = this.verifyPlateInfo();
    }

    this.setState({
      verified: plateRejectionOk && plateOk && generalRejectionOk,
    });
  }
  public async handleSubmit(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();

    const {
      infractionId,
      verifyInfraction,
      declineInfraction,
      onFinishSubmit,
    } = this.props;

    const {
      plate,
      state,
      infraction,
      plateRejectionEnabled,
      generalRejectionEnabled,
      selectedPlateIndex,
      plateImgKeys,
      plateRejectionInfo,
      generalRejectionInfo,
    } = this.state;

    this.setState({ submitting: true });

    const plateImgKey: string | undefined =
      selectedPlateIndex === undefined
        ? undefined
        : plateImgKeys[selectedPlateIndex];

    if (generalRejectionEnabled) {
      const rejectionInfo: RejectionInfo = {};
      if (plateRejectionEnabled) {
        rejectionInfo.plate = plateRejectionInfo;
      }
      if (generalRejectionEnabled) {
        rejectionInfo.general = generalRejectionInfo;
      }

      await declineInfraction({ infractionId, plateImgKey, rejectionInfo });

      this.setState({ submitting: false });

      if (onFinishSubmit) {
        onFinishSubmit();
      }
      return;
    }

    const plateReadability = plateRejectionEnabled
      ? 'UNREADABLE'
      : plate === 'NO_PLATE'
      ? 'NO_PLATE'
      : 'READABLE';
    await verifyInfraction({
      infractionId,
      vehicle: {
        plate: {
          readability: plateReadability,
          text: plateReadability === 'READABLE' ? plate : undefined,
          state: plateReadability === 'READABLE' ? state : undefined,
        },
      },
      imgKeyPlate: plateImgKey!,
      infraction: {
        location_code: '15', // TODO: acquire dynamically
        officer_id: 'AM', // TODO: acquire from configuration
        object_id: infraction!.objectId, // TODO: resolve undefinedness
        type: infraction!.violationType,
        time_parked: infraction!.startTime!.getTime(),
        time_recorded: infraction!.endTime!.getTime(),
      },
    });

    this.setState({ submitting: false });

    if (onFinishSubmit) {
      onFinishSubmit();
    }
  }
  public render() {
    const { loading, submitting } = this.state;

    if (loading) {
      return <PageLoader />;
    }

    const { infractionHandled } = this.state;
    if (infractionHandled) {
      return <AlreadyVerifiedView />;
    }

    const { clientTimezone } = this.props;
    const {
      infraction,
      plate,
      state,
      country,
      parkImgSrc,
      infractionImgSrc,
      plateImgSrcs,
      verified,
      plateRejectionEnabled,
      generalRejectionEnabled,
      selectedPlateIndex,
    } = this.state;

    const startTimeString = formatTimeToMatchImageTimestamp(
      infraction!.startTime!,
      clientTimezone,
    );
    const endTimeString = formatTimeToMatchImageTimestamp(
      infraction!.endTime!,
      clientTimezone,
    );

    return (
      <ManualVerificationView
        parkImgSrc={parkImgSrc!}
        infractionImgSrc={infractionImgSrc!}
        plateImgSrcs={plateImgSrcs!}
        plate={plate || ''}
        state={state || ''}
        country={country || ''}
        plateInfoDisabled={submitting}
        submitDisabled={!verified || submitting}
        onSelectPlateImg={this.handleSelectPlateImg}
        onClickGeneralReject={this.handleClickGeneralReject}
        onChangeGeneralReject={this.handleChangeGeneralReject}
        onClickPlateReject={this.handleClickPlateReject}
        onChangePlateReject={this.handleChangePlateReject}
        onChangePlateInfo={this.handleChangePlateInfo}
        onSubmit={this.handleSubmit}
        {...{
          submitting,
          selectedPlateIndex,
          startTimeString,
          endTimeString,
          plateRejectionEnabled,
          generalRejectionEnabled,
        }}
      />
    );
  }
}
