"use strict";
/**
 * @file Definition of the InvalidTypeError
 * @author Harris Lummis
 */

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Represents a type of error thrown when an invalid type is provided
 */

var InvalidTypeError =
/** @class */
function (_super) {
  __extends(InvalidTypeError, _super);
  /**
   * Creates a new InvalidTypeError
   * @param msg The message to display
   */


  function InvalidTypeError(msg) {
    var _this = _super.call(this, msg) || this;

    Object.setPrototypeOf(_this, InvalidTypeError.prototype);
    Error.captureStackTrace(_this, InvalidTypeError);
    return _this;
  }

  return InvalidTypeError;
}(Error);

exports.InvalidTypeError = InvalidTypeError;
exports.default = InvalidTypeError;