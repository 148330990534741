/**
 * @file The InfractionInfoTable component
 */
import React, { FunctionComponent } from 'react';
import { Infraction } from '@atlas/models';
import Table, { TableProps } from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { formatViolationType } from '../../libs/formatLib';
import moment from 'moment-timezone';

// TODO: add styling

/** Properties used to create an InfractionInfoTable */
export interface InfractionInfoTableProps {
  /** The infraction whose info will be rendered */
  infraction: Infraction;
  /** The location where the infraction was recorded */
  location: string;
  /** Optional properties passed to the rendered table */
  tableProps?: TableProps;
}

export const InfractionInfoTable: FunctionComponent<
  InfractionInfoTableProps
> = props => {
  const { infraction, location, tableProps } = props;

  const rows = [
    {
      name: 'Type',
      value: infraction.violationType
        ? formatViolationType(infraction.violationType)
        : 'N/A',
    },
    { name: 'Location', value: location },
    {
      name: 'Plate',
      value: (() => {
        const plateVal = infraction.plate
          ? infraction.plate.toUpperCase()
          : 'N/A';
        const stateVal = infraction.state
          ? infraction.state.toUpperCase()
          : 'N/A';

        if (plateVal === 'N/A' && stateVal === 'N/A') {
          return 'N/A';
        }
        return `${plateVal} (${stateVal})`;
      })(),
    },
    {
      name: 'Issue Date',
      value: infraction.endTime
        ? `${moment(infraction.endTime).format('L HH:mm')}`
        : 'N/A',
    },
    {
      name: 'Short Link',
      value: infraction.customerLink ? (
        <a href={infraction.customerLink}>{infraction.customerLink}</a>
      ) : (
        'N/A'
      ),
    },
  ];

  return (
    <Table {...tableProps}>
      <TableHead>
        <TableRow>
          <TableCell>Info</TableCell>
          <TableCell align="right" />
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(({ name, value }) => (
          <TableRow key={name}>
            <TableCell>{name}</TableCell>
            <TableCell align="right">{value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default InfractionInfoTable;
