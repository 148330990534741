/**
 * @file The user model
 * @author Harris Lummis
 */
import { UserStatus, UserResponseBody } from '../../controllers/userManagement';

/** An instance of the user model */
export interface UserArgs {
  /** The user's Username property */
  username: string;
  /** The user's firstName attribute */
  firstName: string;
  /** The user's lastName attribute */
  lastName: string;
  /** The user's privilegeLevel attribute */
  privilegeLevel: string;
  /** The epoch time at which the user was created */
  createdAt: number;
  /** The epoch time at which the user was last modified */
  updatedAt: number;
  /** The user's Enabled property */
  enabled: boolean;
  /** The user's Status property */
  status: UserStatus;
}

/**
 * A model for cognito users
 */
export class User {
  /** The user's Username property */
  public username: string;
  /** The user's firstName attribute */
  public firstName: string;
  /** The user's lastName attribute */
  public lastName: string;
  /** The user's privilegeLevel attribute */
  public privilegeLevel: string;
  /** The epoch time at which the user was created */
  public createdAt: number;
  /** The epoch time at which the user was last modified */
  public updatedAt: number;
  /** The user's Enabled property */
  public enabled: boolean;
  /** The user's Status property */
  public status: UserStatus;

  /**
   * Create a new User
   * @param args Arguments used to create the user
   */
  constructor(args: UserArgs) {
    this.username = args.username;
    this.firstName = args.firstName;
    this.lastName = args.lastName;
    this.privilegeLevel = args.privilegeLevel;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;
    this.enabled = args.enabled;
    this.status = args.status;
  }

  /**
   * Create a new User from a raw cognito response
   * @param rawUser The raw user to create from
   * @return A new User
   */
  public static FromRaw(rawUser: UserResponseBody): User {
    const {
      Username: username,
      Attributes: attrs,
      Enabled: enabled,
      UserCreateDate: createdAt,
      UserLastModifiedDate: updatedAt,
      UserStatus: status,
    } = rawUser;

    let firstName = '';
    let lastName = '';
    let privilegeLevel = '';

    attrs.forEach(attr => {
      if (attr.Name === 'firstName') {
        firstName = attr.Value;
      } else if (attr.Name === 'lastName') {
        lastName = attr.Value;
      } else if (attr.Name === 'privilegeLevel') {
        privilegeLevel = attr.Value;
      }
    });

    return new this({
      username,
      firstName,
      lastName,
      privilegeLevel,
      enabled,
      createdAt,
      updatedAt,
      status,
    });
  }
}

export default User;
