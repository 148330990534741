/**
 * @file General Rejection Form Group component. Renders form groups for general
 * rejection reasons.
 * @author Harris Lummis
 * @author Lucille Njoo
 * @author Bridget O'Connor
 */
import React, { FunctionComponent } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import { GeneralRejectionReasons } from './typings';
import FormControl from '@material-ui/core/FormControl';

/** Styles applied to the component */
const styles = () =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    },
  });

/**
 * Props for GeneralRejectionForm component. Includes
 * GeneralRejectionReasons.
 */
export interface GeneralRejectionFormProps extends WithStyles<typeof styles> {
  /**
   * Indicates whether the infraction was rejected because an incoming vehicle
   * swapped ids with the parked vehicle
   */
  trackerSwappedIncoming?: boolean;
  /**
   * Indicates whether the infraction was rejected because an outgoing vehicle
   * swapped ids with the parked vehicle
   */
  trackerSwappedOutgoing?: boolean;
  /** The start time does not match that on the image */
  wrongStartTime?: boolean;
  /** The end time does not match that on the image */
  wrongEndTime?: boolean;
  /** Indicates whether vehicle IDed in park region */
  taggedInParkRegion?: boolean;
  /** Any other reasons, please explain */
  other?: string | null;
  /** A function that handles a change in a form */
  onChange: (name: keyof GeneralRejectionReasons, value: any) => void;
}

/**
 * General Rejection Form Group component that renders form groups for general
 * rejection reasons.
 */
export const GeneralRejectionForm: FunctionComponent<
  GeneralRejectionFormProps
> = ({
  classes,
  trackerSwappedIncoming,
  trackerSwappedOutgoing,
  wrongStartTime,
  wrongEndTime,
  taggedInParkRegion,
  other,
  onChange,
}) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.name as keyof GeneralRejectionReasons, e.target.checked);
  };
  return (
    <div className={classes.root}>
      <FormControl>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!trackerSwappedIncoming}
                name="trackerSwappedIncoming"
                onChange={handleCheckboxChange}
              />
            }
            label="Tracker Swapped (Incoming)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!trackerSwappedOutgoing}
                name="trackerSwappedOutgoing"
                onChange={handleCheckboxChange}
              />
            }
            label="Tracker Swapped (Outgoing)"
          />
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!wrongStartTime}
                name="wrongStartTime"
                onChange={handleCheckboxChange}
              />
            }
            label="Wrong Park Time"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!wrongEndTime}
                name="wrongEndTime"
                onChange={handleCheckboxChange}
              />
            }
            label="Wrong Infraction Time"
          />
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!taggedInParkRegion}
                name="taggedInParkRegion"
                onChange={handleCheckboxChange}
              />
            }
            label="Tagged in Park Region"
          />
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormGroup>
          <TextField
            id="generalRejectedOther"
            label="Other"
            name="other"
            value={other || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange('other', e.target.value)
            }
            margin="normal"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(GeneralRejectionForm);
