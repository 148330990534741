/**
 * @file The EnforcerInfraction data-loading container
 */
import React, { FunctionComponent } from 'react';
import PageLoader from '../../components/PageLoader';
import EnforcerInfractionView from './EnforcerInfractionView';
import { useFetchInfraction, useFetchInfractionImages } from '@atlas/hooks';

/** Properties used to create an EnforcerInfraction container */
export interface EnforcerInfractionProps {
  /** ID of the infraction to display */
  infractionId: string;
  /** Location where the infraction took place */
  location: string;
}

/**
 * Data-fetching container for the EnforcerInfractionView container. Fetches
 * infraction, then generates urls for images of the infraction. Displays
 * a PageLoader while resources are loading.
 */
export const EnforcerInfraction: FunctionComponent<EnforcerInfractionProps> = ({
  infractionId,
  location,
}) => {
  const { infraction, isFetching: isFetchingInfraction } = useFetchInfraction(
    infractionId,
  );
  const { imageUrls, isFetching: isFetchingImages } = useFetchInfractionImages(
    infractionId,
  );

  if (isFetchingInfraction || isFetchingImages || !infraction) {
    return <PageLoader />;
  }

  return (
    <EnforcerInfractionView
      infraction={infraction}
      location={location}
      plateImgSrc={imageUrls.plateImgSrc}
      parkImgSrc={imageUrls.parkImgSrc}
      infractionImgSrc={imageUrls.infractionImgSrc}
    />
  );
};

export default EnforcerInfraction;
