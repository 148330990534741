"use strict";
/**
 * @file Definition of the applyMixins utility
 * @author Harris Lummis
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Apply mixins to a derived class. If multiple mixins are supplied, then
 * they will be applied in the order in which they appear, with later mixins
 * overwriting matching properties from earlier mixins. Note that the derived
 * class must implement the mixin classes in order to receive props properly.
 * @param derivedCtor The class/constructor to which mixins will be applied
 * @param baseCtors Mixins to apply
 */

function applyMixins(derivedCtor) {
  var baseCtors = [];

  for (var _i = 1; _i < arguments.length; _i++) {
    baseCtors[_i - 1] = arguments[_i];
  }

  baseCtors.forEach(function (baseCtor) {
    // Apply static properties of each mixin in order
    Object.keys(baseCtor).forEach(function (name) {
      var propDesc = Object.getOwnPropertyDescriptor(baseCtor, name);

      if (propDesc) {
        Object.defineProperty(derivedCtor, name, propDesc);
      }
    }); // Apply instance properties of each mixin in order

    Object.getOwnPropertyNames(baseCtor.prototype).forEach(function (name) {
      var propDesc = Object.getOwnPropertyDescriptor(baseCtor.prototype, name);

      if (propDesc) {
        Object.defineProperty(derivedCtor.prototype, name, propDesc);
      }
    });
  });
}

exports.applyMixins = applyMixins;
exports.default = applyMixins;