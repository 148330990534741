/**
 * @file Definition of the AnalyticsDisplay component
 * @author Harris Lummis
 */
import Paper from '@material-ui/core/Paper';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { ChartData, ChartOptions } from 'chart.js';
import includes from 'lodash/includes';
import _orderBy from 'lodash/orderBy';
import set from 'lodash/set';
import moment from 'moment';
import React from 'react';
import ChartWithAverages from '../ChartWithAverages';
import Table, { Column } from '../DataTable';
import {
  TimeRangeResolutionName,
  Order,
  TimeRangeName,
  ResolutionAndTicks,
  TimeAxisUnit,
  UpdateParams,
} from './typings';
import DataRangePicker from './DataRangePicker';

const DEFAULT_AXIS_SPEC = {
  type: 'time',
  display: true,
  scaleLabel: {
    display: false,
    labelString: 'Date',
  },
};

const RANGE_TO_RESOLUTION_AXIS_MAP = {
  '1D': {
    hour: [
      {
        ...DEFAULT_AXIS_SPEC,
        time: {
          unit: 'hour',
          displayFormats: {
            hour: 'MM/DD hA',
          },
          stepSize: 3,
        },
      },
    ],
  },
  '1W': {
    hour: [
      {
        ...DEFAULT_AXIS_SPEC,
        time: {
          displayFormats: {
            hour: 'MM/DD',
          },
        },
      },
    ],
    day: [
      {
        ...DEFAULT_AXIS_SPEC,
        time: {
          unit: 'day',
          displayFormats: {
            hour: 'MM/DD',
          },
        },
      },
    ],
  },
  '1M': {
    day: [DEFAULT_AXIS_SPEC],
    week: [DEFAULT_AXIS_SPEC],
  },
  '3M': {
    day: [DEFAULT_AXIS_SPEC],
    week: [DEFAULT_AXIS_SPEC],
    month: [
      {
        ...DEFAULT_AXIS_SPEC,
        time: {
          unit: 'month',
          displayFormats: {
            month: 'MMM',
          },
        },
      },
    ],
  },
  '1Y': {
    day: [DEFAULT_AXIS_SPEC],
    week: [DEFAULT_AXIS_SPEC],
    month: [DEFAULT_AXIS_SPEC],
  },
};

const resolutionAndLabelLookupTable: {
  [timeRangeName in TimeRangeName]: ResolutionAndTicks[]
} = {
  '1D': [{ resolution: 'hour', ticks: 'hour' }],
  '1W': [
    { resolution: 'day', ticks: 'day' },
    { resolution: 'hour', ticks: 'day' },
  ],
  '1M': [
    { resolution: 'day', ticks: 'week' },
    { resolution: 'week', ticks: 'week' },
  ],
  '3M': [
    { resolution: 'day', ticks: 'week' },
    { resolution: 'week', ticks: 'week' },
    { resolution: 'month', ticks: 'month' },
  ],
  '1Y': [
    { resolution: 'day', ticks: 'month' },
    { resolution: 'week', ticks: 'month' },
    { resolution: 'month', ticks: 'month' },
  ],
};

/**
 * Lookup allowed resolutions for a given time range
 * @param timeRangeName - the name of the time range for which to lookup
 * allowed resolutions
 * @returns an array of allowed resolutions
 */
function lookupAllowedResolutions(timeRangeName: TimeRangeName) {
  return resolutionAndLabelLookupTable[timeRangeName].map(({ resolution }) => {
    return resolution;
  });
}

/**
 * Get the epoch time start of the current hour
 * @returns the epoch time start of the current hour
 */
function getCurrentHourStart() {
  return moment()
    .startOf('h')
    .valueOf();
}

/**
 * Get a time range from the current hour start and the name of the range
 * @param currentHourStart - the epoch time of the current hour start
 * @param timeRangeName - the name of the time range
 * @returns an object containing the start and end epoch times of the time range
 */
function getTimeRange(currentHourStart: number, timeRangeName: TimeRangeName) {
  const currentHourStartMoment = moment(currentHourStart);
  let offset: number;
  let unit: moment.unitOfTime.Base;
  switch (timeRangeName) {
    case '1D':
      offset = 1;
      unit = 'd';
      break;
    case '1W':
      offset = 1;
      unit = 'w';
      break;
    case '1M':
      offset = 1;
      unit = 'M';
      break;
    case '3M':
      offset = 3;
      unit = 'M';
      break;
    case '1Y':
      offset = 1;
      unit = 'y';
      break;
    default:
      throw new Error('could not recognize time range');
  }

  return {
    end: currentHourStart,
    start: moment(currentHourStartMoment)
      .subtract(offset, unit)
      .valueOf(),
  };
}

export interface AnalyticsDisplayProps {
  /** Formatting functions */
  avgFormatters?: {
    [dataset: string]: (value: number) => any;
  };
  /** Allowed time range names */
  allowedRanges?: TimeRangeName[];
  /** Height of the chart */
  chartHeight?: number;
  /** The title of the display */
  title?: string;
  /** A component title. Will replace title. */
  componentTitle?: JSX.Element;
  /** Variable time column */
  timeColumnMap: { [timeResolution in TimeRangeResolutionName]: Column };
  /** The specifications for table columns */
  valueColumns: any[]; // TODO: get more specific
  /** Update data for display */
  updateData: (updateParams: UpdateParams) => any;
  /** Options for chart display */
  chartOptions: ChartOptions;
  /** The type of chart to display */
  chartType: 'line' | 'bar';
  /** A function to format data for chart display */
  formatChartData: (
    timeResolution: TimeRangeResolutionName,
    data: any[],
  ) => ChartData;
  /** A function to calculate means for chart */
  meanFunction: (data: any[]) => number;
  /** The default sort column */
  defaultOrderBy: string;
  /** The default order to sort in. Defaults to 'desc' */
  defaultOrder?: 'asc' | 'desc';
  /** The default time range to display. Defaults to '1D' if not specified */
  defaultTimeRangeName?: TimeRangeName;
  /**
   * The default time range resolution to display. Defaults to 'hour' if not
   * specified
   */
  defaultTimeRangeResolution?: TimeRangeResolutionName;
  /**
   * The default time axis label unit to display. Defaults to 'hour' if not
   * specified
   */
  defaultTimeAxisUnit?: TimeAxisUnit;
  /** Maximum number of numbers after decimal to display */
  maxDecimalPrecision: number;
  /** Display averages. Defaults to true. */
  displayAverages?: boolean;
  /** Display date range picker. Defaults to true. */
  displayDataRangePicker?: boolean;
  /** Display table. Defaults to true. */
  displayTable?: boolean;
  /** Display chart. Defaults to true */
  displayChart?: boolean;
  /** Display table footer. Defaults to true */
  displayFooter?: boolean;
  /** Display table header. Defaults to true */
  displayHeader?: boolean;
  /** Display in a single paper component. Defaults to false */
  singlePaper?: boolean;
  /** Table title to display, if any. Defaults to the empty string */
  tableTitle?: string;
  /** CSS classes */
  className?: string;
  /** Properties of the chart container, if any */
  chartContainerProps?: React.HTMLAttributes<HTMLDivElement>;
}

export interface AnalyticsDisplayState {
  /** The start time of the current hour */
  currentHourStart: number;
  /** The name of the selected time range */
  timeRangeName: TimeRangeName;
  /** The name of the selected resolution */
  timeRangeResolution: TimeRangeResolutionName;
  /** The names of allowed resolutions */
  // TODO: what if we switch time range and current resolution is not allowed?
  allowedResolutions: TimeRangeResolutionName[];
  /** The unit by which the time axis is labeled */
  timeAxisUnit: 'hour' | 'day' | 'week' | 'month';
  /** The time range to be displayed */
  timeRange: {
    start: number;
    end: number;
  };
  /** True if new data is being loaded */
  isLoading: boolean;
  /** Chart/table data */
  data: any[];
  /** The column to sort by */
  orderBy: string;
  /** The order to sort in */
  order: 'asc' | 'desc';
  /** The total number of available rows */
  totalCount: number;
}

type AnalyticsDisplayPropsWithStyles = AnalyticsDisplayProps &
  WithStyles<
    | 'table'
    | 'individualPaperMainContainer'
    | 'individualPaperChart'
    | 'individualPaperPicker'
    | 'individualPaperTable'
    | 'singlePaperChart'
  >;

const decorateAnalyticsDisplay = withStyles((theme: Theme) => ({
  table: {
    marginTop: theme.spacing(),
  },
  individualPaperMainContainer: {
    marginBottom: theme.spacing(),
  },
  individualPaperChart: {
    marginBottom: theme.spacing(),
  },
  individualPaperPicker: {
    marginBottom: theme.spacing(),
  },
  individualPaperTable: {},
  singlePaperChart: {
    padding: theme.spacing(2),
  },
}));

const AnalyticsDisplay = decorateAnalyticsDisplay(
  class extends React.Component<
    AnalyticsDisplayPropsWithStyles,
    AnalyticsDisplayState
  > {
    constructor(props: AnalyticsDisplayPropsWithStyles) {
      super(props);
      const timeRangeName = props.defaultTimeRangeName || '1D';
      const timeRangeResolution = props.defaultTimeRangeResolution || 'hour';
      // TODO: validate these specs
      const timeAxisUnit = props.defaultTimeAxisUnit || 'hour';
      const currentHourStart = getCurrentHourStart();
      const timeRange = getTimeRange(currentHourStart, timeRangeName);

      this.state = {
        timeRangeName,
        timeRangeResolution,
        allowedResolutions: lookupAllowedResolutions(timeRangeName),
        timeAxisUnit,
        currentHourStart: getCurrentHourStart(),
        timeRange,
        isLoading: true,
        data: [],
        orderBy: props.defaultOrderBy,
        order: 'desc',
        totalCount: 0,
      };
    }
    public async componentDidMount() {
      const { timeRangeResolution, timeRange, orderBy, order } = this.state;

      await this.updateData({
        timeRangeResolution,
        timeRange,
        orderBy,
        order,
      });
    }
    public updateData = async (updateParams: UpdateParams) => {
      this.setState({
        isLoading: true,
      });
      const {
        timeRange: prevTimeRange,
        timeRangeResolution: prevResolution,
        data: oldData,
      } = this.state;
      const { timeRange, timeRangeResolution, orderBy, order } = updateParams;

      if (
        timeRange.start === prevTimeRange.start &&
        timeRange.end === prevTimeRange.end &&
        timeRangeResolution === prevResolution &&
        oldData.length > 0
      ) {
        this.setState({
          isLoading: false,
          order,
          orderBy,
        });
      } else {
        try {
          const data = await this.props.updateData(updateParams);
          this.setState({
            isLoading: false,
            data,
            totalCount: data.length,
          });
        } catch (err) {
          alert(err);
          this.setState({
            isLoading: false,
          });
        }
      }
    };
    public handleTimeRangeChange = async (timeRangeName: TimeRangeName) => {
      const {
        currentHourStart,
        timeRangeResolution,
        order,
        orderBy,
      } = this.state;
      const newTimeRangeName = timeRangeName;
      const newAllowedResolutions = lookupAllowedResolutions(timeRangeName);
      let newResolution = timeRangeResolution;
      // Set a new resolution if the current selected is not allowed
      if (!includes(newAllowedResolutions, timeRangeResolution)) {
        newResolution = newAllowedResolutions[0];
      }
      const newTimeRange = getTimeRange(currentHourStart, newTimeRangeName);
      this.setState({
        timeRangeName: newTimeRangeName,
        timeRange: newTimeRange,
        allowedResolutions: newAllowedResolutions,
        timeRangeResolution: newResolution,
      });

      await this.updateData({
        timeRangeResolution: newResolution,
        timeRange: newTimeRange,
        order,
        orderBy,
      });
    };
    public handleTimeResolutionChange = async (
      timeRangeResolution: TimeRangeResolutionName,
    ) => {
      const { timeRange, order, orderBy } = this.state;
      this.setState({
        timeRangeResolution,
        isLoading: true,
      });
      await this.updateData({
        timeRangeResolution,
        timeRange,
        order,
        orderBy,
      });
    };
    public formatDataForTable() {
      const { data, order, orderBy } = this.state;
      return _orderBy(data, [orderBy], [order]);
    }

    public renderChart() {
      const {
        avgFormatters,
        chartContainerProps = {},
        chartHeight,
        chartOptions,
        chartType,
        classes,
        componentTitle,
        displayAverages = true,
        formatChartData,
        maxDecimalPrecision,
        meanFunction,
        singlePaper = false,
        title,
      } = this.props;
      const {
        timeRangeName,
        timeRangeResolution,
        // timeAxisUnit, // TODO: apply this
        isLoading,
        data,
      } = this.state;

      const optionsWithFormattedXAxis = set(
        { ...chartOptions },
        'scales.xAxes',
        RANGE_TO_RESOLUTION_AXIS_MAP[timeRangeName][timeRangeResolution],
      );

      const chart = (
        <ChartWithAverages
          title={title}
          componentTitle={componentTitle}
          isLoading={isLoading}
          type={chartType}
          options={optionsWithFormattedXAxis}
          data={formatChartData(timeRangeResolution, data)}
          maxDecimals={maxDecimalPrecision}
          meanFunction={meanFunction}
          displayAverages={displayAverages}
          avgFormatters={avgFormatters}
          height={chartHeight}
          {...chartContainerProps}
        />
      );

      return singlePaper ? (
        <div className={classes.singlePaperChart}>{chart}</div>
      ) : (
        <Paper className={classes.individualPaperChart}>{chart}</Paper>
      );
    }

    public renderDataRangePicker() {
      const { allowedRanges, classes, singlePaper = false } = this.props;
      const {
        timeRangeName,
        timeRangeResolution,
        allowedResolutions,
        // timeAxisUnit, // TODO: apply this
      } = this.state;

      const picker = (
        <DataRangePicker
          allowedRanges={allowedRanges}
          allowedResolutions={allowedResolutions}
          selectedRangeName={timeRangeName}
          selectedResolutionName={timeRangeResolution}
          onRangeChange={(label: string) => {
            return (event: any) => {
              this.handleTimeRangeChange(label as TimeRangeName);
            };
          }}
          onResolutionChange={(label: string) => (event: any) => {
            this.handleTimeResolutionChange(label as TimeRangeResolutionName);
          }}
        />
      );

      return singlePaper ? (
        picker
      ) : (
        <Paper className={classes.individualPaperPicker}>{picker}</Paper>
      );
    }

    public renderTable() {
      const {
        classes,
        componentTitle,
        displayAverages = true,
        displayFooter = true,
        displayHeader = true,
        singlePaper = false,
        tableTitle = '',
        timeColumnMap,
        valueColumns,
      } = this.props;
      const {
        isLoading,
        order,
        orderBy,
        timeRange,
        timeRangeResolution,
        totalCount,
      } = this.state;

      const table = (
        <Table
          title={tableTitle}
          componentTitle={componentTitle}
          columns={[timeColumnMap[timeRangeResolution]].concat(valueColumns)}
          data={this.formatDataForTable()}
          displayHover={true}
          small={!displayAverages}
          orderBy={orderBy}
          order={order}
          paper={!singlePaper}
          enableSelect={false}
          enableSelectAll={false}
          isLoading={isLoading}
          updateData={(newOrderBy?: string, newOrder?: Order) => {
            this.updateData({
              timeRange,
              timeRangeResolution,
              order: newOrder || order,
              orderBy: newOrderBy || orderBy,
            });
          }}
          totalRows={totalCount}
          displayFooter={displayFooter}
          displayHeader={displayHeader}
        />
      );

      return singlePaper ? (
        <div>{table}</div>
      ) : (
        <Paper className={classes.individualPaperTable}>{table}</Paper>
      );
    }

    public render() {
      const {
        displayChart = true,
        displayDataRangePicker = true,
        displayTable = true,
        singlePaper = false,
      } = this.props;

      const content = (
        <div>
          {displayChart && this.renderChart()}
          {displayDataRangePicker && this.renderDataRangePicker()}
          {displayTable && this.renderTable()}
        </div>
      );

      return singlePaper ? (
        <Paper className={this.props.className}>{content}</Paper>
      ) : (
        <div className={this.props.className}>{content}</div>
      );
    }
  },
);

export default AnalyticsDisplay;
