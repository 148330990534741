/**
 * @file Citations routes
 * @author Harris Lummis
 */
import React from 'react';
import { Switch } from 'react-router-dom';
import { ManualVerificationPage } from './manual-verification';
import AuthenticatedRoute from '../../components/AuthenticatedRoute';
import IssuedCitations from '../../containers/IssuedCitations';
import { PagesChildProps } from '../typings';
import VerifyCitations from '../../containers/VerifyCitations';
import SingleInfractionRoute from './SingleInfractionRoute';

export interface CitationsPagesProps extends PagesChildProps {
  path: string;
}

export const citations = ({ path, ...childProps }: CitationsPagesProps) => (
  <Switch>
    <AuthenticatedRoute
      path={`${path}/issued`}
      exact={true}
      component={IssuedCitations}
      props={childProps}
      isAuthenticated={childProps.isAuthenticated}
    />
    <AuthenticatedRoute
      path={`${path}/pending`}
      exact={true}
      component={VerifyCitations}
      props={childProps}
      isAuthenticated={childProps.isAuthenticated}
    />
    <AuthenticatedRoute
      path={`${path}/manual-verification/:infractionId`}
      component={ManualVerificationPage}
      props={{}}
      isAuthenticated={childProps.isAuthenticated}
    />
    <SingleInfractionRoute path={`${path}/:infractionId`} />
  </Switch>
);

export default citations;
