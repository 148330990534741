import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import amber from '@material-ui/core/colors/amber';
import deepOrange from '@material-ui/core/colors/deepOrange';
import map from 'lodash/map';
import { defaults } from 'react-chartjs-2';

(defaults as any).global.defaultFontFamily =
  '"IBM Plex Sans", "Helvetica Neue", Arial, Helvetica, sans-serif';

type Dataset = [string, any[]];

function getColors(index: number) {
  switch (index % 5) {
    case 0:
      return {
        backgroundColor: blue[900],
        borderColor: blue[900],
        pointHoverBackgroundColor: blue[900],
        pointHoverBorderColor: blue.A100,
        pointBorderColor: blue.A100,
        pointBackgroundColor: '#fff',
      };
    case 1:
      return {
        backgroundColor: blue[300],
        borderColor: blue[300],
        pointHoverBackgroundColor: blue[300],
        pointHoverBorderColor: blue.A700,
        pointBorderColor: blue.A700,
        pointBackgroundColor: '#fff',
      };
    case 2:
      return {
        backgroundColor: blueGrey[400],
        borderColor: blueGrey[400],
        pointHoverBackgroundColor: blueGrey[400],
        pointHoverBorderColor: blueGrey[800],
        pointBorderColor: blueGrey[800],
        pointBackgroundColor: '#fff',
      };
    case 3:
      return {
        backgroundColor: amber[500],
        borderColor: amber[500],
        pointHoverBackgroundColor: amber[500],
        pointHoverBorderColor: amber.A100,
        pointBorderColor: amber.A100,
        pointBackgroundColor: '#fff',
      };
    case 4:
      return {
        backgroundColor: deepOrange[500],
        borderColor: deepOrange[500],
        pointHoverBackgroundColor: deepOrange[500],
        pointHoverBorderColor: deepOrange.A100,
        pointBorderColor: deepOrange.A100,
        pointBackgroundColor: '#fff',
      };
    default:
      throw new Error('could not determine color');
  }
}

export function styleDatasets(datasetSpecs: Dataset[]) {
  return map(datasetSpecs, ([label, data], index) => {
    return {
      label,
      fill: false,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointRadius: 1,
      pointHitRadius: 10,
      lineTension: 0.1,
      data,
      ...getColors(index),
    };
  });
}
