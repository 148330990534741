import { API } from 'aws-amplify';
import moment from 'moment-timezone';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Table, { Column } from '../components/DataTable';
import ImageModal from '../components/ImageModal';
import config from '../config';
import { formatViolationType } from '../libs/formatLib';
import BigTableStyle from '../styles/BigTable';
import { PendingCitation } from '../typings/responses';
import { ImageLinks } from '../typings/types';
import './Home.css';

export interface Props extends RouteComponentProps<string> {
  /** Column definitions */
  columnDefs: Column[];
  /** True if the app is in demo mode */
  isDemo: boolean;
  /** True if the user is authenticated */
  isAuthenticated: boolean;
}

export interface State {
  /** True when loading */
  isLoading: boolean;
  /** An array of pending citation objects */
  issuedCitations: IssuedCitation[];
  orderBy: string;
  order: 'asc' | 'desc';
}

interface IssuedCitation extends PendingCitation {
  /** Indicates whether or not the citation has been verified */
  verified?: boolean;
  /** Indicates verification time, if any */
  verifiedAt?: number;
}

const TABLE_COLUMNS: Column[] = [
  {
    id: 'license_plate',
    label: 'Plate #',
    sortable: true,
    disablePadding: false,
    numeric: false,
  },
  {
    id: 'violation_type',
    label: 'Type',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (violationType: string) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {formatViolationType(violationType)}
        </p>
      );
    },
  },
  {
    id: 'end_time',
    label: 'Recorded At',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (time: string) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {moment
            .tz(time, config.clientTimezone)
            .format('MM/DD/YYYY h:mm:ss A')}
        </p>
      );
    },
  },
  {
    id: 'images',
    label: 'Images',
    sortable: false,
    disablePadding: false,
    dense: true,
    numeric: false,
    render: (imageLinks: ImageLinks) => {
      return (
        <div
          style={{
            width: '240px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
          className="image-link-container"
        >
          <ImageModal
            imgSrc={imageLinks.plate}
            imgStyle={{
              maxWidth: '80%',
              maxHeight: '80%',
              width: 'auto' as 'auto',
              height: 'auto' as 'auto',
              marginLeft: 'auto' as 'auto',
              marginRight: 'auto' as 'auto',
              display: 'block' as 'block',
            }}
            title="Plate"
            buttonProps={{
              size: 'small',
            }}
          />
          <ImageModal
            imgSrc={imageLinks.park}
            imgStyle={{
              maxWidth: '80%',
              maxHeight: '80%',
              width: 'auto' as 'auto',
              height: 'auto' as 'auto',
              marginLeft: 'auto' as 'auto',
              marginRight: 'auto' as 'auto',
              display: 'block' as 'block',
            }}
            title="Park"
            buttonProps={{
              size: 'small',
            }}
          />
          <ImageModal
            imgSrc={imageLinks.violation}
            imgStyle={{
              maxWidth: '80%',
              maxHeight: '80%',
              width: 'auto' as 'auto',
              height: 'auto' as 'auto',
              marginLeft: 'auto' as 'auto',
              marginRight: 'auto' as 'auto',
              display: 'block' as 'block',
            }}
            title="Violation"
            buttonProps={{
              size: 'small',
            }}
          />
        </div>
      );
    },
  },
  {
    id: 'verifiedAt',
    label: 'Issued At',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (time: number) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {new Date(time).toLocaleString()}
        </p>
      );
    },
  },
];

export default class IssuedCitations extends React.Component<Props, State> {
  private columns: Column[];
  constructor(props: Props) {
    super(props);
    this.columns = TABLE_COLUMNS;
    this.state = {
      isLoading: true,
      issuedCitations: [],
      orderBy: 'verifiedAt',
      order: 'desc',
    };
  }
  public async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      await this.issuedCitations();
    } catch (err) {
      alert(err);
    }

    this.setState({ isLoading: false });
  }
  public async issuedCitations(orderBy?: string, order?: 'asc' | 'desc') {
    const { orderBy: curOrderBy, order: curOrder } = this.state;
    orderBy = orderBy || curOrderBy;
    order = order || curOrder;
    // TODO: work with non-demo as well
    const previouslyVerifiedCitationString = sessionStorage.getItem(
      'verifiedCitations',
    );
    const verifiedCitations: IssuedCitation[] = [];
    if (
      typeof previouslyVerifiedCitationString &&
      previouslyVerifiedCitationString
    ) {
      verifiedCitations.push(...JSON.parse(previouslyVerifiedCitationString));
    }

    verifiedCitations.sort((c1, c2) => {
      return order === 'asc'
        ? c1[orderBy!] < c2[orderBy!]
          ? -1
          : 1
        : c2[orderBy!] < c1[orderBy!]
        ? -1
        : 1;
    });

    this.setState({ issuedCitations: verifiedCitations });
  }
  public notes() {
    return API.get('notes', '/notes', null);
  }
  public renderCitationsTable(citations: IssuedCitation[]) {
    return (
      <div className="VerifyCitations">
        <Table
          title="Issued Citations"
          columns={this.columns}
          data={citations}
          displayHover={true}
          orderBy="verifiedAt"
          order="desc"
          enableSelect={false}
          enableSelectAll={false}
          totalRows={citations.length}
          updateData={(orderBy: string, order: 'asc' | 'desc') =>
            this.issuedCitations(orderBy, order)
          }
        />
      </div>
    );
  }
  public renderLander() {
    return (
      <div className="lander">
        <h1>Scratch</h1>
        <p>A simple note taking app</p>
      </div>
    );
  }
  public renderNotes() {
    return (
      <div className="notes">
        {!this.state.isLoading &&
          this.renderCitationsTable(this.state.issuedCitations)}
      </div>
    );
  }
  public render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderNotes() : this.renderLander()}
      </div>
    );
  }
}
