"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var models_1 = require("@atlas/models");

var ClientUninitializedError_1 = require("./errors/ClientUninitializedError");
/**
 * A client used for hitting the Atlas API. Requires configuration using an
 * API generated from aws-amplify.
 */


var APIClient =
/** @class */
function () {
  /**
   * Creates a new APIClient instance. Private to maintain singularity
   */
  function APIClient(_a) {
    var API = _a.API,
        logger = _a.logger,
        apiName = _a.apiName;
    this.API = API;
    this.apiName = apiName;
    this.logger = logger;
  }

  Object.defineProperty(APIClient, "API", {
    get: function get() {
      return this.instance ? this.instance.API : undefined;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(APIClient, "apiName", {
    get: function get() {
      return this.instance ? this.instance.apiName : undefined;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(APIClient, "logger", {
    get: function get() {
      return this.instance ? this.instance.logger : undefined;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * Initializes the APIClient
   * @param cfg Configuration used to initialize the client
   * @return The client class
   */

  APIClient.init = function (cfg) {
    this.instance = new this(cfg);
    return this;
  };
  /**
   * Destroys the instance currently maintained by the APIClient class
   * @return The client class
   */


  APIClient.destroy = function () {
    delete this.instance;
    return this;
  };
  /**
   * Gets a single infraction by its id. Hits the /infractions/:infractionId
   * api endpoint and marshals the response. If a 404 response is received,
   * resolves to null.
   * @param infractionId UUID of the infraction to retrieve
   * @return A promise that resolves to the retrieved infraction, or null if
   * no infraction was found at the provided id.
   */


  APIClient.getInfraction = function (infractionId) {
    return __awaiter(this, void 0, void 0, function () {
      var _a, API, apiName, logger, data, err_1;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (!this.instance) {
              throw new ClientUninitializedError_1.ClientUninitializedError('APIClient.getInfraction');
            }

            _a = this.instance, API = _a.API, apiName = _a.apiName, logger = _a.logger;
            _b.label = 1;

          case 1:
            _b.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , API.get(apiName, "/infractions/" + infractionId)];

          case 2:
            data = _b.sent();

            if (logger) {
              logger.debug('found infraction', {
                infractionId: infractionId,
                data: data
              });
            }

            return [2
            /*return*/
            , models_1.Infraction.FromUnmarshalled(data.item)];

          case 3:
            err_1 = _b.sent();

            if (err_1.response) {
              if (err_1.response.status && err_1.response.status === 404) {
                if (logger) {
                  logger.debug('could not find infraction', {
                    infractionId: infractionId
                  });
                }

                return [2
                /*return*/
                , null];
              }
            }

            if (logger) {
              logger.error('error finding infraction', {
                error: err_1,
                infractionId: infractionId
              });
            }

            throw err_1;

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
  /**
   * Gets a single infraction's images by its id. Hits the
   * /infractions/:infractionId/images api endpoint and marshals the response.
   * If a 404 response is received, resolves to null.
   * @param infractionId UUID of the infraction to retrieve
   * @return A promise that resolves to the retrieved infraction image srcs, or null if
   * no infraction was found at the provided id.
   */


  APIClient.getInfractionImages = function (infractionId) {
    return __awaiter(this, void 0, void 0, function () {
      var _a, API, apiName, logger, data, err_2;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (!this.instance) {
              throw new ClientUninitializedError_1.ClientUninitializedError('APIClient.getInfraction');
            }

            _a = this.instance, API = _a.API, apiName = _a.apiName, logger = _a.logger;
            _b.label = 1;

          case 1:
            _b.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , API.get(apiName, "/infractions/" + infractionId + "/images")];

          case 2:
            data = _b.sent().data;

            if (logger) {
              logger.debug('found infraction', {
                infractionId: infractionId,
                data: data
              });
            }

            return [2
            /*return*/
            , data];

          case 3:
            err_2 = _b.sent();

            if (err_2.response) {
              if (err_2.response.status && err_2.response.status === 404) {
                if (logger) {
                  logger.debug('could not find infraction', {
                    infractionId: infractionId
                  });
                }

                return [2
                /*return*/
                , null];
              }
            }

            if (logger) {
              logger.error('error finding infraction', {
                error: err_2,
                infractionId: infractionId
              });
            }

            throw err_2;

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  return APIClient;
}();

exports.APIClient = APIClient;
exports.default = APIClient;