"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @file Definition of the Coordinate model
 * @author Harris Lummis
 */

var openalpr_pb_1 = require("@automotus/openalpr-pb");

var mixins_1 = require("@atlas/models/mixins");

var utils_1 = require("@atlas/models/utils");
/**
 * Represents a coordinate. Includes methods for encoding/decoding over wire format
 * provided by protobuf message definition
 */


var Coordinate =
/** @class */
function (_super) {
  __extends(Coordinate, _super);

  function Coordinate() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * Creates a new coordinate from a wire-format encoding
   * @param buf The buffer holding the wire-format encoded Coordinate
   * @return A new Coordinate
   */


  Coordinate.FromBuffer = function (buf) {
    return utils_1.fromBuffer(this, buf);
  };
  /**
   * Creates a new Coordinate from an object of in camel, snake, or pascal case
   * @param obj The source object from which to create the Coordinate
   * @param opts Options used to define creation
   * @return A new Coordinate instance
   */


  Coordinate.FromObject = function (obj) {
    return utils_1.parseObject(this, obj);
  };
  /** Default property values */


  Coordinate.DEFAULTS = {
    x: 0,
    y: 0
  };
  return Coordinate;
}(mixins_1.Bufferable(openalpr_pb_1.openalpr.cloud.Coordinate));

exports.Coordinate = Coordinate;
mixins_1.applyMixins(Coordinate, mixins_1.Objectable, mixins_1.IsBufferable);
exports.default = Coordinate;