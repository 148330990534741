import LinkHeader from 'http-link-header';
import concat from 'lodash/concat';
import each from 'lodash/each';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AnalyticsDisplay, { UpdateParams } from '../components/AnalyticsDisplay';
import { Column } from '../components/DataTable';
import { ErrorBar } from '../components/errors';
import { retryApi } from '../libs/apiLib';
import {
  formatDuration,
  getRemainingAPIPathsFromLinks,
} from '../libs/formatLib';
import { aggregateChunks } from '../libs/utils';
import BigTableStyle from '../styles/BigTable';
import { styleDatasets } from '../styles/LineGraphs';

export interface ParkingAndTrafficAnalyticsProps
  extends RouteComponentProps<string> {
  isAuthenticated: boolean;
}

export type ParkingAndTrafficAnalyticsState = Readonly<typeof initialState>;

const initialState = {
  err: undefined as Error | undefined,
};

// TODO: this will actually change, make non-const
const TIME_COLUMNS = {
  hour: {
    id: 'hourTime',
    label: 'Hour',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (dayTime: number) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {new Date(dayTime).toLocaleString()}
        </p>
      );
    },
  },
  day: {
    id: 'dayTime',
    label: 'Day',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (dayTime: number) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {new Date(dayTime).toLocaleDateString()}
        </p>
      );
    },
  },
  week: {
    id: 'dayTime',
    label: 'Week',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (dayTime: number) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {new Date(dayTime).toLocaleDateString()}
        </p>
      );
    },
  },
  month: {
    id: 'dayTime',
    label: 'Month',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (dayTime: number) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {new Date(dayTime).toLocaleDateString()}
        </p>
      );
    },
  },
};
const VALUE_COLUMNS: Column[] = [
  {
    id: 'parks',
    label: '# Parks',
    sortable: true,
    disablePadding: false,
    numeric: true,
  },
  {
    id: 'vehicles',
    label: '# Vehicles',
    sortable: true,
    disablePadding: false,
    numeric: true,
  },
  {
    id: 'avgParkTime',
    label: 'Avg. Park Time (HH:MM:SS)',
    sortable: true,
    disablePadding: false,
    numeric: true,
    render: (timeInSeconds: number) => {
      const duration = moment.duration(timeInSeconds, 's');
      return <p>{formatDuration(duration)}</p>;
    },
  },
];

export default class ParkingAndTrafficAnalytics extends React.Component<
  ParkingAndTrafficAnalyticsProps,
  ParkingAndTrafficAnalyticsState
> {
  constructor(props: ParkingAndTrafficAnalyticsProps) {
    super(props);
    this.state = initialState;
  }
  /** Handle an error */
  public handleError = (err: Error) => {
    this.setState({ err });
  };
  /** Handle a press on the error display bar close button */
  public handleErrorBarClose = () => {
    this.setState({ err: undefined });
  };
  /** Handle a press on the error display bar retry button */
  public handleErrorBarRetry = () => {
    window.location.reload();
  };
  public updateData = async (updateParams: UpdateParams) => {
    const { timeRangeResolution, timeRange, order } = updateParams;
    let path = '/analytics/';
    if (timeRangeResolution === 'hour') {
      path += 'hourly?';
    } else {
      path += 'daily?';
    }

    path += qs.stringify({
      timeRange: [timeRange.start, timeRange.end],
      order,
    });

    try {
      const { data, headers } = await retryApi.get('parking', path, {
        response: true,
      });
      const { rows } = data;
      const links = LinkHeader.parse(headers.link);
      const remainingPaths = getRemainingAPIPathsFromLinks(links, 2);

      return Promise.all(
        remainingPaths.map(remainingPath => {
          return retryApi.get('parking', remainingPath, null);
        }),
      ).then(results => {
        const allData = concat(
          rows,
          flatten(map(results, result => result.data.rows)),
        );
        if (timeRangeResolution === 'week') {
          return aggregateChunks(
            allData,
            7,
            ['avgParkTime'],
            ['parks', 'vehicles'],
          );
        } else if (timeRangeResolution === 'month') {
          // TODO: actual month length?
          return aggregateChunks(
            allData,
            30,
            ['avgParkTime'],
            ['parks', 'vehicles'],
          );
        } else {
          return allData;
        }
      });
    } catch (err) {
      this.handleError(err);
      return []; // TODO: probably want an error
    }
  };
  public renderPage() {
    console.log(this.state); // tslint:disable-line
    const { err } = this.state;
    return (
      <div>
        <ErrorBar
          err={err}
          onClose={this.handleErrorBarClose}
          onRetry={this.handleErrorBarRetry}
        />
        <AnalyticsDisplay
          title="Parking and Traffic Analytics"
          timeColumnMap={TIME_COLUMNS}
          valueColumns={VALUE_COLUMNS}
          defaultOrderBy="hourTime"
          maxDecimalPrecision={3}
          updateData={this.updateData}
          chartType="line"
          meanFunction={dataPoints => {
            return (
              dataPoints.reduce((sum, { y: val }) => {
                return sum + val;
              }, 0) / dataPoints.length
            );
          }}
          avgFormatters={{
            'Avg. Park Time': (t: number) => {
              const duration = moment.duration(t, 's');
              return formatDuration(duration);
            },
          }}
          // TODO: put chart options in state
          chartOptions={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  type: 'time',
                  display: true,
                  // time: {
                  //   unit: 'hour', // TODO: modularize
                  // },
                  scaleLabel: {
                    display: false,
                    labelString: 'Date',
                  },
                },
              ],
              yAxes: [
                {
                  id: '# Parks',
                  type: 'linear',
                  display: false,
                },
                {
                  id: '# Vehicles',
                  type: 'linear',
                  display: false,
                },
                {
                  id: 'Avg. Park Time',
                  type: 'linear',
                  display: false,
                },
              ],
            },
          }}
          formatChartData={(timeResolution: string, data: any[]) => {
            const timeColumnName =
              timeResolution === 'hour' ? 'hourTime' : 'dayTime';
            const parkData: [string, Array<{ x: Date; y: number }>] = [
              '# Parks',
              [],
            ];
            const vehicleData: [string, Array<{ x: Date; y: number }>] = [
              '# Vehicles',
              [],
            ];
            const timeData: [string, Array<{ x: Date; y: number }>] = [
              'Avg. Park Time',
              [],
            ];
            each(data, row => {
              const x = new Date(row[timeColumnName]); // TODO: modularize this
              parkData[1].push({ x, y: row.parks });
              vehicleData[1].push({ x, y: row.vehicles });
              timeData[1].push({ x, y: row.avgParkTime });
            });
            return {
              datasets: styleDatasets([parkData, vehicleData, timeData]).map(
                obj => {
                  return { ...obj, yAxisID: obj.label };
                },
              ),
            };
          }}
        />
      </div>
    );
  }
  public renderLander() {
    return (
      <div className="lander">
        <h1>Scratch</h1>
        <p>A simple note taking app</p>
      </div>
    );
  }
  public render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderPage() : this.renderLander()}
      </div>
    );
  }
}
