import React, { FunctionComponent } from 'react';
import { EnforcerInfraction } from '../../containers/EnforcerInfraction';
import { RouteComponentProps } from 'react-router';
import { Route, RouteProps } from 'react-router-dom';

interface URLParams {
  infractionId: string;
}

type SingleInfractionPageProps = RouteComponentProps<URLParams>;

const SingleInfractionPage: FunctionComponent<SingleInfractionPageProps> = ({
  match,
  history,
}) => {
  const { infractionId } = match.params;
  // TODO: get this dynamically
  const location = 'Burns One-Way';

  return <EnforcerInfraction {...{ infractionId, location }} />;
};

export const SingleInfractionRoute = (props: RouteProps) => {
  return (
    <Route
      {...props}
      render={routeProps => <SingleInfractionPage {...routeProps} />}
    />
  );
};

export default SingleInfractionRoute;
