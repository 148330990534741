import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import UnauthenticatedRoute from '../components/UnauthenticatedRoute';
import ChangePassword from '../containers/ChangePassword';
import DeeperInsightsAnalytics from '../containers/DeeperInsightsAnalytics';
import Footage from '../containers/Footage';
import Home from '../containers/Home';
import Login from '../containers/Login';
import NotFound from '../containers/NotFound';
import ParkingAndTrafficAnalytics from '../containers/ParkingAndTrafficAnalytics';
import RequestAnalytics from '../containers/RequestAnalytics';
import UserManagement from '../containers/UserManagement';
import ViolationAnalytics from '../containers/ViolationAnalytics';
import Citations from './citations';
import { PagesProps } from './typings';

export function Routes({ childProps }: PagesProps) {
  const { isAuthenticated } = childProps;
  return (
    <Switch>
      <AuthenticatedRoute
        path="/"
        exact={true}
        component={Home}
        props={childProps}
        {...{ isAuthenticated }}
      />
      <UnauthenticatedRoute
        path="/login"
        exact={true}
        component={Login}
        props={childProps}
      />
      <Citations path="/citations" {...childProps} />
      <AuthenticatedRoute
        path="/analytics/parking-traffic"
        exact={true}
        component={ParkingAndTrafficAnalytics}
        props={childProps}
        isAuthenticated={isAuthenticated}
      />
      <AuthenticatedRoute
        path="/analytics/violations"
        exact={true}
        component={ViolationAnalytics}
        props={childProps}
        isAuthenticated={isAuthenticated}
      />
      <AuthenticatedRoute
        path="/analytics/deeper-insights"
        exact={true}
        component={DeeperInsightsAnalytics}
        props={childProps}
        isAuthenticated={isAuthenticated}
      />
      <AuthenticatedRoute
        path="/change-password"
        exact={true}
        component={ChangePassword}
        props={childProps}
        isAuthenticated={isAuthenticated}
      />
      <AuthenticatedRoute
        path="/admin/user-management"
        exact={true}
        component={UserManagement}
        props={childProps}
        isAuthenticated={isAuthenticated}
      />
      <AuthenticatedRoute
        path="/admin/footage"
        exact={true}
        component={Footage}
        props={childProps}
        isAuthenticated={isAuthenticated}
      />
      <AuthenticatedRoute
        path="/admin/request-analytics"
        exact={true}
        component={RequestAnalytics}
        props={childProps}
        isAuthenticated={isAuthenticated}
      />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
