/**
 * @file Container for violation analytics display
 * @author Harris Lummis
 */
import LinkHeader from 'http-link-header';
import concat from 'lodash/concat';
import each from 'lodash/each';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import qs from 'qs';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AnalyticsDisplay, { UpdateParams } from '../components/AnalyticsDisplay';
import { Column } from '../components/DataTable';
import { ErrorBar } from '../components/errors';
import { retryApi } from '../libs/apiLib';
import {
  formatWithMaxPrecision,
  getRemainingAPIPathsFromLinks,
} from '../libs/formatLib';
import { aggregateChunks } from '../libs/utils';
import BigTableStyle from '../styles/BigTable';
import { styleDatasets } from '../styles/LineGraphs';

export interface ViolationAnalyticsProps extends RouteComponentProps<string> {
  isAuthenticated: boolean;
}

export type ViolationAnalyticsState = Readonly<typeof initialState>;

const initialState = {
  /** An error to display */
  err: undefined as Error | undefined,
};

const TIME_COLUMNS = {
  hour: {
    id: 'hourTime',
    label: 'Hour',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (dayTime: number) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {new Date(dayTime).toLocaleString()}
        </p>
      );
    },
  },
  day: {
    id: 'dayTime',
    label: 'Day',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (dayTime: number) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {new Date(dayTime).toLocaleDateString()}
        </p>
      );
    },
  },
  week: {
    id: 'dayTime',
    label: 'Week',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (dayTime: number) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {new Date(dayTime).toLocaleDateString()}
        </p>
      );
    },
  },
  month: {
    id: 'dayTime',
    label: 'Month',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (dayTime: number) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {new Date(dayTime).toLocaleDateString()}
        </p>
      );
    },
  },
};
const VALUE_COLUMNS: Column[] = [
  {
    id: 'parks',
    label: '# Parks',
    sortable: true,
    disablePadding: false,
    numeric: true,
  },
  {
    id: 'violations',
    label: '# Violations',
    sortable: true,
    disablePadding: false,
    numeric: true,
  },
  {
    id: 'parksPerViolation',
    label: 'Parks Per Violation',
    sortable: true,
    disablePadding: false,
    numeric: true,
    render: (parksPerViolation: number) => {
      return <p>{parksPerViolation.toFixed(3)}</p>;
    },
  },
];

function calculateParksPerViolation(data: any[]) {
  return map(data, row => {
    const parksPerViolation = row.violations ? row.parks / row.violations : 0;
    return { parksPerViolation, ...row };
  });
}

export default class ViolationAnalytics extends React.Component<
  ViolationAnalyticsProps,
  ViolationAnalyticsState
> {
  constructor(props: ViolationAnalyticsProps) {
    super(props);
    this.state = initialState;
  }
  /** Handle an error */
  public handleError = (err: Error) => {
    this.setState({ err });
  };
  /** Handle a press on the error display bar close button */
  public handleErrorBarClose = () => {
    this.setState({ err: undefined });
  };
  /** Handle a press on the error display bar retry button */
  public handleErrorBarRetry = () => {
    window.location.reload();
  };
  public updateData = async (updateParams: UpdateParams) => {
    const { timeRangeResolution, timeRange, order } = updateParams;
    let path = '/analytics/';
    if (timeRangeResolution === 'hour') {
      path += 'hourly?';
    } else {
      path += 'daily?';
    }

    path += qs.stringify({
      timeRange: [timeRange.start, timeRange.end],
      order,
    });
    try {
      const { data, headers } = await retryApi.get('parking', path, {
        response: true,
      });
      const { rows } = data;
      const links = LinkHeader.parse(headers.link);
      const remainingPaths = getRemainingAPIPathsFromLinks(links, 2);

      return Promise.all(
        remainingPaths.map(remainingPath => {
          return retryApi.get('parking', remainingPath, null);
        }),
      ).then(results => {
        const allData = calculateParksPerViolation(
          concat(rows, flatten(map(results, result => result.data.rows))),
        );
        if (timeRangeResolution === 'week') {
          return aggregateChunks(
            allData,
            7,
            ['parksPerViolation'],
            ['parks', 'violations'],
          );
        } else if (timeRangeResolution === 'month') {
          // TODO: actual month length?
          return aggregateChunks(
            allData,
            30,
            ['parksPerViolation'],
            ['parks', 'violations'],
          );
        } else {
          return allData;
        }
      });
    } catch (err) {
      this.handleError(err);
      return []; // TODO: probably want an error
    }
  };
  public renderPage() {
    return (
      <div>
        <ErrorBar
          err={this.state.err}
          onClose={this.handleErrorBarClose}
          onRetry={this.handleErrorBarRetry}
        />
        <AnalyticsDisplay
          title="Violation Analytics"
          timeColumnMap={TIME_COLUMNS}
          valueColumns={VALUE_COLUMNS}
          defaultOrderBy="dayTime"
          defaultOrder="desc"
          defaultTimeRangeName="1M"
          defaultTimeRangeResolution="day"
          maxDecimalPrecision={3}
          updateData={this.updateData}
          chartType="line"
          meanFunction={dataPoints => {
            return (
              dataPoints.reduce((sum, { y: val }) => sum + val, 0) /
              dataPoints.length
            );
          }}
          // TODO: put chart options in state
          chartOptions={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  type: 'time',
                  display: true,
                  time: {
                    unit: 'day', // TODO: modularize
                  },
                  scaleLabel: {
                    display: false,
                    labelString: 'Date',
                  },
                },
              ],
              yAxes: [
                {
                  id: '# Parks',
                  type: 'linear',
                  display: false,
                },
                {
                  id: '# Violations',
                  type: 'linear',
                  display: false,
                },
                {
                  id: 'Parks per Violation',
                  type: 'linear',
                  display: false,
                },
              ],
            },
          }}
          formatChartData={(timeResolution: string, data: any[]) => {
            const timeColumnName =
              timeResolution === 'hour' ? 'hourTime' : 'dayTime';
            const parkData: [string, Array<{ x: Date; y: number }>] = [
              '# Parks',
              [],
            ];
            const violation: [string, Array<{ x: Date; y: number }>] = [
              '# Violations',
              [],
            ];
            const parksPerViolationData: [
              string,
              Array<{ x: Date; y: number }>,
            ] = ['Parks per Violation', []];
            each(data, row => {
              const x = new Date(row[timeColumnName]); // TODO: modularize this
              parkData[1].push({ x, y: row.parks });
              violation[1].push({ x, y: row.violations });
              parksPerViolationData[1].push({
                x,
                y: formatWithMaxPrecision(row.parksPerViolation, 3),
              });
            });
            return {
              datasets: styleDatasets([
                parkData,
                violation,
                parksPerViolationData,
              ]).map(obj => {
                return { ...obj, yAxisID: obj.label };
              }),
            };
          }}
        />
      </div>
    );
  }
  public renderLander() {
    return (
      <div className="lander">
        <h1>Scratch</h1>
        <p>A simple note taking app</p>
      </div>
    );
  }
  public render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderPage() : this.renderLander()}
      </div>
    );
  }
}
