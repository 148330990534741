import _orderBy from 'lodash/orderBy';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import UserManagementContent from './UserManagementContent';

// TODO: disable delete on currently active user
// TODO: make menu slide open to move content to the side rather than resize it
// TODO: disable adding user that already exists
// TODO: make privileges show up correctly
// TODO: parameterize isDemo

export interface UserManagementProps extends RouteComponentProps<string> {
  isAuthenticated: boolean;
}

export default class UserManagement extends React.Component<
  UserManagementProps
> {
  constructor(props: UserManagementProps) {
    super(props);
  }
  public renderLander() {
    return (
      <div className="lander">
        <h1>Automotus Client Portal</h1>
      </div>
    );
  }
  public renderPage() {
    return <UserManagementContent isDemo={true} />;
  }
  public render() {
    return this.props.isAuthenticated ? this.renderPage() : this.renderLander();
  }
}
