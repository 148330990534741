"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @file Definition of the VehicleDetails model
 * @author Harris Lummis
 */

var openalpr_pb_1 = require("@automotus/openalpr-pb");

var mixins_1 = require("@atlas/models/mixins");

var OpenALPR_1 = require("@atlas/models/models/OpenALPR");

var utils_1 = require("@atlas/models/utils");
/** Represents vehicle details results from OpenALPR Cloud API */


var VehicleDetails =
/** @class */
function (_super) {
  __extends(VehicleDetails, _super);
  /**
   * Creates a new instance of the VehicleDetailsObject, converting any plain object
   * properties to models
   * @param props Properties used to create the instance
   */


  function VehicleDetails(props) {
    var _a;

    var _this = _super.call(this) || this;

    if (!props) {
      _a = VehicleDetails.DEFAULTS, _this.color = _a.color, _this.make = _a.make, _this.makeModel = _a.makeModel, _this.bodyType = _a.bodyType;
      return _this;
    }

    _this.color = props.color ? props.color.map(function (c) {
      return c instanceof OpenALPR_1.VehicleCandidate ? c : new OpenALPR_1.VehicleCandidate(c);
    }) : VehicleDetails.DEFAULTS.color;
    _this.make = props.make ? props.make.map(function (c) {
      return c instanceof OpenALPR_1.VehicleCandidate ? c : new OpenALPR_1.VehicleCandidate(c);
    }) : VehicleDetails.DEFAULTS.make;
    _this.makeModel = props.makeModel ? props.makeModel.map(function (c) {
      return c instanceof OpenALPR_1.VehicleCandidate ? c : new OpenALPR_1.VehicleCandidate(c);
    }) : VehicleDetails.DEFAULTS.makeModel;
    _this.bodyType = props.bodyType ? props.bodyType.map(function (c) {
      return c instanceof OpenALPR_1.VehicleCandidate ? c : new OpenALPR_1.VehicleCandidate(c);
    }) : VehicleDetails.DEFAULTS.bodyType;
    return _this;
  }
  /**
   * Parses an object and creates a new instance from it
   * @param obj The object to parse
   * @return A new VehicleDetails instance
   */


  VehicleDetails.FromObject = function (obj) {
    return utils_1.parseObject(this, obj);
  };
  /**
   * Creates a new VehicleDetails instance from a wire-format encoded message
   * @param buf The buffer containing wire-format encoded VehicleDetails
   * @return A new VehicleDetails instance
   */


  VehicleDetails.FromBuffer = function (buf) {
    return utils_1.fromBuffer(this, buf);
  };
  /** Default values of enumerable properties */


  VehicleDetails.DEFAULTS = {
    color: [],
    make: [],
    makeModel: [],
    bodyType: []
  };
  return VehicleDetails;
}(mixins_1.Bufferable(openalpr_pb_1.openalpr.cloud.VehicleDetails));

exports.VehicleDetails = VehicleDetails;
mixins_1.applyMixins(VehicleDetails, mixins_1.Objectable, mixins_1.IsBufferable);
exports.default = VehicleDetails;