import { API } from 'aws-amplify';
import querystring from 'query-string';
import { RetryAPI, RetryAPIInstance } from './utils';

export function declineViolation(violationId: string): Promise<any> {
  return API.put('parking', `/violations/${violationId}/decline`, {});
}

export function verifyViolation(violationId: string): Promise<any> {
  return API.put('parking', `/violations/${violationId}/verify`, {});
}

/** Parameters for getting real time analytics */
interface GetRealTimeAnalyticsParams {
  /** The epoch time (in ms) to use as the fetch time */
  time: number;
  /** The zones to fetch analytics for. Defaults to [] */
  zones?: string[];
}

export const retryApi = RetryAPI({ api: API }) as RetryAPIInstance;

/**
 *
 * @param params Params used to define analytics request
 * @returns A promise that resolves
 */
export function getRealTimeAnalytics(params: GetRealTimeAnalyticsParams) {
  return retryApi.get(
    'parking',
    '/analytics/real-time?' +
      querystring.stringify({
        time: params.time,
        zones: params.zones || [],
      }),
    { response: true },
  );
}
