"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @file Definition of the PlateDetails model
 * @author Harris Lummis
 */

var openalpr_pb_1 = require("@automotus/openalpr-pb");

var mixins_1 = require("@atlas/models/mixins");

var OpenALPR_1 = require("@atlas/models/models/OpenALPR");

var utils_1 = require("@atlas/models/utils");
/** Represents plate details as returned by the OpenALPR Cloud API */


var PlateDetails =
/** @class */
function (_super) {
  __extends(PlateDetails, _super);
  /**
   * Creates a new instance of the PlateDetails model. If any properties can
   * be converted to models, this is done here
   * @param properties Properties used to create the instance
   */


  function PlateDetails(properties) {
    var _this = this; // Construct models from plain objects as necessary


    var _a = properties || {},
        _b = _a.coordinates,
        coordinates = _b === void 0 ? PlateDetails.DEFAULTS.coordinates : _b,
        _c = _a.candidates,
        candidates = _c === void 0 ? PlateDetails.DEFAULTS.candidates : _c,
        _d = _a.vehicleRegion,
        vehicleRegion = _d === void 0 ? PlateDetails.DEFAULTS.vehicleRegion : _d,
        _e = _a.vehicle,
        vehicle = _e === void 0 ? PlateDetails.DEFAULTS.vehicle : _e,
        rest = __rest(_a, ["coordinates", "candidates", "vehicleRegion", "vehicle"]);

    _this = _super.call(this, rest) || this;
    _this.coordinates = coordinates ? coordinates.map(function (c) {
      return c instanceof OpenALPR_1.Coordinate ? c : new OpenALPR_1.Coordinate(c);
    }) : PlateDetails.DEFAULTS.coordinates;
    _this.candidates = candidates ? candidates.map(function (c) {
      return c instanceof OpenALPR_1.PlateCandidate ? c : new OpenALPR_1.PlateCandidate(c);
    }) : PlateDetails.DEFAULTS.candidates;
    _this.vehicleRegion = vehicleRegion instanceof OpenALPR_1.RegionOfInterest ? vehicleRegion : vehicleRegion ? new OpenALPR_1.RegionOfInterest(vehicleRegion) : PlateDetails.DEFAULTS.vehicleRegion;
    _this.vehicle = vehicle instanceof OpenALPR_1.VehicleDetails ? vehicle : vehicle ? new OpenALPR_1.VehicleDetails(vehicle) : PlateDetails.DEFAULTS.vehicle;
    return _this;
  }
  /**
   * Parses an object and creates a new instance from it
   * @param obj The object to parse
   * @return A new PlateDetails instance
   */


  PlateDetails.FromObject = function (obj) {
    return utils_1.parseObject(this, obj);
  };
  /**
   * Creates a new PlateDetails instance from a wire-format encoded message
   * @param buf The buffer containing wire-format encoded PlateDetails
   * @return A new PlateDetails instance
   */


  PlateDetails.FromBuffer = function (buf) {
    return utils_1.fromBuffer(this, buf);
  };
  /** Default values of enumerable properties */


  PlateDetails.DEFAULTS = {
    plate: '',
    matchesTemplate: 0,
    requestedTopn: 0,
    processingTimeMs: 0,
    confidence: 0,
    region: '',
    regionConfidence: 0,
    coordinates: [],
    candidates: [],
    vehicleRegion: null,
    vehicle: null
  };
  return PlateDetails;
}(mixins_1.Bufferable(openalpr_pb_1.openalpr.cloud.PlateDetails));

exports.PlateDetails = PlateDetails;
mixins_1.applyMixins(PlateDetails, mixins_1.Objectable, mixins_1.IsBufferable);
exports.default = PlateDetails;