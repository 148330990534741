/**
 * @file The EnforcerInfractionView container
 */
import React from 'react';
import { Infraction } from '@atlas/models';
import { InfractionInfoTable } from '../../components/InfractionInfoTable';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { Theme } from '@material-ui/core/styles';

/** Props used to create a new EnforcerInfractionView */
export interface EnforcerInfractionViewProps {
  /** Optional classes to apply */
  classes?: ReturnType<typeof useStyles>;
  /** The current screen width (used for media query styling) */
  width: Breakpoint;
  /** The infraction being viewed. */
  infraction: Infraction;
  /** The location where the infraction took place */
  location: string;
  /** URL of an image of the infracting vehicle's license plate */
  plateImgSrc: string;
  /** URL of an image of the infracting vehicle at the time of park */
  parkImgSrc: string;
  /** URL of an image of the infracting vehicle at the time of infraction */
  infractionImgSrc: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    width: '100%',
    height: 'auto',
  },
  typographyContainer: {
    paddingLeft: theme.spacing(1),
  },
  tableContainer: {},
  tablePaper: {},
  imagesContainer: {},
  imageContainer: {},
  imagePaper: {},
}));

/** Component used for viewing an infraction by enforcers */
export const EnforcerInfractionView: React.FunctionComponent<
  EnforcerInfractionViewProps
> = props => {
  const {
    width,
    infraction,
    location,
    plateImgSrc,
    parkImgSrc,
    infractionImgSrc,
  } = props;
  const classes = useStyles(props);

  const imageProps = [
    { title: 'Plate', src: plateImgSrc, alt: 'Plate Image' },
    { title: 'Park', src: parkImgSrc, alt: 'Park Image' },
    { title: 'Infraction', src: infractionImgSrc, alt: 'Infraction Image' },
  ];

  return (
    <Grid container={true} xs={12} spacing={2} direction="row" justify="center">
      <Grid
        className={classes.tableContainer}
        container={true}
        item={true}
        direction="row"
        justify="center"
        xs={12}
      >
        <Paper className={classes.tablePaper}>
          <InfractionInfoTable {...{ infraction, location }} />
        </Paper>
      </Grid>
      <Grid
        className={classes.imagesContainer}
        container={true}
        item={true}
        direction="row"
        justify="center"
        alignItems="center"
        xs={12}
        spacing={2}
      >
        {imageProps.map(({ title, src, alt }) => (
          <Grid
            className={classes.imageContainer}
            item={true}
            container={true}
            xs={12}
            md={6}
            lg={4}
            key={title}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Paper className={classes.imagePaper}>
              <div className={classes.typographyContainer}>
                <Typography
                  variant={isWidthUp('xs', width, false) ? 'h5' : 'h6'}
                >
                  {title}
                </Typography>
              </div>
              <img className={classes.img} src={src} alt={alt} />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default withWidth()(EnforcerInfractionView);
