import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Column, OrderString } from './typings';
import DataTableToolbar from './DataTableToolbar';
import DataTableHead from './DataTableHead';

// TODO: remove hasModal stuff
// TODO: add in optional property for top right component
// TODO: modal paper component

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    table: {
      minWidth: 800,
    },
    tableSmall: {
      minWidth: 400,
    },
    cell: {
      fontSize: 14,
    },
    cellSmall: {
      fontSize: 12,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    loadProgress: {
      color: theme.palette.primary.main,
    },
    loadProgressContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
  });

interface TableProps extends WithStyles<typeof styles> {
  /** The specification of all columns to be displayed in the table */
  columns: Column[];
  /** The data to fill the table with */
  data: any[];
  /** The name of the column to sort by */
  orderBy: string;
  /** The sort order of data in the "orderBy" column */
  order: OrderString;
  /** Display info on hover */
  displayHover: boolean;
  /** Indicates loading status of table */
  isLoading?: boolean;
  /** The title of the table */
  title?: string;
  // Handle a click on a row
  onRowClick?: (event: any, rowIndex: number) => any;
  /**
   * The component title of the table. If defined, will override normal title
   * property
   */
  componentTitle?: JSX.Element;
  /** Whether selection of individual rows is enabled */
  enableSelect: boolean;
  /** Whether selection of all rows with single button press is enabled */
  enableSelectAll: boolean;
  /** Callback to be fired when change pange buttons are clicked */
  handleChangePage?: (event: any, page: number) => any;
  /** Starting number of rows to display on a page of the table */
  rowsPerPage?: number;
  /** Total number of rows in initial data */
  totalRows: number;
  /** Whether or not to wrap table in a paper component. Defaults to true */
  paper?: boolean;
  /** TODO: remove */
  parkData?: number[];
  /** TODO: remove */
  violationData?: number[];
  /** The update data function of the table */
  updateData: (...args: any[]) => any;
  /** Whether or not to display table in small size */
  small?: boolean;
  /** Whether or not to display table header. Defaults to true */
  displayHeader?: boolean;
  /** Whether or not to display table footer. Defaults to true */
  displayFooter?: boolean;
  /**
   * Element/component to display in the top right of the table header.
   * If not set, the filter icon will be displayed
   */
  topRightElement?: JSX.Element;
}

interface TableState {
  order: OrderString;
  orderBy: string;
  selected: any[];
  page: number;
  rowsPerPage: number;
  isLoading: boolean;
}

export class DataTable extends React.Component<TableProps, TableState> {
  constructor(props: TableProps) {
    super(props);

    this.state = {
      order: props.order,
      orderBy: props.orderBy,
      selected: [],
      page: 0,
      rowsPerPage: props.rowsPerPage || 10,
      isLoading: props.isLoading || false,
    };
  }

  public handleRequestSort = async (event: any, property: string) => {
    // TODO: how to handle pagination?
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const { updateData } = this.props;
    this.setState({
      page: 0,
      order: order as OrderString,
      orderBy,
      isLoading: true,
    });
    try {
      await updateData(orderBy, order);
    } catch (err) {
      // TODO: handle error
      alert(err);
    }

    this.setState({ isLoading: false });
  };

  public handleSelectAllClick = (event: any, checked: boolean) => {
    if (checked) {
      this.setState({ selected: this.props.data.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  public handleClick = (event: any, id: any) => {
    event.preventDefault();
    if (!this.props.enableSelect) {
      return;
    }
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  public handleChangePage = async (event: any, page: number) => {
    if (page < 0) {
      return;
    }
    const { data, updateData } = this.props;
    const { rowsPerPage } = this.state;
    if (page * rowsPerPage >= data.length) {
      try {
        this.setState({ isLoading: true });
        await updateData();
        this.setState({
          page,
          isLoading: false,
        });
      } catch (err) {
        alert(err);
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ page });
    }
  };

  public handleChangeRowsPerPage = (event: any) => {
    // Revert to first page to keep weird behavior from being a problem
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  public isSelected = (id: any) => this.state.selected.indexOf(id) !== -1;

  public renderLoading() {
    const { rowsPerPage } = this.state;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          height: 49 * rowsPerPage,
        }}
      >
        <CircularProgress size={120} />
      </div>
    );
  }

  public renderTable() {
    const {
      small = false,
      displayFooter = true,
      classes,
      columns,
      data,
      displayHover,
      enableSelect,
      enableSelectAll,
      onRowClick,
      isLoading,
      totalRows,
    } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;

    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const actualNumColumns = columns.length + (enableSelect ? 1 : 0);

    return (
      <Table className={small ? classes.tableSmall : classes.table}>
        <DataTableHead
          columns={columns}
          enableSelectAll={enableSelectAll}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={this.handleSelectAllClick}
          onRequestSort={this.handleRequestSort}
          rowCount={totalRows}
          small={small}
        />
        <TableBody>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((n, index) => {
              const isSelected = this.isSelected(n.id);
              const rowIndex = page * rowsPerPage + index;
              return (
                <TableRow
                  hover={displayHover}
                  onClick={event =>
                    onRowClick
                      ? onRowClick(event, rowIndex)
                      : this.handleClick(event, n.id)
                  }
                  role="checkbox"
                  aria-checked={isSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isSelected}
                >
                  {enableSelect && (
                    <TableCell padding="checkbox">
                      <Checkbox checked={isSelected} />
                    </TableCell>
                  )}
                  {columns.map(column => {
                    const { id } = column;
                    return (
                      <TableCell
                        key={id}
                        align={column.numeric ? 'right' : 'inherit'}
                        size={small || column.dense ? 'small' : undefined}
                        padding={
                          column.disablePadding ? 'none' : column.paddingType
                        }
                        className={small ? classes.cellSmall : classes.cell}
                      >
                        {column.render ? column.render(n[id], n) : n[id]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
            .concat(
              emptyRows > 0 && displayFooter
                ? [
                    <TableRow
                      key={rowsPerPage}
                      style={{ height: 49 * emptyRows }}
                    >
                      <TableCell colSpan={actualNumColumns} />
                    </TableRow>,
                  ]
                : [],
            )}
        </TableBody>
        {displayFooter && (
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={actualNumColumns}
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  disabled: isLoading || page === 0,
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  disabled:
                    isLoading || page === Math.ceil(totalRows / rowsPerPage),
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    );
  }
  public render() {
    const {
      componentTitle,
      displayHeader = true,
      classes,
      paper = true,
      title,
      isLoading,
      topRightElement,
    } = this.props;
    const { selected } = this.state;

    const tableContent = (
      <div className={classes.root}>
        {displayHeader && (
          <DataTableToolbar
            title={title}
            componentTitle={componentTitle}
            numSelected={selected.length}
            topRightElement={topRightElement}
          />
        )}
        <div className={classes.tableWrapper}>
          {isLoading ? this.renderLoading() : this.renderTable()}
        </div>
      </div>
    );

    return paper ? (
      <Paper className={classes.root}>{tableContent}</Paper>
    ) : (
      tableContent
    );
  }
}

export default withStyles(styles)(DataTable);
