/**
 * @file Definition of the DataTableToolbar component
 * @author Harris Lummis
 */
import React, { FunctionComponent } from 'react';
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingRight: theme.spacing(),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    spacer: {
      flex: '1 1 100%',
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    title: {
      flex: '0 0 auto',
    },
  });

export interface ToolbarProps extends WithStyles<typeof styles> {
  selectable?: boolean;
  numSelected?: number;
  title?: string;
  componentTitle?: JSX.Element;
  topRightElement?: JSX.Element;
}

export const DataTableToolbar: FunctionComponent<ToolbarProps> = ({
  numSelected = 0,
  classes,
  componentTitle,
  title,
  topRightElement,
}) => (
  <Toolbar
    className={classnames(classes.root, {
      [classes.highlight]: numSelected > 0,
    })}
  >
    <div className={classes.title}>
      {numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : componentTitle ? (
        componentTitle
      ) : (
        title && <Typography variant="h6">{title}</Typography>
      )}
    </div>
    <div className={classes.spacer} />
    <div className={classes.actions}>
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        topRightElement || (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )
      )}
    </div>
  </Toolbar>
);

export default withStyles(styles)(DataTableToolbar);
