import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Theme,
  withStyles,
  createStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ChartData } from 'chart.js';
import classnames from 'classnames';
import mean from 'lodash/mean';
import React, { FunctionComponent } from 'react';
import ChartComponent, { ChartComponentProps } from 'react-chartjs-2';

const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      display: 'flex' as 'flex',
      flexDirection: 'row' as 'row',
      // padding: theme.spacing(),
      // marginTop: theme.spacing(),
      // marginBottom: theme.spacing(),
      height: 360,
      maxHeight: 500,
      overflow: 'auto' as 'auto',
    },
    chartContainer: {
      position: 'relative' as 'relative',
      height: '95%',
      width: '95%',
      padding: theme.spacing(),
      margin: 'auto' as 'auto',
      display: 'flex' as 'flex',
      flexDirection: 'column' as 'column',
      justifyContent: 'center' as 'center',
      alignContent: 'center' as 'center',
      alignItems: 'center' as 'center',
    },
    chartAndTitleContainer: {
      display: 'flex' as 'flex',
      flexDirection: 'column' as 'column',
      justifyContent: 'space-between' as 'space-between',
      width: '80%',
      flexGrow: 4,
    },
    loaderContainer: {
      display: 'flex' as 'flex',
      justifyContent: 'center' as 'center',
      alignItems: 'center' as 'center',
      alignContent: 'center' as 'center',
    },
    title: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    statsContainer: {
      width: '20%',
      flexGrow: 1,
      flexShrink: 0,
      padding: theme.spacing(),
      borderLeft: `1px solid ${theme.palette.grey['300']}`,
    },
    statsContentContainer: {
      paddingLeft: theme.spacing(),
      display: 'flex' as 'flex',
      flexDirection: 'row' as 'row',
      justifyContent: 'space-between' as 'space-between',
    },
    statsContentSpacer: {
      minWidth: theme.spacing(),
    },
    statsLabelContainer: {
      textAlign: 'left' as 'left',
      padding: theme.spacing(),
    },
    statsValueContainer: {
      textAlign: 'right' as 'right',
      padding: theme.spacing(),
    },
  });

/** Properties for the Chart component */
export interface ChartWithAveragesProps
  extends ChartComponentProps,
    WithStyles<typeof styles> {
  /** The chart data to include. See react-chartjs-2 docs for more info */
  data: ChartData;
  /**
   * The title of the display. If undefined, no title will display, unless
   * componentTitle is defined.
   */
  title?: string;
  /**
   * A component-format display title. Will override the title specified as a
   * string, if one exists.
   */
  componentTitle?: JSX.Element;
  /** Whether or not the chart is loading */
  isLoading: boolean;
  /** The maximum decimal precision of displayed averages. Defaults to 3. */
  maxDecimals?: number;
  /**
   * The function used to calculate mean values to display averages. Defaults
   * to the lodash mean function.
   */
  meanFunction?: (data: any[]) => number;
  /**
   * An object specifying how to format the average from each dataset. Defaults
   * to the empty object
   */
  avgFormatters?: {
    [dataset: string]: (value: number) => any;
  };
  /** Whether or not to display averages of each dataset. Defaults to true. */
  displayAverages?: boolean;
}

const ChartWithAverages: FunctionComponent<ChartWithAveragesProps> = props => {
  const {
    classes,
    isLoading,
    componentTitle,
    title,
    maxDecimals = 3,
    meanFunction = mean,
    avgFormatters = {},
    displayAverages = true,
    ...rest
  } = props;

  const {
    data,
    type,
    getDatasetAtEvent,
    getElementAtEvent,
    getElementsAtEvent,
    height,
    legend,
    onElementsClick, // alias for getElementsAtEvent (backward compatibility)
    options,
    redraw,
    width,
    ...containerProps
  } = rest;

  const chartProps = {
    data,
    type,
    getDatasetAtEvent,
    getElementAtEvent,
    getElementsAtEvent,
    height,
    legend,
    onElementsClick, // alias for getElementsAtEvent (backward compatibility)
    options,
    redraw,
    width,
  };

  return (
    <div className={classes.mainContainer} {...containerProps}>
      <div className={classes.chartAndTitleContainer}>
        {componentTitle
          ? componentTitle
          : title && (
              <Typography variant="h4" className={classes.title}>
                {title}
              </Typography>
            )}
        <div
          className={classnames(classes.chartContainer, {
            [classes.loaderContainer]: isLoading,
          })}
        >
          {isLoading ? (
            <CircularProgress size={50} />
          ) : (
            <ChartComponent {...chartProps} />
          )}
        </div>
      </div>
      {displayAverages && (
        <div className={classes.statsContainer}>
          <Typography variant="h6">Averages</Typography>
          <div className={classes.statsContentContainer}>
            <div>
              {chartProps.data.datasets!.map(({ label }) => {
                return (
                  <div key={label} className={classes.statsLabelContainer}>
                    <Typography>{label}</Typography>
                  </div>
                );
              })}
            </div>
            <div className={classes.statsContentSpacer} />
            <div>
              {chartProps.data.datasets!.map(({ label, data: chartData }) => {
                const avgValue = meanFunction(chartData || []);
                return (
                  <div className={classes.statsValueContainer} key={label}>
                    {isLoading ? (
                      <CircularProgress size={14} />
                    ) : (
                      <Typography variant="body1">
                        {label && avgFormatters[label]
                          ? avgFormatters[label](avgValue)
                          : avgValue.toFixed(maxDecimals)}
                      </Typography>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ChartWithAverages);
