import stampit from 'stampit';
import { AuthClass } from 'aws-amplify';
import { RetryAPIInstance } from '../libs/utils';
import qs from 'querystring';

/** A factory function for creating objects with auth */
export const HasAuth = stampit().init(function(
  this: any,
  { Auth }: { Auth: AuthClass },
) {
  this.Auth = Auth;
});

export interface RequestParams {
  /** The method used to make the request. Defaults to 'get' */
  method?: string;
  /** The endpoint to hit with the request */
  path: string;
  /** The query parameters to use */
  queryParams?: any;
  /** The body parameters to use */
  body?: any;
  /** The request headers */
  headers?: any;
  /** Whether or not to return the full response. Defaults to true. */
  response?: boolean;
}

export interface HasAPIInstance {
  /** The API instance used for making requests */
  API: RetryAPIInstance;
  /** The name of the api to request on */
  apiName: string;
  /**
   * Make an API request
   * @param params The parameters used to make a request
   * @returns A promise that resolves to the response of the request
   */
  request: (params: RequestParams) => PromiseLike<any>;
}

export type HasAPIStamp = ({
  apiName,
  API,
}: {
  apiName: string;
  API: RetryAPIInstance;
}) => HasAPIInstance;

/** A factory function for creating objects with API */
export const HasAPI = stampit()
  .init(function(
    this: any,
    { apiName, API }: { apiName: string; API: RetryAPIInstance },
  ) {
    this.API = API;
    this.apiName = apiName;
  })
  .methods({
    request<TInstance extends HasAPIInstance>(
      this: TInstance,
      params: RequestParams,
    ) {
      const {
        method = 'get',
        path,
        queryParams,
        body,
        headers,
        response = true,
      } = params;
      let fullEndpoint = path;
      if (queryParams) {
        fullEndpoint += '?' + qs.stringify(queryParams);
      }
      return this.API[method.toLowerCase()](this.apiName, fullEndpoint, {
        body,
        headers,
        response,
      });
    },
  });
