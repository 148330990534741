/**
 * @file Definition of the DataTableHead component
 * @author Harris Lummis
 */
import React, { FunctionComponent } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import { Column, OnRequestSort } from '../typings';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const styles = () =>
  createStyles({
    labelCell: {
      fontSize: '14px',
    },
  });

interface DataTableHeadProps extends WithStyles<typeof styles> {
  /** Small table format */
  small?: boolean;
  /** The column specs of the table */
  columns: Column[];
  /** Indicates whether individual selection is enabled */
  enableSelect?: boolean;
  /** Indicates whether selecting all rows is an allowed operation */
  enableSelectAll?: boolean;
  /** The number of selected rows */
  numSelected?: number;
  /** The total number of rows */
  rowCount: number;
  /** The sort order */
  order?: 'asc' | 'desc';
  /** The id of the column to sort by */
  orderBy?: string;
  /** The sorting function */
  onRequestSort?: OnRequestSort;
  /** The selectAll handler */
  onSelectAllClick?: (event: any, checked: boolean) => any;
}

export const DataTableHead: FunctionComponent<DataTableHeadProps> = props => {
  const {
    classes,
    columns,
    enableSelect,
    enableSelectAll,
    onSelectAllClick,
    order,
    orderBy,
    onRequestSort,
    numSelected,
    rowCount,
    small = false,
  } = props;

  const createSortHandler = (property: string) => (event: any) => {
    if (onRequestSort) {
      return onRequestSort(event, property);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {enableSelect && (
          <TableCell padding="checkbox">
            {enableSelectAll && (
              <Checkbox
                indeterminate={
                  (numSelected as number) > 0 &&
                  (numSelected as number) < rowCount
                }
                checked={(numSelected as number) === rowCount}
                onChange={onSelectAllClick}
              />
            )}
          </TableCell>
        )}
        {columns.map(column => {
          return (
            <TableCell
              key={column.id}
              align={column.numeric ? 'right' : 'inherit'}
              size={small ? 'small' : undefined}
              padding={column.disablePadding ? 'none' : column.paddingType}
              sortDirection={orderBy === column.id ? order : false}
              className={classes.labelCell}
            >
              <Tooltip
                title="Sort"
                placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={order}
                  onClick={
                    column.sortable ? createSortHandler(column.id) : () => null
                  }
                >
                  {column.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default withStyles(styles)(DataTableHead);
