/**
 * @file Defintion of the UserModal component
 * @author Harris Lummis
 */
import React from 'react';
import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
} from '@material-ui/core/styles';
import {
  CreateUserParams,
  DeleteUserParams,
} from '../../controllers/userManagement';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import { User } from '../../models/user';

const styles = (theme: Theme) =>
  createStyles({
    deleteButton: {
      color: red[500],
      border: `2px solid ${red[500]}`,
      '&:hover': {
        backgroundColor: red[500],
        color: theme.palette.getContrastText(red[500]),
      },
    },
    main: {
      margin: 'auto',
      marginTop: '25%',
    },
    title: {
      height: '2em',
      padding: theme.spacing(),
      paddingLeft: theme.spacing(2),
      paddingRight: 0,
      backgroundColor: theme.palette.primary.main,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
    },
    titleText: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    row: {
      padding: theme.spacing(),
    },
    column: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
    columnLeft: {
      textAlign: 'left',
    },
    columnRight: {
      textAlign: 'right',
    },
    contentContainer: {
      paddingLeft: '10%',
      paddingRight: '10%',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    content: {
      paddingTop: 'inherit',
      paddingBottom: 'inherit',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    iconButton: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    confirmDeleteModal: {
      marginTop: '45%',
    },
    confirmDeleteModalContent: {
      padding: theme.spacing(2),
    },
    confirmDeleteForm: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignContent: 'center',
    },
  });

/** Props for the user modal popup */
export interface UserModalProps extends WithStyles<typeof styles> {
  /** Indicates whether or not the modal display is open */
  isOpen: boolean;
  /** The user to display in the modal, or null if no user has been selected */
  user: User;
  /** The update handler */
  onUpdate: (params: CreateUserParams) => any;
  /** The delete handler */
  onInitiateDelete: (params: DeleteUserParams) => PromiseLike<any>;
  onFinalizeDelete: () => PromiseLike<any>;
  /** Close handler */
  onClose: (event: any) => any;
}

/** State of the User Modal Popup */
export interface UserModalState {
  /** The modified privilege level value. Deafults to initial privilegeLevel
   * of user
   */
  privilegeLevel: string;
  /**
   * Indicates whether or not the user's attributes have changed. Defaults to
   * false
   */
  hasChanged: boolean;
  /** Indicates whether confirm deletion modal is opened. Defaults to false */
  isConfirmDeleteOpen: boolean;
  /** Indicates whether delete operation is in progress. Defaults to false */
  isDeleting: boolean;
  /** Indicates whether update operation is in progress. Defaults to false */
  isUpdating: boolean;
  confirmDeleteText: string;
  hasDeleted: boolean;
}

export class UserModal extends React.Component<UserModalProps, UserModalState> {
  constructor(props: UserModalProps) {
    super(props);
    // Set default value for privilegeLevel if user is null
    const { privilegeLevel = '' } = props.user || {};
    this.state = {
      privilegeLevel,
      hasChanged: false,
      isConfirmDeleteOpen: false,
      isDeleting: false,
      isUpdating: false,
      confirmDeleteText: '',
      hasDeleted: false,
    };
  }
  public handleDelete = async (event: any) => {
    event.preventDefault();
    const { user, onInitiateDelete, onFinalizeDelete } = this.props;
    this.setState({ isDeleting: true });
    try {
      await onInitiateDelete({ email: user.username });
      this.setState({
        isDeleting: false,
        isConfirmDeleteOpen: false,
      });
      onFinalizeDelete();
    } catch (err) {
      alert(err);
    }
  };
  public handleDeleteClick = () => {
    this.setState({ isConfirmDeleteOpen: true });
  };
  public handleConfirmDeleteModalClose = () => {
    this.setState({ isConfirmDeleteOpen: false });
  };
  public handleConfirmDeleteTextChange = (event: any) => {
    this.setState({
      confirmDeleteText: event.target.value as string,
    });
  };
  public validateConfirmDeleteForm() {
    const {
      user: { username },
    } = this.props;
    const { confirmDeleteText } = this.state;

    return confirmDeleteText === username;
  }
  public render() {
    const { classes, isOpen, onClose, user } = this.props;
    const {
      hasDeleted,
      isConfirmDeleteOpen,
      isDeleting,
      confirmDeleteText,
    } = this.state;
    const userPropsDisplaySpec = [
      { id: 'firstName', label: 'First Name' },
      { id: 'lastName', label: 'Last Name' },
      { id: 'username', label: 'Email' },
      { id: 'privilegeLevel', label: 'Privileges' },
      {
        id: 'createdAt',
        label: 'Created At',
        render(timestamp: number) {
          return (
            <Typography>{new Date(timestamp).toLocaleString()}</Typography>
          );
        },
      },
      {
        id: 'updatedAt',
        label: 'Updated At',
        render(timestamp: number) {
          return (
            <Typography>{new Date(timestamp).toLocaleString()}</Typography>
          );
        },
      },
    ];
    const modalContent = (
      <Grid container={true} direction="row" justify="center">
        <Grid item={true} xs={12} sm={6} md={4} xl={3}>
          <Paper className={classes.main}>
            <div>
              <div className={classes.title}>
                <Typography className={classes.titleText} variant="h6">
                  User Editor
                </Typography>
                <IconButton className={classes.iconButton} onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className={classes.contentContainer}>
                <div className={classes.content}>
                  <div
                    className={classnames(classes.column, classes.columnLeft)}
                  >
                    {userPropsDisplaySpec.map(({ label }) => {
                      return (
                        <div key={label} className={classes.row}>
                          <Typography>{label}</Typography>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className={classnames(classes.column, classes.columnRight)}
                  >
                    {userPropsDisplaySpec.map(({ id, render }) => {
                      return (
                        <div key={id} className={classes.row}>
                          {render ? (
                            render(user[id])
                          ) : (
                            <Typography>{user[id]}</Typography>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={classes.content}>
                  <Button
                    className={classes.deleteButton}
                    onClick={this.handleDeleteClick}
                  >
                    Delete User
                  </Button>
                </div>
              </div>
            </div>
            <Modal open={isConfirmDeleteOpen}>
              <Grid container={true} direction="row" justify="center">
                <Grid item={true} xs={11} sm={6} md={4} lg={3}>
                  <Paper className={classes.confirmDeleteModal}>
                    <div className={classes.title}>
                      <Typography className={classes.titleText} variant="h6">
                        Delete User
                      </Typography>
                      <IconButton
                        className={classes.iconButton}
                        onClick={this.handleConfirmDeleteModalClose}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div className={classes.confirmDeleteModalContent}>
                      <Typography gutterBottom={true}>
                        Are you sure you want to delete user with email{' '}
                        <strong>{user.username}</strong>?
                      </Typography>
                      <Typography>
                        To delete this user, type their email and confirm.
                      </Typography>
                      <form onSubmit={this.handleDelete}>
                        <div className={classes.confirmDeleteForm}>
                          <TextField
                            autoFocus={true}
                            id="confirmDeleteText"
                            value={confirmDeleteText}
                            margin="normal"
                            onChange={this.handleConfirmDeleteTextChange}
                          />
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={
                              !this.validateConfirmDeleteForm() || isDeleting
                            }
                          >
                            Confirm
                          </Button>
                        </div>
                      </form>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Modal>
          </Paper>
        </Grid>
      </Grid>
    );
    return <Modal open={isOpen && !hasDeleted}>{modalContent}</Modal>;
  }
}

export default withStyles(styles)(UserModal);
