"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @file Definition of the PlateCandidate model
 * @author Harris Lummis
 */

var openalpr_pb_1 = require("@automotus/openalpr-pb");

var mixins_1 = require("@atlas/models/mixins");

var utils_1 = require("@atlas/models/utils");
/** Represents a plate result candidate returned from the OpenALPR Cloud API */


var PlateCandidate =
/** @class */
function (_super) {
  __extends(PlateCandidate, _super);

  function PlateCandidate() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * Parses an object and creates a new instance from it
   * @param obj The object to parse
   * @return A new PlateCandidate instance
   */


  PlateCandidate.FromObject = function (obj) {
    return utils_1.parseObject(this, obj);
  };
  /**
   * Creates a new PlateCandidate instance from a wire-format encoded buffer
   * @param buf The buffer holding the encoded message
   * @return A new PlateCandidate instance
   */


  PlateCandidate.FromBuffer = function (buf) {
    return utils_1.fromBuffer(this, buf);
  };
  /** Default values of enumerable properties */


  PlateCandidate.DEFAULTS = {
    plate: '',
    confidence: 0,
    matchesTemplate: 0
  };
  return PlateCandidate;
}(mixins_1.Bufferable(openalpr_pb_1.openalpr.cloud.PlateCandidate));

exports.PlateCandidate = PlateCandidate;
mixins_1.applyMixins(PlateCandidate, mixins_1.Objectable, mixins_1.IsBufferable);
exports.default = PlateCandidate;