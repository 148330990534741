/**
 * @file Definition of the UserManagementContent component
 * @author Harris Lummis
 */
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import {
  UserManagementControllerInstance,
  UserManagementController,
  DeleteUserParams,
  UpdateUserParams,
} from '../../controllers/userManagement';
import { RetryAPI } from '../../libs/utils';
import { HasAdminMocks } from '../../libs/demoApi';
import User from '../../models/user';
import DataTable, { OrderString, Column } from '../../components/DataTable';
import config from '../../config';
import flatten from 'lodash/flatten';
import _orderBy from 'lodash/orderBy';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import UserModal from '../../components/UserModal';
import Modal from '@material-ui/core/Modal';
import NewUserDisplay from '../../components/NewUserDisplay';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const styles = () =>
  createStyles({
    root: {
      width: '100%',
    },
  });

export interface UserManagementContentProps extends WithStyles<typeof styles> {
  /** Indicates whether demo is active or not */
  isDemo: boolean;
}

export interface UserManagementContentState {
  /** First name of the new user to create */
  newUserFirstName: string;
  /** Last name of the new user to create */
  newUserLastName: string;
  /** Email of the new user to create */
  newUserEmail: string;
  /** Privilege Level of the new user to create */
  newUserPrivilegeLevel: string;
  /** Indicates whether or not the page is loading. Defaults to true */
  isLoading: boolean;
  /** Indicates whether or not the User modal is open. Defaults to false. */
  isModalOpen: boolean;
  /** The current array of users to display. Defaults to [] */
  users: User[];
  /** The default column to order by. Defaults to 'lastName' */
  orderBy: string;
  /** The default order. Defaults to 'asc' */
  order: OrderString;
  /** The total number of items. Defaults to 0. */
  totalCount: number;
  /** Whether or not the table is loading. Defaults to false. */
  tableLoading: boolean;
  /** The currently selected user. Defaults to null. */
  selectedUser: User | null;
  /** Whether or not the new user modal is open */
  isNewUserModalOpen: boolean;
  /** Indicates whether new user is activley being added */
  isAddingNewUser: boolean;
}

/** Definitions for table columns */
const TABLE_COLUMNS: Column[] = [
  {
    id: 'firstName',
    label: 'First Name',
    sortable: true,
    disablePadding: false,
    numeric: false,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    sortable: true,
    disablePadding: false,
    numeric: false,
  },
  {
    id: 'username',
    label: 'Email',
    sortable: true,
    disablePadding: false,
    numeric: false,
  },
  {
    id: 'privilegeLevel',
    label: 'Privileges',
    sortable: true,
    disablePadding: false,
    numeric: false,
  },
];

export class UserManagementContent extends React.Component<
  UserManagementContentProps,
  UserManagementContentState
> {
  /** The User Management API Controller */
  public userManagementController: UserManagementControllerInstance;
  constructor(props: UserManagementContentProps) {
    super(props);
    this.userManagementController = UserManagementController({
      apiName: 'parking',
      // API called by controller depends on whether or not use is in demo
      API: RetryAPI({ api: HasAdminMocks() }),
    });
    this.state = {
      isLoading: true,
      isModalOpen: false,
      users: [],
      orderBy: 'lastName',
      order: 'asc',
      totalCount: 0,
      tableLoading: false,
      selectedUser: null,
      isNewUserModalOpen: false,
      newUserFirstName: '',
      newUserLastName: '',
      newUserEmail: '',
      newUserPrivilegeLevel: '',
      isAddingNewUser: false,
    };
  }

  /** Update data on mount */
  public async componentDidMount() {
    try {
      this.userManagementController.API.api.setDefaults();
      await this.getAllUsers();
    } catch (err) {
      alert(err);
    }
  }

  /** Handle a click on a table row */
  public handleRowClick = (event: any, rowIndex: number) => {
    const { users } = this.state;
    this.setState({
      isModalOpen: true,
      selectedUser: users[rowIndex],
    });
  };

  public handleModalClose = () => {
    this.setState({ isModalOpen: false, selectedUser: null });
  };

  public handleInitiateDelete = async (params: DeleteUserParams) => {
    try {
      await this.deleteUser(params);
    } catch (err) {
      alert(err);
    }
  };
  public handleFinalizeDelete = async () => {
    this.setState({
      isModalOpen: false,
      selectedUser: null,
    });
    try {
      await this.getAllUsers();
    } catch (err) {
      alert(err);
    }
    this.setState({
      tableLoading: false,
    });
  };

  public handleNewUserOpen = () => {
    this.setState({
      isNewUserModalOpen: true,
    });
  };

  public handleNewUserClose = () => {
    this.setState({
      isNewUserModalOpen: false,
    });
  };

  public handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [event.target.name as 'newUserEmail']: event.target.value, // TODO: Fix this
    });
  };

  public handleNewUserSubmit = async (event: any) => {
    event.preventDefault();
    const {
      newUserFirstName,
      newUserLastName,
      newUserEmail,
      newUserPrivilegeLevel,
    } = this.state;

    try {
      await this.userManagementController.createUser({
        firstName: newUserFirstName,
        lastName: newUserLastName,
        email: newUserEmail,
        privilegeLevel: newUserPrivilegeLevel,
      });

      this.setState({
        newUserFirstName: '',
        newUserLastName: '',
        newUserEmail: '',
        newUserPrivilegeLevel: '',
        isNewUserModalOpen: false,
      });

      await this.getAllUsers();
    } catch (err) {
      alert(err);
    }
  };
  /**
   * Delete a user but do nothing to change the users array, have to handle
   * this later else user editor will display the wrong user
   */
  public deleteUser = async (params: DeleteUserParams) => {
    try {
      await this.userManagementController.deleteUser(params);
    } catch (err) {
      alert(err);
    }
  };

  public validateNewUserForm = () => {
    const {
      newUserFirstName,
      newUserLastName,
      newUserEmail,
      newUserPrivilegeLevel,
    } = this.state;

    // TODO: actually validate email
    return (
      !!newUserFirstName &&
      !!newUserLastName &&
      !!newUserEmail &&
      !!newUserPrivilegeLevel
    );
  };
  /**
   * Get users at all privilege levels and update the state to reflect the
   * get
   */
  public async getAllUsers() {
    const { order, orderBy } = this.state;
    this.setState({
      tableLoading: true,
    });
    try {
      const rawUsers = flatten(
        await Promise.all([
          this.userManagementController.listUsersInGroup({
            groupName: 'admin',
            userPoolId: config.cognito.USER_POOL_ID,
          }),
          this.userManagementController.listUsersInGroup({
            groupName: 'normal',
            userPoolId: config.cognito.USER_POOL_ID,
          }),
        ]),
      ).map(rawUserResponse => rawUserResponse.data);

      // Sort the users array
      const users = _orderBy(
        flatten(rawUsers).map(raw => User.FromRaw(raw)),
        [orderBy],
        [order],
      );

      this.setState({
        users,
        totalCount: users.length,
      });
    } catch (err) {
      alert(err);
    }
    this.setState({
      tableLoading: false,
    });
  }

  /** Render page content */
  public render() {
    const {
      isModalOpen,
      tableLoading,
      users,
      order,
      orderBy,
      selectedUser,
      isNewUserModalOpen,
      newUserFirstName,
      newUserLastName,
      newUserEmail,
      newUserPrivilegeLevel,
      isAddingNewUser,
    } = this.state;

    const addButton = (
      <Tooltip title="Add user">
        <IconButton aria-label="Add user" onClick={this.handleNewUserOpen}>
          <PersonAddIcon />
        </IconButton>
      </Tooltip>
    );

    return (
      <Grid container={true} direction="row" justify="center">
        <Grid item={true} xs={12} sm={10} lg={8} xl={6}>
          <DataTable
            title="Users"
            columns={TABLE_COLUMNS}
            data={users}
            displayHover={true}
            orderBy={orderBy}
            order={order}
            enableSelect={false}
            enableSelectAll={false}
            onRowClick={this.handleRowClick}
            isLoading={tableLoading}
            updateData={(sortBy?: string, sort?: OrderString) => {
              this.getAllUsers();
            }}
            totalRows={this.state.totalCount}
            topRightElement={addButton}
          />
          {selectedUser && (
            <UserModal
              isOpen={isModalOpen}
              onClose={this.handleModalClose}
              onUpdate={(params: UpdateUserParams) => null}
              onInitiateDelete={this.handleInitiateDelete}
              onFinalizeDelete={this.handleFinalizeDelete}
              user={selectedUser}
            />
          )}
          <Modal open={isNewUserModalOpen}>
            <NewUserDisplay
              newUserFirstName={newUserFirstName}
              newUserLastName={newUserLastName}
              newUserEmail={newUserEmail}
              newUserPrivilegeLevel={newUserPrivilegeLevel}
              isAddingNewUser={isAddingNewUser}
              onClose={this.handleNewUserClose}
              validateForm={this.validateNewUserForm}
              onSubmit={this.handleNewUserSubmit}
              onChange={this.handleFormChange}
            />
          </Modal>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(UserManagementContent);
