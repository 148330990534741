import { HasAPI, HasAPIInstance } from '../stamps/aws';
import { RetryAPIInstance } from '../libs/utils';
import { FullResponse } from '../libs/awsLib';

/** Parameters used to create a user */
export interface CreateUserParams {
  /** The user's email */
  email: string;
  /** The user's first name */
  firstName: string;
  /** The user's last name */
  lastName: string;
  /** The user's privilege level */
  privilegeLevel: string;
}

/** Parameters used to delete a user */
export interface DeleteUserParams {
  /** The user's email */
  email: string;
}

/** Parameters used to update a user */
export interface UpdateUserParams {
  /** The user's email */
  email: string;
  /** The user's privilege level */
  privilegeLevel: string;
}

/** Parameters used to list users from a particular group */
export interface ListUsersInGroupParams {
  /** The name of the group to list from */
  groupName: string;
  /**
   * The number of users to retrieve in a single call. Defaults to 50. Min value
   * of 0, max value of 60.
   */
  limit?: number;
  /**
   * An identifier from the previous request that can be used to return the next
   * set of items in the list
   */
  nextToken?: string;
  /** The user pool id of the user pool to list from */
  userPoolId: string;
}

/** A string indicating user status */
export type UserStatus =
  | 'UNCONFIRMED'
  | 'CONFIRMED'
  | 'ARCHIVED'
  | 'COMPROMISED'
  | 'UNKNOWN'
  | 'RESET_REQUIRED'
  | 'FORCE_CHANGE_PASSWORD';

export interface UserResponseBody {
  /** A container with information about user type attributes */
  Attributes: Array<{ Name: string; Value: string }>;
  /** Specifies whether user is enabled */
  Enabled: boolean;
  /** The MFA options for the user */
  MFAOptions: { AttributeName: string; DeliveryMedium: string };
  /** The creation timestamp of the user */
  UserCreateDate: number;
  /** The last modified timestamp of the user */
  UserLastModifiedDate: number;
  /** The user name of the user */
  Username: string;
  /** The user status */
  UserStatus: UserStatus;
}

/** The response body of the ListUsersInGroup call */
export interface ListUsersInGroupResponseBody {
  NextToken: string;
  Users: UserResponseBody[];
}

export interface UserManagementControllerInstance extends HasAPIInstance {
  /**
   * Create a new user
   * @param params The parameters used to create the user
   * @returns A promise that resolves to the newly created user
   */
  createUser(
    params: CreateUserParams,
  ): PromiseLike<FullResponse<UserResponseBody>>;
  /**
   * Delete a user
   * @param params The parameters used to delete the user
   * @returns A promise that resolves to the deleted user
   */
  deleteUser(
    params: DeleteUserParams,
  ): PromiseLike<FullResponse<UserResponseBody>>;
  /**
   * List users
   * @param params The parameters used to list users
   * @returns A promise that resolves to the API response
   */
  listUsersInGroup(
    params: ListUsersInGroupParams,
  ): PromiseLike<FullResponse<UserResponseBody[]>>;
  /**
   * Update a user
   * @param params The parameters used to update the user
   * @returns A promise that resolves to the updated user
   */
  updateUser(
    params: UpdateUserParams,
  ): PromiseLike<FullResponse<UserResponseBody>>;
}

export type UserManagementControllerStamp = ({
  apiName,
  API,
}: {
  apiName: string;
  API: RetryAPIInstance;
}) => UserManagementControllerInstance;

export const UserManagementController = HasAPI.methods({
  createUser(this: UserManagementControllerInstance, params: CreateUserParams) {
    return this.request({
      method: 'post',
      path: '/admin/user',
      body: params,
    });
  },
  deleteUser(this: UserManagementControllerInstance, params: DeleteUserParams) {
    return this.request({
      method: 'delete',
      path: '/admin/user',
      body: params,
    });
  },
  updateUser(this: UserManagementControllerInstance, params: UpdateUserParams) {
    return this.request({
      method: 'put',
      path: '/admin/user',
      body: params,
    });
  },
  listUsersInGroup(
    this: UserManagementControllerInstance,
    params: ListUsersInGroupParams,
  ) {
    const { groupName, userPoolId, limit = 50, nextToken } = params;
    return this.request({
      method: 'get',
      path: '/admin/users',
      queryParams: {
        groupName,
        userPoolId,
        limit,
        nextToken,
      },
    });
  },
}) as UserManagementControllerStamp;
