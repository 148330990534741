"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Determines if an value is bufferable
 * @param val The value to check
 * @return True if the value is bufferable
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any

function isBufferable(val) {
  return typeof val === 'object' && val !== null && !!val.isBufferable;
}

exports.isBufferable = isBufferable;
/**
 * Creates a mixin class that adds buffer functionality to the
 * base class
 * @param base base class to mixin
 * @return A new class with the ability to create instances from
 * buffers and create buffers from instances
 */

function Bufferable(base) {
  return (
    /** @class */
    function (_super) {
      __extends(BufferableMixin, _super);

      function BufferableMixin() {
        return _super !== null && _super.apply(this, arguments) || this;
      }
      /**
       * Encodes the instance in wire-format
       * @return A buffer containing the RegionOfInterest in wire-format
       */


      BufferableMixin.prototype.toBuffer = function () {
        return base.encode(this).finish();
      };

      return BufferableMixin;
    }(base)
  );
}

exports.Bufferable = Bufferable;
/** A prototype for indicating bufferability */

var IsBufferable =
/** @class */
function () {
  function IsBufferable() {
    /** Provides a means of ensuring that an instance is bufferable */
    this.isBufferable = true;
  }

  return IsBufferable;
}();

exports.IsBufferable = IsBufferable;
exports.default = Bufferable;