"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Decodes a wire-format buffer as an instance of the provided class
 * @param Class The class used to decode the buffer
 * @param buffer The buffer to decode
 * @return A new instance of the provided class
 */

function fromBuffer(Class, buffer) {
  return new Class(Class.decode(buffer));
}

exports.fromBuffer = fromBuffer;
exports.default = fromBuffer;