/**
 * @file A list item that can be expanded and collapsed
 * @author Harris Lummis
 */
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { FunctionComponent } from 'react';

/** Properties used to create a collablsible list item */
export interface CollapsibleListItemProps {
  /** The icon to display for the list item */
  icon?: JSX.Element;
  /** The title of the item */
  title: string;
  /** Indicates expansion status of list item */
  expanded: boolean;
  /** Handle a click on the main item */
  onClick?: (event: any) => any;
  /** Optional override properties for the ListItem component */
  listItemProps?: Partial<Omit<ListItemProps<'div'>, 'button' | 'onClick'>>;
}

export const CollapsibleListItem: FunctionComponent<
  CollapsibleListItemProps
> = props => {
  const { icon, title, expanded, children, onClick, listItemProps } = props;

  return (
    <div>
      <ListItem button={true} onClick={onClick} {...listItemProps}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText inset={!icon} primary={title} />
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
        <List disablePadding={true}>{children}</List>
      </Collapse>
    </div>
  );
};

export default CollapsibleListItem;
