"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * An error thrown when a method is called prior to initialization on an
 * API Client
 */

var ClientUninitializedError =
/** @class */
function (_super) {
  __extends(ClientUninitializedError, _super);
  /**
   * Creates a new ClientUninitializedError
   * @param methodName The name method that was attempted to be called
   */


  function ClientUninitializedError(methodName) {
    var _this = _super.call(this, methodName ? "cannot call " + methodName + " before initializing client." : 'client must be initialized before use.') || this;

    Object.setPrototypeOf(_this, ClientUninitializedError.prototype);
    Error.captureStackTrace(_this, ClientUninitializedError);
    return _this;
  }

  return ClientUninitializedError;
}(Error);

exports.ClientUninitializedError = ClientUninitializedError;
exports.default = ClientUninitializedError;