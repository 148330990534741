/**
 * @file A component for image modals that are triggered by a button
 * @author Harris Lummis
 * @author Daniel Ostrander
 */
import Button, { ButtonProps } from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import shadows from '@material-ui/core/styles/shadows';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const imageModalStyles = ({ palette, spacing }: Theme) =>
  createStyles({
    paper: {
      margin: 'auto' as 'auto',
      maxHeight: '80%',
      maxWidth: '80%',
      width: 'auto' as 'auto',
      height: 'auto' as 'auto',
      backgroundColor: palette.background.paper,
      boxShadow: shadows[5],
      padding: spacing(4),
      display: 'inline-block' as 'inline-block',
    },
  });

export interface ImageModalProps extends WithStyles<typeof imageModalStyles> {
  /** The title of the modal. To be displayed on the button and on the modal */
  title: string;
  /** The source of the image to be displayed */
  imgSrc: string;
  /** The styles of the image */
  imgStyle?: React.CSSProperties;
  /** Optional aria label and description */
  aria?: {
    label?: string;
    description?: string;
  };
  buttonProps?: ButtonProps;
}

export interface ImageModalState {
  /** Indicates whether or not the modal is open */
  open: boolean;
  /**
   * Indicates whether or not the modal has been opened before. Used for
   * deferring image display
   */
  hasBeenOpened: boolean;
  /**
   * Indicates whether or not the modal is loading an image
   */
  isLoading: boolean;
}

export class UnstyledImageModal extends React.Component<
  ImageModalProps,
  ImageModalState
> {
  /**
   * Create an unstyled ImageModal
   * @param props - the props that are passed to the modal
   */
  constructor(props: ImageModalProps) {
    super(props);
    this.state = {
      open: false,
      hasBeenOpened: false,
      isLoading: true,
    };
  }
  /**
   * Open the modal. Simply changes its open state to true
   */
  public handleOpen = () => {
    this.setState({ open: true });
  };
  /**
   * Close the modal. Simply changes its open state to false
   */
  public handleClose = () => {
    this.setState({ open: false });
  };

  public render(): JSX.Element {
    const { classes, imgSrc, imgStyle, title, aria, buttonProps } = this.props;
    // Set default values for button props
    const {
      variant: buttonVariant = 'contained',
      onClick: buttonOnClick = this.handleOpen,
      ...restButtonProps
    } = buttonProps || {};
    return (
      <div>
        <Button
          variant={buttonVariant}
          onClick={buttonOnClick}
          {...restButtonProps}
        >
          {title}
        </Button>
        <Modal
          aria-labelledby={aria ? aria.label : ''}
          aria-describedby={aria ? aria.description : ''}
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className={classes.paper}>
            <Typography variant="h6" id="modal-title">
              {title}
            </Typography>
            <img src={imgSrc} style={imgStyle || {}} />
          </div>
        </Modal>
      </div>
    );
  }
}

/**
 * Apply styling to the UnstyledImageModal with withStyles and export
 * the styled component as the default.
 */
export const ImageModal = withStyles(imageModalStyles)(UnstyledImageModal);

export default ImageModal;
