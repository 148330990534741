/**
 * @file The main application container. All other containers/components live
 * inside here
 * @author Harris Lummis
 */
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import {
  WithStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import { Auth } from 'aws-amplify';
import classNames from 'classnames';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SideMenu from './components/SideMenu';
import Routes from './pages';

const DRAWER_WIDTH = 240;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: '100%',
    },
    appFrame: {
      height: '100%',
      zIndex: 1,
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      width: '100%',
    },
    appBar: {
      position: 'absolute',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: DRAWER_WIDTH,
    },
    'appBar-left': {
      marginLeft: DRAWER_WIDTH,
    },
    'appBar-right': {
      marginRight: DRAWER_WIDTH,
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 20,
    },
    hide: {
      display: 'none',
    },
    content: {
      width: '100%',
      flexGrow: 1,
      background: 'linear-gradient(360deg, #dee1e1 10%, #f4f4f4 160%)',
      padding: theme.spacing(3),
      paddingTop: theme.spacing(10),
      overflowX: 'scroll',
      overflowY: 'scroll',
      whiteSpace: 'nowrap',
      marginLeft: -DRAWER_WIDTH,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentUnauth: {
      marginLeft: 0,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    appBarContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    appBarLeft: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    authButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
      paddingRight: theme.spacing(2),
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  });

/** Props (including styles) used to create the App container */
export interface AppProps
  extends RouteComponentProps,
    WithStyles<typeof styles> {}

/** State for the App container */
export interface AppState {
  /** Indicates whether user is authenticated */
  isAuthenticated: boolean;
  /** Indicates whether user is authenticating */
  isAuthenticating: boolean;
  /** Indicates wheter user is a demo user */
  isDemo: boolean;
  /** Indicates the status of the side menu (open or closed) */
  sidebarOpen: boolean;
}

export class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      isDemo: true,
      sidebarOpen: false,
    };
  }

  public async componentDidMount() {
    try {
      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);
        this.setState({ sidebarOpen: true });
      }
    } catch (err) {
      if (err !== 'No current user') {
        alert(err);
      }
    }

    this.setState({ isAuthenticating: false });
    setTimeout(() => {
      this.setState({ sidebarOpen: false });
    }, 500);
  }

  public handleLogin = async (event: any) => {
    // TODO: make this better
    this.props.history.push('/login');
  };

  public handleLogout = async (event: any) => {
    // Clear session variables. If this is not done, then non-automotus
    // users can see things that they should not be able to
    sessionStorage.clear();
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.setState({
      sidebarOpen: false,
    });
    this.props.history.push('/login');
  };

  public handleDrawerClose = (event: any) => {
    this.setState({ sidebarOpen: false });
  };

  public handleDrawerOpen = (event: any) => {
    this.setState({ sidebarOpen: true });
  };

  public userDemoStatus = async () => {
    try {
      const { isDemo } = await Auth.currentUserInfo();
      this.setState({ isDemo });
    } catch (err) {
      alert(err);
    }
  };

  public userHasAuthenticated = (authenticated: boolean) => {
    this.setState({ isAuthenticated: authenticated });
  };

  public render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userDemoStatus: this.userDemoStatus,
      userHasAuthenticated: this.userHasAuthenticated,
    };
    const { classes } = this.props;
    return (
      !this.state.isAuthenticating && (
        <div className={classes.root}>
          <div className={classes.appFrame}>
            <AppBar
              className={classNames(classes.appBar, {
                [classes.appBarShift]: this.state.sidebarOpen,
              })}
            >
              <Toolbar disableGutters={!this.state.sidebarOpen}>
                <div className={classes.appBarContent}>
                  <div className={classes.appBarLeft}>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={this.handleDrawerOpen}
                      className={classNames(
                        classes.menuButton,
                        this.state.sidebarOpen && classes.hide,
                      )}
                      disabled={!this.state.isAuthenticated}
                    >
                      {this.state.isAuthenticated ? <MenuIcon /> : <Icon />}
                    </IconButton>
                    <div className={classes.logoContainer}>
                      <img
                        src="/logos/medium-white.png"
                        style={{ height: 32, width: 'auto' }}
                      />
                    </div>
                  </div>
                  <div className={classes.authButtonContainer}>
                    {this.state.isAuthenticated ? (
                      <Button color="inherit" onClick={this.handleLogout}>
                        Logout
                      </Button>
                    ) : (
                      <Button color="inherit" onClick={this.handleLogin}>
                        Login
                      </Button>
                    )}
                  </div>
                </div>
              </Toolbar>
            </AppBar>
            {this.state.isAuthenticated && (
              <SideMenu
                path={this.props.location.pathname}
                onDrawerClose={this.handleDrawerClose}
                open={this.state.sidebarOpen}
              />
            )}

            <main
              className={classNames(classes.content, {
                [classes.contentUnauth]: !this.state.isAuthenticated,
                [classes.contentShift]: this.state.sidebarOpen,
              })}
            >
              <Routes childProps={childProps} />
            </main>
          </div>
        </div>
      )
    );
  }
}

export default withRouter(withStyles(styles)(App));
