/**
 * @file Definition of the AuthenticatedRoute component
 * @author Harris Lummis
 */
import React, { ComponentType } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

export interface AuthenticatedRouteProps<P extends {}> extends RouteProps {
  component: ComponentType<any>;
  isAuthenticated: boolean;
  props: P;
}

export function AuthenticatedRoute<P extends {}>(
  props: AuthenticatedRouteProps<P>,
) {
  const {
    isAuthenticated,
    component: C,
    props: componentProps,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={routeProps =>
        isAuthenticated ? (
          <C {...routeProps} {...componentProps} />
        ) : (
          <Redirect
            to={`/login?redirect=${routeProps.location.pathname}${
              routeProps.location.search
            }`}
          />
        )
      }
    />
  );
}

export default AuthenticatedRoute;
