import { InvalidViolationTypeError } from '../errors';
import url from 'url'; // Node 6 vs 8 discrepancy
import querystring from 'querystring';
import LinkHeader from 'http-link-header';
import range from 'lodash/range';
import moment from 'moment-timezone';

/**
 * Format a violation type from the database in human readable format
 * @param violationType - the violation type to format
 * @returns the human-readable form of the violation type string
 */
export function formatViolationType(violationType: string): string {
  switch (violationType) {
    case 'MAX_TIME':
      return 'EXCEEDED TIME';
    case 'RED_ZONE':
      return 'RED ZONE';
    case 'METER':
      return 'EXPIRED_METER';
    case 'PERMIT_EXPIRED':
      return 'EXPIRED_PERMIT';
    case 'INVALID_PERMIT':
      return 'INVALID PERMIT';
    default:
      throw new InvalidViolationTypeError(
        `${violationType} is not a valid violation type`,
      );
  }
}

/**
 * Get an API path and querystring parameters from a LinkHeader object
 * @param link - the LinkHeader object obtained from the 'Link' header in a
 * response
 * @param relName - the name of the rel to convert to a usable api path
 * @returns the concatenation of the path and querystring parameters of the
 * specified rel, or the empty string if the rel could not be found
 */
export function getAPIPathFromRel(link: LinkHeader, relName: string): string {
  const relLinks = link.rel(relName);
  if (relLinks.length === 0) {
    return '';
  }
  const [{ uri }] = relLinks;
  const urlObj = url.parse(uri);
  return urlObj.pathname! + urlObj.search!;
}

export function getRemainingAPIPathsFromLinks(
  link: LinkHeader,
  startPage: number,
): string[] {
  const relLinks = link.rel('last');
  if (relLinks.length === 0) {
    return [];
  }
  const [{ uri }] = relLinks;
  const urlObj = url.parse(uri, true);
  const { page: lastPageNum } = urlObj.query;

  return range(startPage, 1 + +lastPageNum).map(pageNum => {
    return (
      urlObj.pathname! +
      '?' +
      querystring.stringify({ ...urlObj.query, ...{ page: pageNum } })
    );
  });
}

function twoDigitStringOfNumber(n: number) {
  return (n < 10 ? '0' : '') + n;
}

/**
 * Format a duration
 * @param duration - the duration to format
 * @returns a string of the format HH:MM:SS
 */
export function formatDuration(duration: moment.Duration): string {
  const times = [duration.hours(), duration.minutes(), duration.seconds()];
  return times.map(twoDigitStringOfNumber).join(':');
}

/**
 * Format a time as seen in camera timestamps
 * @param time - the time to format expressed as a date or in seconds since the Unix epoch
 * @param timezone - the client timezone to use for formatting
 * @returns the formatted string
 */
export function formatTimeToMatchImageTimestamp(
  time: number | Date,
  timezone: string,
) {
  return moment.tz(time, timezone).format('YYYY/MM/DD HH:mm:ss');
}

/**
 * Format a number to a max decimal precision
 * @param n - the number to format
 * @param precision - the integral max decimal precision
 * @returns the formatted number
 */
export function formatWithMaxPrecision(n: number, precision: number) {
  return +n.toFixed(3);
}

/**
 * Format a number as a percentage
 * @param p The number to format
 * @param precision The precision at which to display the percentage. Defaults
 * to no decimals
 * @returns A string containing the formatted percentage
 */
export function formatPercentage(p: number, precision?: number) {
  if (!precision) {
    return `${Math.round(p * 100)}%`;
  }
  return `${(p * 100).toFixed(precision)}%`;
}
