"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @file Definitions of changeCase utilities
 * @author Harris Lummis
 */

var CAMEL_CASE_REGEXP = /([a-z])([A-Z])/g;
var CAMEL_CASE_UPPER_REGEXP = /([A-Z])([A-Z][a-z])/g;
var NON_WORD_REGEX = /([^A-Za-z0-9]+)/g;
/**
 * Create a new string where individual segments are all lower case and separated by a single space, or the provided replacement token
 * @param str The string to convert
 * @param replacement An optional string used to separate tokens (defaults to ' ')
 * @return A lower case string separated by spaces
 */

function noCase(str, replacement) {
  if (replacement === void 0) {
    replacement = ' ';
  }

  if (str === '') {
    return '';
  }

  return str // Place spaces after lower case characters followed by upper case characters
  .replace(CAMEL_CASE_REGEXP, '$1 $2') // Place spaces after upper case characters followed by lower case characters
  .replace(CAMEL_CASE_UPPER_REGEXP, '$1 $2') // Replace all non-prefix non-word characters with the replacement string.
  // remove non-word prefixes
  .replace(NON_WORD_REGEX, function (match, index, value) {
    if (index === 0 || index === value.length - match.length) {
      return '';
    }

    return replacement;
  }) // Convert the result to lower case
  .toLowerCase();
}

exports.noCase = noCase;
/**
 * Converts a string to camel case
 * @param str The string to convert to camel case
 * @return A new camel case string
 */

function camelCase(str) {
  if (str === '') {
    return str;
  }

  return noCase(str) // Replace spaces between digit characters with underscores
  .replace(/ (?=\d)/g, '_') // Make characters after spaces upper case
  .replace(/ (.)/g, function (_m, matchChar) {
    return matchChar.toUpperCase();
  });
}

exports.camelCase = camelCase;
/**
 * Converts a string to pascal case
 * @param str The string to convert to pascal case
 * @return A pascal case string
 */

function pascalCase(str) {
  if (str === '') {
    return '';
  }

  var camel = camelCase(str);
  return camel.charAt(0).toUpperCase() + camel.substr(1);
}

exports.pascalCase = pascalCase;
/**
 * Creates a new string based on the original, but in snake case
 * @param str The string to convert
 * @return A snake case string
 */

function snakeCase(str) {
  return noCase(str, '_');
}

exports.snakeCase = snakeCase;