"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @file Definition of the Attribute model
 */

var mixins_1 = require("@atlas/models/mixins");

var utils_1 = require("@atlas/models/utils");
/** Default values of enumerable properties */


var defaults = {
  name: '',
  value: ''
};
/** Represents an Attribute of a cognito user */

var Attribute =
/** @class */
function () {
  /**
   * Creates a new Attribute
   * @param props Properties that define the Attribute
   */
  function Attribute(props) {
    if (props === void 0) {
      props = defaults;
    }
    /** Name of the attribute */


    this.name = defaults.name;
    /** Value of the attribute */

    this.value = defaults.value;
    var name = props.name,
        value = props.value;
    this.name = name || defaults.name;
    this.value = value || defaults.value;
  }
  /**
   * Creates a new Attribute instance from an object
   * @param obj The object used to create from
   * @return A new Attribute instance
   */


  Attribute.FromObject = function (obj) {
    return utils_1.parseObject(this, obj);
  };
  /** Default values of enumerable properties */


  Attribute.DEFAULTS = defaults;
  return Attribute;
}();

exports.Attribute = Attribute;
mixins_1.applyMixins(Attribute, mixins_1.Objectable);
exports.default = Attribute;