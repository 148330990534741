/**
 * @file View component for ManualVerification container
 * @author Harris Lummis
 */
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Grid,
  Typography,
  Modal,
  Card,
} from '@material-ui/core';
import { GeneralRejectionReasons, PlateRejectionReasons } from './typings';
import React from 'react';
import TitledImage from '../../components/TitledImage';
import RejectButton from '../../components/RejectButton';
import GeneralRejectionForm from './GeneralRejectionForm';
import map from 'lodash/map';
import ImageRadioButton from '../../components/ImageRadioButton';
import PlateForm from './PlateForm';
import LoadingButton from '../../components/LoadingButton';
import PlateRejectionForm from './PlateRejectionForm';

/** Styles applied to the ManualVerificationView */
const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    imgModalCard: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: theme.spacing(2),
      width: '75%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
    fullSizeImg: {
      width: '100%',
      height: 'auto',
    },
    containerCard: {
      padding: theme.spacing(2),
    },
    wrapText: {
      overflow: 'visible',
      wordWrap: 'break-word',
      whiteSpace: 'normal',
    },
    rejectButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    plateImageContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  });

/** Props passed to create a ManualVerificationView */
export interface ManualVerificationViewProps extends WithStyles<typeof styles> {
  /** Plate text */
  plate: string;
  /** Plate state */
  state: string;
  /** Plate country */
  country: string;
  /** Infraction start time as a formatted string */
  startTimeString: string;
  /** Infraction end time as a formatted string */
  endTimeString: string;
  /** Source url for park image */
  parkImgSrc: string;
  /** Source url for infraction image */
  infractionImgSrc: string;
  /** Source urls for plate images */
  plateImgSrcs: string[];
  /** Index of selected plate image */
  selectedPlateIndex?: number;
  /** Indicates whether submission is in progress */
  submitting: boolean;
  /** Indicates whether plate info form is disabled */
  plateInfoDisabled: boolean;
  /**
   * Handler for selection of plate image. The event accepts a value.
   */
  onSelectPlateImg: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /** Indicates whether general rejection has been enabled */
  generalRejectionEnabled: boolean;
  /** Info supplied for general rejection */
  generalRejectionInfo?: GeneralRejectionReasons;
  /** Click handler for general reject button */
  onClickGeneralReject: (event: React.MouseEvent<HTMLElement>) => void;
  /** Indicates whether plate rejection has been enabled */
  plateRejectionEnabled: boolean;
  /** Info supplied for plate rejection */
  plateRejectionInfo?: PlateRejectionReasons;
  /** Click handler for plate reject button */
  onClickPlateReject: (event: React.MouseEvent<HTMLElement>) => void;
  /** Change handler for general rejection form */
  onChangeGeneralReject: (
    name: keyof GeneralRejectionReasons,
    value: any,
  ) => void;
  /** Change handler for plate rejection form */
  onChangePlateReject: (name: keyof PlateRejectionReasons, value: any) => void;
  /** Change handler for plate info form */
  onChangePlateInfo: (
    name: 'plate' | 'state' | 'country',
    value: string,
  ) => void;
  /** Click handler for submit button */
  onSubmit: (event: React.MouseEvent<HTMLElement>) => void;
  /** Indicates whether submission is disabled */
  submitDisabled: boolean;
  /** A way to optionally specify the initial state of the component */
  initialState?: ManualVerificationViewState;
}

/** Initial state of the ManualVerificationView */
const initialState = {
  /** Indicates whether park image modal is open */
  parkModalOpen: false,
  /** Indicates whether infraction modal is open */
  infractionModalOpen: false,
};

export type ManualVerificationViewState = Readonly<typeof initialState>;

/**
 * View for displaying the content of the manual verification container
 */
export class ManualVerificationView extends React.Component<
  ManualVerificationViewProps,
  ManualVerificationViewState
> {
  constructor(props: ManualVerificationViewProps) {
    super(props);
    this.state = props.initialState || initialState;
    this.toggleParkModal = this.toggleParkModal.bind(this);
    this.toggleInfractionModal = this.toggleInfractionModal.bind(this);
  }
  /**
   * Toggle open/close the park image modal
   * @param open True to open modal, false to close
   */
  public toggleParkModal(open: boolean) {
    this.setState({ parkModalOpen: open });
  }
  /**
   * Toggle open/close the park image modal
   * @param open True to open modal, false to close
   */
  public toggleInfractionModal(open: boolean) {
    this.setState({ infractionModalOpen: open });
  }
  public render() {
    const {
      classes,
      plate,
      state,
      country,
      startTimeString,
      endTimeString,
      parkImgSrc,
      infractionImgSrc,
      plateImgSrcs: plateImageSrcs,
      selectedPlateIndex,
      submitting,
      plateInfoDisabled,
      onSelectPlateImg,
      generalRejectionEnabled,
      generalRejectionInfo,
      onClickGeneralReject,
      plateRejectionEnabled,
      plateRejectionInfo,
      onClickPlateReject,
      onChangeGeneralReject,
      onChangePlateReject,
      onChangePlateInfo,
      onSubmit,
      submitDisabled,
    } = this.props;

    const { parkModalOpen, infractionModalOpen } = this.state;

    return (
      <div className={classes.root}>
        <Grid id="container--main-imgs" container={true} spacing={3}>
          <Grid className={classes.wrapText} item={true} xs={12}>
            <Typography variant="h5">
              Please Verify that the Correct Vehicle was Cited at the Correct
              Time
            </Typography>
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <TitledImage
              onClickImg={() => this.toggleParkModal(true)}
              imgSrc={parkImgSrc}
              title={`Reported Park Time: ${startTimeString}`}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <TitledImage
              onClickImg={() => this.toggleInfractionModal(true)}
              imgSrc={infractionImgSrc}
              title={`Reported Infraction Time: ${endTimeString}`}
            />
          </Grid>
        </Grid>
        <Grid
          id="container--general-reject-group"
          container={true}
          spacing={3}
          justify="center"
        >
          <Grid className={classes.rejectButtonContainer} item={true} xs={12}>
            <RejectButton
              onClick={onClickGeneralReject}
              pressed={generalRejectionEnabled}
              disabled={submitting}
            >
              {'Reject Citation'}
            </RejectButton>
          </Grid>
          {generalRejectionEnabled && (
            <GeneralRejectionForm
              onChange={onChangeGeneralReject}
              {...generalRejectionInfo}
            />
          )}
        </Grid>
        <div id="container--plate-info">
          <h2>Select the Best Plate Image</h2>
          <div className={classes.plateImageContainer}>
            {map(plateImageSrcs, (imgSrc, index) => {
              return (
                <ImageRadioButton
                  key={index}
                  imgSrc={imgSrc}
                  selected={index === selectedPlateIndex}
                  onClick={onSelectPlateImg}
                  value={index}
                  disabled={submitting || plateRejectionEnabled}
                />
              );
            })}
          </div>
          <div className={classes.rejectButtonContainer}>
            <RejectButton
              onClick={onClickPlateReject}
              pressed={plateRejectionEnabled}
              disabled={submitting}
            >
              {'Reject Plate'}
            </RejectButton>
          </div>
          {plateRejectionEnabled && (
            <PlateRejectionForm
              onChange={onChangePlateReject}
              {...plateRejectionInfo}
            />
          )}
          <h2>Enter Plate Information</h2>
          <PlateForm
            plate={plate}
            country={country}
            state={state}
            onChange={onChangePlateInfo}
            disabled={submitting || plateInfoDisabled}
          />
        </div>
        <LoadingButton
          id="submit-button"
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={submitting || submitDisabled}
          loading={submitting}
        >
          {'Submit'}
        </LoadingButton>
        <Modal
          id="modal--park-img"
          aria-labelledby="park image"
          aria-describedby="park image"
          open={parkModalOpen}
          onClose={() => this.toggleParkModal(false)}
        >
          <Card className={classes.imgModalCard}>
            <img className={classes.fullSizeImg} src={parkImgSrc} />
          </Card>
        </Modal>
        <Modal
          aria-labelledby="infraction image"
          aria-describedby="infraction image"
          open={infractionModalOpen}
          onClose={() => this.toggleInfractionModal(false)}
        >
          <Card className={classes.imgModalCard}>
            <img className={classes.fullSizeImg} src={infractionImgSrc} />
          </Card>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(ManualVerificationView);
