"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @file Definition of the MFAOption model
 */

var mixins_1 = require("@atlas/models/mixins");

var utils_1 = require("@atlas/models/utils");
/** Default values of enumerable properties */


var defaults = {
  deliveryMedium: null,
  attributeName: ''
};

var MFAOption =
/** @class */
function () {
  /**
   * Creates a new MFAOption
   * @param props Properties used to define the MFAOption
   */
  function MFAOption(props) {
    if (props === void 0) {
      props = defaults;
    }
    /** Delivery medium */


    this.deliveryMedium = defaults.deliveryMedium;
    /** Name of the attribute */

    this.attributeName = defaults.attributeName;
    var deliveryMedium = props.deliveryMedium,
        attributeName = props.attributeName;
    this.deliveryMedium = deliveryMedium || defaults.deliveryMedium;
    this.attributeName = attributeName || defaults.attributeName;
  }
  /**
   * Creates a new MFAOption from a camel, snake, or pascal case
   * object
   * @param obj Source object to create from
   * @return An MFAOption instance
   */


  MFAOption.FromObject = function (obj) {
    return utils_1.parseObject(this, obj);
  };
  /** Default values of enumerable properties */


  MFAOption.DEFAULTS = defaults;
  return MFAOption;
}();

exports.MFAOption = MFAOption;
mixins_1.applyMixins(MFAOption, mixins_1.Objectable);
exports.default = MFAOption;