/**
 * @file Component that holds inputs for plate data
 * @author Harris Lummis
 */
import React from 'react';
import {
  createStyles,
  WithStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';

/** Default properties passed to each interior text field */
export const DEFAULT_TEXTFIELD_PROPS: StandardTextFieldProps = {
  type: 'text',
  margin: 'normal',
};

/** Styles applied to the component */
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    textField: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: 200,
    },
  });

/** Props passed to create a PlateForm */
export interface PlateFormProps extends WithStyles<typeof styles> {
  /** Plate text */
  plate: string;
  /** Country text */
  country: string;
  /** State text */
  state: string;
  /** Input change handler */
  onChange: (name: 'plate' | 'country' | 'state', value: string) => void;
  /** Properties that will override default text field properties */
  textFieldProps?: StandardTextFieldProps;
  /** Indicates whether input is enabled */
  disabled?: boolean;
}

/** Component for managing form inputs relating to plate data */
export function PlateForm({
  classes,
  plate,
  country,
  state,
  onChange,
  textFieldProps,
  disabled = false,
}: PlateFormProps) {
  const extraProps = {
    ...DEFAULT_TEXTFIELD_PROPS,
    className: classes.textField,
    ...textFieldProps,
    disabled,
  };
  return (
    <div className={classes.root}>
      <TextField
        name="plate"
        label="plate text"
        className={classes.textField}
        value={plate}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange('plate', event.target.value);
        }}
        {...extraProps}
      />
      <TextField
        name="country"
        label="country"
        className={classes.textField}
        value={country}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange('country', event.target.value);
        }}
        {...extraProps}
      />
      <TextField
        name="state"
        label="state"
        className={classes.textField}
        value={state}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange('state', event.target.value);
        }}
        {...extraProps}
      />
    </div>
  );
}

export default withStyles(styles)(PlateForm);
