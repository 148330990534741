/**
 * @file Manual verification page
 * @author Harris Lummis
 */
import React, { FunctionComponent } from 'react';
import { createAuth } from '../../../libs/auth';
import withAuthorization from '../../../components/withAuthorization';
import { ManualVerification } from '../../../containers/ManualVerification';
import { RouteComponentProps } from 'react-router';
import config from '../../../config';
import { API } from 'aws-amplify';
import InfractionsController from '../../../controllers/infractions';
import { RetryAPI } from '../../../libs/utils';
import { DeclineInfractionArgs } from '../../../containers/ManualVerification/typings';
import { getTemporaryUrlFromS3Key } from '../../../libs/awsLib';
import times from 'lodash/times';

interface URLParams {
  infractionId: string;
}

export interface ManualVerificationPageProps
  extends RouteComponentProps<URLParams> {}

const { isAutomotusUser } = createAuth();
const AuthorizedManualVerification = withAuthorization(isAutomotusUser)(
  ManualVerification,
);

const IMAGE_TTL = 300;

export const ManualVerificationPage: FunctionComponent<
  ManualVerificationPageProps
> = ({ match, history }) => {
  const infractionId = match.params.infractionId;
  const infractionsController = InfractionsController({
    apiName: 'parking',
    API: RetryAPI({ api: API }),
  });

  const getInfraction = async (id: string) => {
    const rawInfraction = await infractionsController.getOne(id);

    if (!rawInfraction) {
      throw new Error('could not find infraction');
    }

    return {
      allowVerification: rawInfraction.allow_verification,
      endTime: new Date(rawInfraction.end_time),
      startTime: new Date(rawInfraction.start_time),
      clientId: config.clientId,
      infractionId: rawInfraction.infraction_id,
      objectId: rawInfraction.object_id,
      plate: rawInfraction.plate || undefined,
      state: rawInfraction.state || undefined,
      verified: rawInfraction.verified,
      violationType: rawInfraction.violation_type,
    };
  };

  const declineInfraction = (args: DeclineInfractionArgs) =>
    infractionsController.decline({
      infractionId: args.infractionId,
      imgKeyPlate: args.plateImgKey,
      rejectionReason: JSON.stringify(args.rejectionInfo),
    });

  const getImgSrc = (prefix: string, key: string, ttl: number) =>
    getTemporaryUrlFromS3Key(prefix, key, ttl);

  const getPlateImgKeys = (objectId: string, numImgs: number) =>
    times(numImgs, index => {
      const imgNum = index + 1;
      return `prod/${config.clientId}/${objectId}/img${
        imgNum > 9 ? imgNum : '0' + imgNum
      }.jpg`;
    });

  const verifyInfraction = infractionsController.verify.bind(
    infractionsController,
  );

  return (
    <AuthorizedManualVerification
      infractionId={infractionId}
      clientId={config.clientId}
      clientTimezone={config.clientTimezone}
      numPlateImgs={config.NUM_PLATE_IMAGES}
      imageTTL={IMAGE_TTL}
      // TODO: avoid hardcoding history push
      onFinishSubmit={() => history.push('/citations/pending')}
      getInfraction={getInfraction}
      verifyInfraction={verifyInfraction}
      declineInfraction={declineInfraction}
      getImgSrc={getImgSrc}
      getPlateImgKeys={getPlateImgKeys}
    />
  );
};
