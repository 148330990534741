import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { Theme, WithStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import { Auth } from 'aws-amplify';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './Login.css';
import LoadingButton from '../../components/LoadingButton';

interface LoginContentProps {
  userHasAuthenticated: (hasAuthenticated: true) => void;
}

type LoginContentPropsWithStyles = LoginContentProps &
  WithStyles<
    | 'buttonProgress'
    | 'card'
    | 'container'
    | 'heading'
    | 'textField'
    | 'buttonWrapper'
    | 'errorMessage'
  >;

interface LoginContentState {
  email: string;
  password: string;
  isLoading: boolean;
  newPassword: string;
  confirmNewPassword: string;
  newPasswordRequired: boolean;
  user: any;
  incorrectLogin: boolean;
  invalidEmail: boolean;
}

const decorator = withStyles((theme: Theme) => ({
  container: {
    height: '66%',
  },
  heading: {
    color: theme.palette.primary.main,
    fontWeight: 300 as 300,
    marginLeft: 'auto' as 'auto',
    marginRight: 'auto' as 'auto',
    marginTop: theme.spacing(3),
  },
  card: {
    padding: theme.spacing(2),
    whiteSpace: 'normal' as 'normal',
    margin: 'auto' as 'auto',
    textAlign: 'center' as 'center',
  },
  textField: {
    marginLeft: 50,
    marginRight: 50,
    width: 200,
  },
  errorMessage: {
    marginTop: theme.spacing(),
    color: red[500],
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    margin: 'auto' as 'auto',
    marginTop: theme.spacing(3),
    position: 'relative' as 'relative',
  },
}));

// TODO: add password incorrect message

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // tslint:disable-line

const LoginContent = decorator(
  class extends React.Component<
    LoginContentPropsWithStyles,
    LoginContentState
  > {
    constructor(props: LoginContentPropsWithStyles) {
      super(props);
      this.state = {
        email: '',
        isLoading: false,
        password: '',
        newPassword: '',
        confirmNewPassword: '',
        newPasswordRequired: false,
        user: null,
        incorrectLogin: false,
        invalidEmail: false,
      };
    }

    public validateEmail = () => {
      if (!EMAIL_REGEX.test(this.state.email)) {
        this.setState({ invalidEmail: true });
      }
    };

    public validateForm() {
      if (this.state.newPasswordRequired) {
        const { newPassword, confirmNewPassword } = this.state;
        return newPassword.length > 0 && newPassword === confirmNewPassword;
      }
      const { email, password } = this.state;
      return email.length > 0 && password.length > 0;
    }

    public handleChange = (inputName: keyof LoginContentState) => (
      event: any,
    ) => {
      // yes this is an ugly hack but computed property names are a bitch when it
      // comes to state
      if (inputName === 'email') {
        this.setState({
          invalidEmail: false,
        });
      }
      this.setState({
        incorrectLogin: false,
        [inputName]: event.target.value as string,
      } as any);
    };

    public handleNewPasswordSubmit = (event: any) => {
      const { user, newPassword } = this.state;
      event.preventDefault();
      this.setState({ isLoading: true });
      Auth.completeNewPassword(user, newPassword, null)
        .then(() => {
          this.props.userHasAuthenticated(true);
        })
        .catch(err => {
          alert(err.message);
          this.setState({ isLoading: false });
        });
    };

    public handleSubmit = (event: any) => {
      const { email, password } = this.state;
      event.preventDefault();
      this.setState({ isLoading: true });
      Auth.signIn(email, password)
        .then(user => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.setState({
              isLoading: false,
              user,
              newPasswordRequired: true,
            });
          } else {
            this.props.userHasAuthenticated(true);
          }
        })
        .catch(err => {
          if (
            err.message === 'Incorrect username or password.' ||
            err.message === 'User does not exist.'
          ) {
            this.setState({
              incorrectLogin: true,
            });
          } else {
            alert(err.message);
          }
          this.setState({ isLoading: false });
        });
    };

    public render() {
      const { classes } = this.props;
      const {
        email,
        incorrectLogin,
        invalidEmail,
        isLoading,
        password,
        newPassword,
        confirmNewPassword,
        newPasswordRequired,
      } = this.state;

      return (
        <Grid
          className={classes.container}
          container={true}
          direction="row"
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item={true} xs={11} sm={8} md={5} lg={4} xl={3}>
            <Card className={classes.card}>
              <h2 className={classes.heading}>Sign In</h2>
              {newPasswordRequired ? (
                <form onSubmit={this.handleNewPasswordSubmit}>
                  <TextField
                    autoFocus={true}
                    id="newPassword"
                    label="new password"
                    className={classes.textField}
                    value={newPassword}
                    type="password"
                    onChange={this.handleChange('newPassword')}
                    margin="normal"
                  />
                  <TextField
                    id="confirmNewPassword"
                    label="confirm new password"
                    className={classes.textField}
                    value={confirmNewPassword}
                    onChange={this.handleChange('confirmNewPassword')}
                    type="password"
                    margin="normal"
                  />
                  <div className={classes.buttonWrapper}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!this.validateForm() || isLoading}
                    >
                      Change Password
                    </Button>
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </form>
              ) : (
                <form onSubmit={this.handleSubmit}>
                  <TextField
                    autoFocus={true}
                    id="email"
                    label="email"
                    className={classes.textField}
                    value={email}
                    onChange={this.handleChange('email')}
                    margin="normal"
                    error={invalidEmail || incorrectLogin}
                  />
                  {invalidEmail && (
                    <div className={classes.errorMessage}>
                      Please enter a valid email address.
                    </div>
                  )}
                  <TextField
                    id="password"
                    label="password"
                    className={classes.textField}
                    value={password}
                    onChange={this.handleChange('password')}
                    onFocus={this.validateEmail}
                    type="password"
                    margin="normal"
                    error={incorrectLogin}
                  />
                  {incorrectLogin && (
                    <div className={classes.errorMessage}>
                      Incorrect username or password.
                    </div>
                  )}
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!this.validateForm() || isLoading}
                    loading={isLoading}
                  >
                    Login
                  </LoadingButton>
                </form>
              )}
            </Card>
          </Grid>
        </Grid>
      );
    }
  },
);

interface LoginPropsBase {
  userHasAuthenticated: (hasAuthenticated: true) => void;
}

export type LoginProps = LoginPropsBase & RouteComponentProps<string>;

export const Login: React.FunctionComponent<LoginProps> = props => (
  <LoginContent userHasAuthenticated={props.userHasAuthenticated} />
);

export default Login;
