const BigTableStyle = {
  card: {
    margin: '12px',
  },
  tableHeader: {
    color: 'rgba(1, 50, 67, 0.5)',
    fontFamily: "'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif",
  },
  tableHeaderColumn: {
    // color: 'rgba(1, 50, 67, 0.5)',
    fontSize: '20px',
    fontWeight: 300 as 300, // otherwise TS compiler will throw a type error
    verticalAlign: 'middle',
  },
  tableRow: {
    fontFamily: "'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif",
  },
  tableRowColumn: {
    color: '#013243',
    fontSize: '12px',
    fontWeight: 300 as 300,
    verticalAlign: 'middle',
  },
  tableRowChild: {
    fontFamily: 'inherit' as 'inherit',
    color: 'inherit' as 'inherit',
    fontSize: 'inherit' as 'inherit',
    fontWeight: 'inherit' as 'inherit',
  },
};

export default BigTableStyle;
