import { Auth, Storage } from 'aws-amplify';

export interface FullResponse<TData> {
  data: TData;
  status: number;
  headers?: any;
}

export function s3Upload(file: File) {
  const filename = `${Date.now()}-${file.name}`;
  return (
    Storage.vault
      .put(filename, file, {
        contentType: file.type,
      })
      // TODO: libary types complain here for some unknown reason
      // @ts-ignore
      .then((stored: { key: string }) => {
        return stored.key;
      })
  );
}

/**
 * Get a temporary url for an object in S3
 * @param keyPrefix - the prefix to use for public retrieval of the S3 object
 * in question
 * @param objectKey - the key of the image for which to retrieve a temporary
 * url
 * @param expireTime - the time in seconds after which the temprorary url
 * will expire
 * @returns a promise that resolves to the
 */
export function getTemporaryUrlFromS3Key(
  keyPrefix: string,
  objectKey: string,
  expireTime: number = 300,
): PromiseLike<string> {
  return Storage.get(objectKey, {
    customPrefix: {
      public: keyPrefix,
      protected: keyPrefix,
      private: keyPrefix,
    },
    level: 'public',
    expires: expireTime,
  }) as Promise<string>;
}

/**
 * Determine the group(s) to which a user belongs
 * @returns A promise that resolves to the value of the 'cognito:groups'
 * item in session storage, or if none exists, the 'cognito:groups' property of
 * the user's id token
 */
export function getUserGroups(): Promise<string[]> {
  // First check local storage for groups
  const groupsFromStorage = sessionStorage.getItem('cognito:groups');
  if (groupsFromStorage) {
    return Promise.resolve(JSON.parse(groupsFromStorage));
  }
  return Auth.currentSession().then(({ idToken }) => {
    const groups = idToken.payload['cognito:groups'];

    // Set session storage item for easier retrieval
    sessionStorage.setItem('cognito:groups', JSON.stringify(groups));
    return groups;
  });
}

/**
 * Check whether the current user is a demo user
 * @returns A promise that resolves to true if the user is a demo user
 */
export async function isDemoUser() {
  const [demoGroup] = await getUserGroups();

  // If the user has no groups then we assume that they are part of the
  // demo group
  if (!demoGroup || demoGroup === 'Demo') {
    return true;
  }

  return false;
}

/**
 * Check whether the current user is an automotus user
 * @returns A promise that resolves to true if the user is an automotus user
 */
export async function isAutomotusUser() {
  const [group] = await getUserGroups();

  // If the user is part of the Automotus group, then that should be the first
  // group that appears
  if (group === 'Automotus') {
    return true;
  }

  return false;
}
