import { API } from 'aws-amplify';
import findIndex from 'lodash/findIndex';
import moment from 'moment-timezone';
import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Table, { Column } from '../components/DataTable';
import { ErrorBar } from '../components/errors';
import ImageModal from '../components/ImageModal';
import VerificationButtonContainer from '../components/VerificationButtonContainer';
import config from '../config';
import InfractionsController, {
  InfractionsControllerInstance,
} from '../controllers/infractions';
import { isAutomotusUser } from '../libs/awsLib';
import { formatViolationType } from '../libs/formatLib';
import { RetryAPI } from '../libs/utils';
import BigTableStyle from '../styles/BigTable';
import { Infraction, PendingCitation } from '../typings/responses';
import { ImageLinks } from '../typings/types';
import './Home.css';

export interface Props extends RouteComponentProps<string> {
  /** Column definitions */
  columnDefs: Column[];
  /** True if the app is in demo mode */
  isDemo: boolean;
  /** True if the user is authenticated */
  isAuthenticated: boolean;
}

export interface State {
  /**
   * Whether current user is an automotus user. If null, the user has not been
   * checked yet.
   */
  automotusUser: null | boolean;
  /** An error to display */
  err?: Error;
  /** True when loading */
  isLoading: boolean;
  /** Actual infractions from database */
  infractions: Infraction[];
  /** Last evaluated key during queries for infractions */
  lastEvaluatedKey?: { [attributeName: string]: string };
  /** An array of pending citation objects */
  pendingCitations: StatefulPendingCitation[];
  orderBy: string;
  order: 'asc' | 'desc';
}

interface StatefulPendingCitation extends PendingCitation {
  /** Indicates whether or not the citation has been verified */
  verified?: boolean;
  /** Indicates verification time, if any */
  verifiedAt?: number;
}

const DEMO_CITATIONS: StatefulPendingCitation[] = [
  {
    images: {
      park: '/images/7WVX691-park.jpg',
      plate: '/images/7WVX691-plate.jpg',
      violation: '/images/7WVX691-cite.jpg',
    },
    license_plate: '7WVX691',
    end_time: moment.tz('2018-04-17 10:13:08', 'America/Los_Angeles').toDate(),
    start_time: moment
      .tz('2018-04-17 09:41:08', 'America/Los_Angeles')
      .toDate(),
    state: 'CA',
    verified: false,
    violation_id: 'violation1',
    violation_type: 'MAX_TIME',
  },
  {
    images: {
      park: '/images/6RGJ148-park.jpg',
      plate: '/images/6RGJ148-plate.jpg',
      violation: '/images/6RGJ148-cite.jpg',
    },
    license_plate: '6RGJ148',
    end_time: moment.tz('2018-04-17 11:43:35', 'America/Los_Angeles').toDate(),
    start_time: moment
      .tz('2018-04-17 11:11:35', 'America/Los_Angeles')
      .toDate(),
    state: 'CA',
    verified: false,
    violation_id: 'violation2',
    violation_type: 'MAX_TIME',
  },
  {
    images: {
      park: '/images/7EUU195-park.jpg',
      plate: '/images/7EUU195-plate.png',
      violation: '/images/7EUU195-cite.jpg',
    },
    license_plate: '7EUU195',
    end_time: moment.tz('2018-04-10 15:12:22', 'America/Los_Angeles').toDate(),
    start_time: moment
      .tz('2018-04-10 14:40:22', 'America/Los_Angeles')
      .toDate(),
    state: 'CA',
    verified: false,
    violation_id: 'violation3',
    violation_type: 'MAX_TIME',
  },
  {
    images: {
      park: '/images/7LZT891-park.jpg',
      plate: '/images/7LZT891-plate.jpg',
      violation: '/images/7LZT891-cite.jpg',
    },
    license_plate: '7LZT891',
    end_time: moment.tz('2018-04-10 10:50:40', 'America/Los_Angeles').toDate(),
    start_time: moment
      .tz('2018-04-10 10:18:40', 'America/Los_Angeles')
      .toDate(),
    state: 'CA',
    verified: false,
    violation_id: 'violation4',
    violation_type: 'MAX_TIME',
  },
  {
    images: {
      park: '/images/7RYG758-park.jpg',
      plate: '/images/7RYG758-plate.jpg',
      violation: '/images/7RYG758-cite.jpg',
    },
    license_plate: '7RYG758',
    end_time: moment.tz('2018-04-12 10:55:55', 'America/Los_Angeles').toDate(),
    start_time: moment
      .tz('2018-04-12 10:23:55', 'America/Los_Angeles')
      .toDate(),
    state: 'CA',
    verified: false,
    violation_id: 'violation5',
    violation_type: 'MAX_TIME',
  },
];

const imgModalStyle = {
  maxWidth: '80%',
  maxHeight: '80%',
  width: 'auto' as 'auto',
  height: 'auto' as 'auto',
  marginLeft: 'auto' as 'auto',
  marginRight: 'auto' as 'auto',
  display: 'block' as 'block',
};

/** Table columns used for automotus-specific display. Subject to change. */
const TABLE_COLUMNS_AUTOMOTUS: Column[] = [
  {
    id: 'infraction_id',
    label: 'ID',
    sortable: false,
    disablePadding: false,
    numeric: false,
    render: (id: string) => {
      return <Link to={`/citations/manual-verification/${id}`}>{id}</Link>;
    },
  },
  {
    id: 'end_time',
    label: 'Recorded At',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (time: number) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {moment
            .tz(time, config.clientTimezone)
            .format('MM/DD/YYYY h:mm:ss A')}
        </p>
      );
    },
  },
];

const TABLE_COLUMNS: Column[] = [
  {
    id: 'license_plate',
    label: 'Plate # (State)',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (plateText: string, rowValues: PendingCitation) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {plateText + ' (' + rowValues.state + ')'}
        </p>
      );
    },
  },
  {
    id: 'violation_type',
    label: 'Type',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (violationType: string) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {formatViolationType(violationType)}
        </p>
      );
    },
  },
  {
    id: 'end_time',
    label: 'Recorded At',
    sortable: true,
    disablePadding: false,
    numeric: false,
    render: (time: Date) => {
      return (
        <p style={BigTableStyle.tableRowChild}>
          {moment
            .tz(time.getTime(), config.clientTimezone)
            .format('MM/DD/YYYY h:mm:ss A')}
        </p>
      );
    },
  },
  {
    id: 'images',
    label: 'Images',
    sortable: false,
    disablePadding: false,
    dense: true,
    numeric: false,
    render: (imageLinks: ImageLinks) => {
      return (
        <div
          style={{
            width: '240px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
          className="image-link-container"
        >
          <ImageModal
            imgSrc={imageLinks.plate}
            imgStyle={imgModalStyle}
            title="Plate"
            buttonProps={{
              size: 'small',
            }}
          />
          <ImageModal
            imgSrc={imageLinks.park}
            imgStyle={imgModalStyle}
            title="Park"
            buttonProps={{
              size: 'small',
            }}
          />
          <ImageModal
            imgSrc={imageLinks.violation}
            imgStyle={imgModalStyle}
            title="Violation"
            buttonProps={{
              size: 'small',
            }}
          />
        </div>
      );
    },
  },
  {
    id: 'violation_id',
    label: '',
    sortable: false,
    disablePadding: false,
    numeric: false,
  },
];

export default class VerifyCitations extends React.Component<Props, State> {
  private columns: Column[];
  private infractionsController: InfractionsControllerInstance;
  /** Construct a new VerifyCitations container */
  constructor(props: Props) {
    super(props);
    // TODO: Once Automotus verification is fully setup, set this to the
    // Automotus columns
    this.columns = TABLE_COLUMNS;
    // Setup special rendering function for verification buttons
    this.columns[
      this.columns.length - 1
    ].render = this.renderVerificationButtonContainer();

    this.infractionsController = InfractionsController({
      apiName: 'parking',
      API: RetryAPI({ api: API }),
    });

    this.state = {
      // Can't establish user until mount
      automotusUser: null,
      err: undefined,
      isLoading: true,
      infractions: [],
      lastEvaluatedKey: undefined,
      pendingCitations: [],
      orderBy: 'end_time',
      order: 'desc',
    };
  }
  /**
   * Specialized rendering function for verification buttons. Because these
   * buttons call an instance method of the container, their functionality
   * cannot be defined prior to the creation of the container.
   */
  public renderVerificationButtonContainer = () => {
    return (violationId: string) => {
      const pendingCitations = this.state.pendingCitations.slice();
      const removeIndex = findIndex(pendingCitations, citation => {
        return citation.violation_id === violationId;
      });
      pendingCitations.splice(removeIndex as number, 1);
      return (
        <VerificationButtonContainer
          violationId={violationId}
          onVerificationHandler={() => {
            this.handleCitationVerification(violationId);
          }}
          onDeclineHandler={() => {
            this.handleCitationDecline(violationId);
          }}
        />
      );
    };
  };
  /** Handle a press of a verification button */
  public handleCitationVerification = (violationId: string) => {
    const pendingCitations = this.state.pendingCitations.slice();
    const removeIndex = findIndex(pendingCitations, citation => {
      return citation.violation_id === violationId;
    });
    const removed = pendingCitations.splice(removeIndex as number, 1);
    const previouslyVerifiedString = sessionStorage.getItem(
      'verifiedCitations',
    );
    let previouslyVerified = [];
    if (typeof previouslyVerifiedString && previouslyVerifiedString) {
      previouslyVerified = JSON.parse(previouslyVerifiedString);
    }
    removed[0].verifiedAt = new Date().getTime();
    sessionStorage.setItem(
      'verifiedCitations',
      JSON.stringify(previouslyVerified.concat(removed)),
    );
    this.setState({ pendingCitations });
  };
  /** Handle a press of a decline button */
  public handleCitationDecline = (violationId: string) => {
    const pendingCitations = this.state.pendingCitations.slice();
    const removeIndex = findIndex(pendingCitations, citation => {
      return citation.violation_id === violationId;
    });
    const removed = pendingCitations.splice(removeIndex as number, 1);
    const previouslyDeclinedString = sessionStorage.getItem(
      'declinedCitations',
    );
    let previouslyDeclined = [];
    if (typeof previouslyDeclinedString && previouslyDeclinedString) {
      previouslyDeclined = JSON.parse(previouslyDeclinedString);
    }
    removed[0].verifiedAt = new Date().getTime();
    sessionStorage.setItem(
      'declinedCitations',
      JSON.stringify(previouslyDeclined.concat(removed)),
    );
    this.setState({ pendingCitations });
  };
  /** Handle a press of the error bar's close button */
  public handleCloseErrorBar = () => {
    this.setState({ err: undefined });
  };
  /** Handle a press of the error bar's retry button by refreshing the page */
  public handleRetryErrorBar = () => {
    window.location.reload();
  };
  /**
   * Mounting callback. Ensures that current user is authenticated and
   * establishes initial contents of table
   */
  public async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      const automotusUser = await isAutomotusUser();
      // Initialize the infractions controller to allow for list queries
      if (automotusUser) {
        // Only initialize the controller if user has Automotus permissions
        await this.infractionsController.init();
        await this.updateInfractions();
        this.setState({ automotusUser });
      } else {
        const pendingCitations = await this.pendingCitations();
        this.setState({ automotusUser, pendingCitations });
      }
    } catch (err) {
      this.setState({ err });
    }

    this.setState({ isLoading: false });
  }
  /** Update infractions using dynamodb as a data source */
  public async updateInfractions() {
    const { lastEvaluatedKey } = this.state;
    const listOutput = await this.infractionsController.list({
      verified: false,
      sort: 'end-time_desc',
      lastEvaluatedKey,
    });
    if (listOutput.lastEvaluatedKey) {
      this.setState({ lastEvaluatedKey: listOutput.lastEvaluatedKey });
    }
    this.setState({
      infractions: this.state.infractions.concat(listOutput.infractions),
    });
  }
  public async pendingCitations(orderBy?: string, order?: 'asc' | 'desc') {
    const { orderBy: curOrderBy, order: curOrder } = this.state;
    let pendingCitations: PendingCitation[] = [];
    orderBy = orderBy || curOrderBy;
    order = order || curOrder;
    if (this.props.isDemo || !this.props.isDemo) {
      const previouslyVerifiedCitationString = sessionStorage.getItem(
        'verifiedCitations',
      );
      const previouslyDeclinedCitationString = sessionStorage.getItem(
        'declinedCitations',
      );
      const previouslyHandledIds: string[] = [];

      if (
        typeof previouslyVerifiedCitationString &&
        previouslyVerifiedCitationString
      ) {
        previouslyHandledIds.push(
          ...JSON.parse(previouslyVerifiedCitationString).map(
            (citation: StatefulPendingCitation) => citation.violation_id,
          ),
        );
      }
      if (
        typeof previouslyDeclinedCitationString &&
        previouslyDeclinedCitationString
      ) {
        previouslyHandledIds.push(
          ...JSON.parse(previouslyDeclinedCitationString).map(
            (citation: StatefulPendingCitation) => citation.violation_id,
          ),
        );
      }

      if (previouslyHandledIds.length === 0) {
        pendingCitations = DEMO_CITATIONS;
      } else {
        pendingCitations = DEMO_CITATIONS.filter(citation => {
          // Only return unverified citations
          return previouslyHandledIds.indexOf(citation.violation_id) < 0;
        }).map(citation => {
          citation.verified = false;
          return citation;
        });
      }
    }

    pendingCitations.sort((c1, c2) => {
      return order === 'asc'
        ? c1[orderBy!] < c2[orderBy!]
          ? -1
          : 1
        : c2[orderBy!] < c1[orderBy!]
        ? -1
        : 1;
    });

    return pendingCitations;
  }
  /**
   * Render table of real infractions. Called when an Automotus user requests
   * this display
   */
  public renderInfractionsTable(infractions: Infraction[]) {
    return (
      <div className="VerifyCitations">
        <Table
          title="Verify Citations"
          columns={TABLE_COLUMNS_AUTOMOTUS}
          data={infractions}
          displayHover={true}
          orderBy="end_time"
          order="desc"
          enableSelect={false}
          enableSelectAll={false}
          totalRows={infractions.length}
          updateData={() => this.updateInfractions()}
        />
      </div>
    );
  }
  /** Render table of fake infractions. For demo purposes only */
  public renderCitationsTable(citations: StatefulPendingCitation[]) {
    return (
      <div className="VerifyCitations">
        <Table
          title="Verify Citations"
          columns={this.columns}
          data={citations}
          displayHover={true}
          orderBy="end_time"
          order="desc"
          enableSelect={false}
          enableSelectAll={false}
          totalRows={citations.length}
          updateData={(orderBy?: string, order?: 'asc' | 'desc') =>
            this.pendingCitations(orderBy, order)
          }
        />
      </div>
    );
  }
  /** Render a lander page */
  public renderLander() {
    return (
      <div className="lander">
        <h1>Scratch</h1>
        <p>A simple note taking app</p>
      </div>
    );
  }
  /** Render the page content (i.e. infractions/citations table) */
  public renderContent() {
    const {
      automotusUser,
      err,
      infractions,
      isLoading,
      pendingCitations,
    } = this.state;
    return (
      <div className="notes">
        <ErrorBar
          err={err}
          onRetry={this.handleRetryErrorBar}
          onClose={this.handleCloseErrorBar}
        />
        {!isLoading &&
          (automotusUser
            ? this.renderInfractionsTable(infractions)
            : this.renderCitationsTable(pendingCitations))}
      </div>
    );
  }
  public render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated
          ? this.renderContent()
          : this.renderLander()}
      </div>
    );
  }
}
